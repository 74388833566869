import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ReadOnlyRepresentative from './ReadOnlyRepresentative';
import { Button } from 'src/components/ui/button';
import { BookUser, Plus } from 'lucide-react';
import CMSTitleInfo from '../Title';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';
import { PersonaAccessContext } from '../../../v2api/PersonaAccessContext/PersonaAccessContext';
import EditRepresentatives from './EditRepresentatives';

const Representatives = ({ isCMS }) => {
  const { persona } = useContext(PersonaContext);
  const personaAccessContext = useContext(PersonaAccessContext);

  const { personaAccess, representativeAccess, updateAccess } =
    isCMS && personaAccessContext
      ? personaAccessContext
      : {
          personaAccess: null,
          representativeAccess: null,
          updateAccess: () => {},
        };

  const [editorOpen, setEditorOpen] = useState(false);

  const repsList = persona?.representatives || [];
  const hasContacts = repsList.some((el) => el?.repName);

  if (!hasContacts && !isCMS) {
    return null;
  }

  return (
    <div>
      {isCMS && (
        <EditRepresentatives
          isOpen={editorOpen}
          closeModal={() => setEditorOpen(false)}
        />
      )}
      {hasContacts ? (
        <div className="card">
          <CMSTitleInfo
            isCMS={isCMS}
            title="Contacts/Representatives"
            isNotEmpty={hasContacts}
            message="Edit Contacts/Representatives"
            onClick={() => setEditorOpen(true)}
            isVisible={personaAccess?.representatives}
            setVisible={(v) =>
              updateAccess({
                method: 'PATCH',
                payload: { representatives: v },
              })
            }
            isVisibilityDisabled={!personaAccess?.personaId}
          />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
            {repsList.map((rep) => (
              <div key={rep.uniqueKey}>
                <ReadOnlyRepresentative
                  representative={rep}
                  isVisible={representativeAccess?.[rep.uniqueKey]}
                  isCMS={isCMS}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="card-inactive">
          <div className="flex flex-col gap-4 items-center justify-center h-36">
            <div className="flex gap-2 opacity-20">
              <BookUser />
            </div>
            <p>Specify who people should contact to reach out to you</p>
            <Button
              size="lg"
              data-testid="add-data-button"
              onClick={() => setEditorOpen(true)}
            >
              <Plus className="w-4 h-4" />
              Contact
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

Representatives.propTypes = {
  isCMS: PropTypes.bool,
};

export default Representatives;
