import React, { useContext, useEffect, useState } from 'react';
import Modal from '../../CMSModal';
import Toolclick from '../../CPHelpMessage';
import InfoFieldEdit from '../InfoFieldEdit';
import RolesIcon from '../../../../icons/atomsRoles';
import { defaultSuggestions } from '../../../../text_fields';
import { languages } from 'countries-list';
import FeaturesIcon from '../../../../icons/atomsFeatures';
import SkillsIcon from '../../../../icons/atomsSkills';
import InstrumentsIcon from '../../../../icons/instruments';
import ToolsAndGearIcon from '../../../../icons/toolsAndGear';
import GenresIcon from '../../../../icons/genres';
import LanguagesIcon from '../../../../icons/languages';
import FavoriteArtistsIcon from '../../../../icons/favoriteArtist';
import EndorsementIcon from '../../../../icons/endorsement';
import InspirationsIcon from '../../../../icons/inspirations';
import SupportedCauses from '../../../../icons/supportedCauses';
import QuotesIcon from '../../../../icons/quotes';
import styles from '../styles';
import { makeStyles } from '@mui/styles';
import { PersonaContext } from '../../../../v2api/PersonaContext/PersonaContext';
import { PersonaAccessContext } from '../../../../v2api/PersonaAccessContext/PersonaAccessContext';

const useStyles = makeStyles(styles);

const PersonalInfoEdit = ({ closeModal, isOpen }) => {
  const classes = useStyles();
  const { persona, updatePersona } = useContext(PersonaContext);
  const { personaAccess, updateAccess } = useContext(PersonaAccessContext);
  const [personaPatch, setPersonaPatch] = useState({});
  const [personaVisibilityPatch, setPersonaVisibilityPatch] = useState({});

  const [state, setState] = useState({});

  useEffect(() => {
    if (!persona) return;
    const personaKeys = [
      'musicRoles',
      'features',
      'otherSkills',
      'instrumentsPlayed',
      'instrumentsOwned',
      'toolsUsed',
      'toolsOwned',
      'genres',
      'spokenLanguages',
      'favouriteMusicMakers',
      'endorsements',
      'interestsInspiration',
      'charitiesSupported',
      'favouriteQuote',
    ];
    const patchedState = Object.fromEntries(
      personaKeys.map((key) => [
        key,
        key in personaPatch ? personaPatch[key] : persona[key] || [],
      ]),
    );
    patchedState['favouriteQuote'] =
      'favouriteQuote' in personaPatch
        ? personaPatch['favouriteQuote']
        : persona['favouriteQuote'] || '';
    setState(patchedState);
  }, [personaPatch, persona]);

  const patchedVisibility = {
    ...personaAccess,
    ...personaVisibilityPatch,
  };

  const handleSave = () => {
    const personaPatchCopy = { ...personaPatch };
    // Iterate over patch keys
    Object.keys(personaPatch).forEach((key) => {
      if (typeof personaPatch[key] === 'string') {
        // null indicates to delete
        personaPatch[key] = personaPatch[key] || null;
        if (personaPatch[key] === null && !persona[key]) {
          // Asked to delete, but original had no data
          delete personaPatchCopy[key];
        }
      } else if ((persona[key] || []).length !== personaPatch[key].length) {
        // all these should be lists of strings
        // If the lengths mismatch this is a definite patch required
        return;
      } else if (
        personaPatch[key].length === 0 &&
        (!persona[key] || !persona[key].length)
      ) {
        // patch has no items but original also is blank
        delete personaPatchCopy[key]; // remove no-op deletes
      } else if (
        personaPatch[key]
          .map((patchVal, i) => patchVal === persona?.[key]?.[i])
          .every((eq) => eq)
      ) {
        delete personaPatchCopy[key]; // remove no-op updates
      }
    });
    if (Object.keys(personaPatchCopy).length >= 1) {
      updatePersona({ method: 'PATCH', payload: personaPatchCopy });
    }

    const personaVisibilityPatchCopy = { ...personaVisibilityPatch };
    Object.keys(personaVisibilityPatch).forEach((key) => {
      if (personaVisibilityPatch[key] === personaAccess[key]) {
        delete personaVisibilityPatchCopy[key];
      }
    });
    if (Object.keys(personaVisibilityPatchCopy).length >= 1) {
      updateAccess({
        method: 'PATCH',
        payload: personaVisibilityPatchCopy,
      });
    }
    setPersonaVisibilityPatch({});
    setPersonaPatch({});
    closeModal();
  };

  const handleCancel = () => {
    setPersonaPatch({});
    setPersonaVisibilityPatch({});
    closeModal();
  };

  const allLanguages = Object.values(languages).map((l) => l.name);

  return (
    <Modal onSave={handleSave} onClose={handleCancel} open={isOpen}>
      <div className={classes.modal}>
        {persona.isProfessionalProfile ? (
          <InfoFieldEdit
            Icon={RolesIcon}
            title="roles"
            values={state.musicRoles}
            setValues={(updatedList) =>
              setPersonaPatch((p) => ({ ...p, musicRoles: updatedList }))
            }
            isVisible={patchedVisibility.musicRoles}
            setVisible={(v) =>
              setPersonaVisibilityPatch((p) => ({
                ...p,
                musicRoles: v,
              }))
            }
            description="Specify the role/s in the industry that best represent you both music and non-music related.
              The first three roles will be displayed in your profile summary. Drag roles to rearrange them."
            defaultSuggestions={defaultSuggestions.musicRoles.values}
            personaId={persona.personaId}
          />
        ) : (
          <InfoFieldEdit
            Icon={FeaturesIcon}
            title="keywords"
            description="Specify any top features and defining characteristics of your profile. Type nouns, adjectives, tags, or keywords. The first three keywords will be displayed in your profile summary, so make sure to add the most important ones first."
            values={state.features}
            setValues={(updatedList) =>
              setPersonaPatch((p) => ({ ...p, features: updatedList }))
            }
            isVisible={patchedVisibility.features}
            setVisible={(v) =>
              setPersonaVisibilityPatch((p) => ({
                ...p,
                features: v,
              }))
            }
            defaultSuggestions={defaultSuggestions.keywords.values}
            personaId={persona.personaId}
          />
        )}
        <InfoFieldEdit
          Icon={SkillsIcon}
          title="skills"
          description="Add your most relevant music and non-music skills and expertise that define you."
          values={state.otherSkills}
          setValues={(updatedList) =>
            setPersonaPatch((p) => ({ ...p, otherSkills: updatedList }))
          }
          isVisible={patchedVisibility.otherSkills}
          setVisible={(v) =>
            setPersonaVisibilityPatch((p) => ({
              ...p,
              otherSkills: v,
            }))
          }
          defaultSuggestions={[
            'Songwriting',
            'Composing',
            'Photography',
            'Web Design',
          ]}
          personaId={persona.personaId}
        />
        <InfoFieldEdit
          Icon={InstrumentsIcon}
          title="instruments"
          description="List the musical instruments you can play and the ones that you own."
          primarySubheading="Can play"
          values={state.instrumentsPlayed}
          setValues={(updatedList) =>
            setPersonaPatch((p) => ({ ...p, instrumentsPlayed: updatedList }))
          }
          isVisible={
            patchedVisibility.instrumentsPlayed ||
            patchedVisibility.instrumentsOwned
          }
          setVisible={(v) =>
            setPersonaVisibilityPatch((p) => ({
              ...p,
              instrumentsPlayed: v,
              instrumentsOwned: v,
            }))
          }
          defaultSuggestions={defaultSuggestions.instrumentsPlayed.values}
          secondarySubheading="Owned"
          secondaryValues={state.instrumentsOwned}
          setSecondaryValues={(updatedList) =>
            setPersonaPatch((p) => ({ ...p, instrumentsOwned: updatedList }))
          }
          personaId={persona.personaId}
        />
        <InfoFieldEdit
          Icon={ToolsAndGearIcon}
          title="tools and gear"
          description="Add your preferred software, instruments, and other gear you can use and/or own. Do you use a specific DAW, or plugins? This is an important field to improve your discoverability in case brands are looking for a music maker that is using their brand for promotional activities/endorsement, or just to share what you use in your public profile so music makers can check each other’s gear or get inspiration."
          primarySubheading={'Used'}
          values={state.toolsUsed}
          setValues={(updatedList) =>
            setPersonaPatch((p) => ({ ...p, toolsUsed: updatedList }))
          }
          isVisible={
            patchedVisibility.toolsUsed || patchedVisibility.toolsOwned
          }
          setVisible={(v) =>
            setPersonaVisibilityPatch((p) => ({
              ...p,
              toolsUsed: v,
              toolsOwned: v,
            }))
          }
          secondarySubheading={'Owned'}
          secondaryValues={state.toolsOwned}
          setSecondaryValues={(updatedList) =>
            setPersonaPatch((p) => ({ ...p, toolsOwned: updatedList }))
          }
          personaId={persona.personaId}
        />
        <InfoFieldEdit
          Icon={GenresIcon}
          title="genres"
          description="Add the genres that best represent your music."
          values={state.genres}
          setValues={(updatedList) =>
            setPersonaPatch((p) => ({ ...p, genres: updatedList }))
          }
          isVisible={patchedVisibility.genres}
          setVisible={(v) =>
            setPersonaVisibilityPatch((p) => ({
              ...p,
              genres: v,
            }))
          }
          defaultSuggestions={defaultSuggestions.genres.values}
          personaId={persona.personaId}
        />
        <InfoFieldEdit
          Icon={LanguagesIcon}
          title="spoken languages"
          description="List all the languages that you speak fluently."
          values={state.spokenLanguages}
          setValues={(updatedList) =>
            setPersonaPatch((p) => ({ ...p, spokenLanguages: updatedList }))
          }
          isVisible={patchedVisibility.spokenLanguages}
          setVisible={(v) =>
            setPersonaVisibilityPatch((p) => ({
              ...p,
              spokenLanguages: v,
            }))
          }
          defaultSuggestions={allLanguages}
          personaId={persona.personaId}
        />
        <InfoFieldEdit
          Icon={FavoriteArtistsIcon}
          title="favorite artists"
          description="List all your favourite artists and Music Makers. This is an important field to share with the music makers’ community and champion your favourite colleagues, and the Music industry too. For instance… imagine if a DSP will be able to access this information and offer playlists or artist’s radio based on what music you like."
          values={state.favouriteMusicMakers}
          setValues={(updatedList) =>
            setPersonaPatch((p) => ({
              ...p,
              favouriteMusicMakers: updatedList,
            }))
          }
          isVisible={patchedVisibility.favouriteMusicMakers}
          setVisible={(v) =>
            setPersonaVisibilityPatch((p) => ({
              ...p,
              favouriteMusicMakers: v,
            }))
          }
          personaId={persona.personaId}
        />
        <InfoFieldEdit
          Icon={EndorsementIcon}
          title="endorsements"
          description="List if you are endorsing a specific brand or equipment. This is an important field to improve your discoverability."
          values={state.endorsements}
          setValues={(updatedList) =>
            setPersonaPatch((p) => ({ ...p, endorsements: updatedList }))
          }
          isVisible={patchedVisibility.endorsements}
          setVisible={(v) =>
            setPersonaVisibilityPatch((p) => ({
              ...p,
              endorsements: v,
            }))
          }
          personaId={persona.personaId}
        />
        <InfoFieldEdit
          Icon={InspirationsIcon}
          title="interests / inspirations"
          description="List all your interests or inspirations. Are you interested in particle physics or molecular cuisine? A specific book or painter? This is an important field to improve your discoverability and to share with the music makers’ community your preferences."
          values={state.interestsInspiration}
          setValues={(updatedList) =>
            setPersonaPatch((p) => ({
              ...p,
              interestsInspiration: updatedList,
            }))
          }
          isVisible={patchedVisibility.interestsInspiration}
          setVisible={(v) =>
            setPersonaVisibilityPatch((p) => ({
              ...p,
              interestsInspiration: v,
            }))
          }
          personaId={persona.personaId}
        />
        <InfoFieldEdit
          Icon={SupportedCauses}
          title="supported causes"
          description="List all the charities and charitable causes you support. This is an important field, as it can help to increase the visibility of the causes or institutions you care the most."
          values={state.charitiesSupported}
          setValues={(updatedList) =>
            setPersonaPatch((p) => ({ ...p, charitiesSupported: updatedList }))
          }
          isVisible={patchedVisibility.charitiesSupported}
          setVisible={(v) =>
            setPersonaVisibilityPatch((p) => ({
              ...p,
              charitiesSupported: v,
            }))
          }
          personaId={persona.personaId}
        />
        <InfoFieldEdit
          Icon={QuotesIcon}
          title="quote"
          description="Add a favourite quote that represents you or your artistic persona."
          values={state.favouriteQuote}
          setValues={(updatedString) =>
            setPersonaPatch((p) => ({ ...p, favouriteQuote: updatedString }))
          }
          isVisible={patchedVisibility.favouriteQuote}
          setVisible={(v) =>
            setPersonaVisibilityPatch((p) => ({
              ...p,
              favouriteQuote: v,
            }))
          }
          personaId={persona.personaId}
          isString
        />
      </div>
    </Modal>
  );
};

export default PersonalInfoEdit;
