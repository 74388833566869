import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import {
  Search,
  UserRoundPlus,
  KeyRoundIcon,
  Globe,
  Music2,
  IdCard,
} from 'lucide-react';
import { Button } from 'src/components/ui/button';
import { PersonaDropdown } from '../PersonaDropdown';
import {
  motion,
  AnimatePresence,
  useScroll,
  useMotionValueEvent,
} from 'framer-motion';
import { cn } from 'src/lib/utils';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { InviteValidate } from '../../P2P/InviteValidate';
import Logo from 'src/icons/auraclesLogo';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';

const directRegistrationLink = `${process.env.REACT_APP_KEYCLOAK_REALM_URI}/protocol/openid-connect/registrations?client_id=${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}&response_type=code&scope=openid&redirect_uri=${encodeURIComponent(`${window.location.origin}/signin-callback`)}`;

const WebsiteSwitcher = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const websites = [
    {
      name: 'Auracle ID',
      url: '/',
      bgColor: 'bg-primary-accent',
      icon: IdCard,
      isExternal: false,
    },
    {
      name: 'Auracle Song',
      url: 'https://song.auracles.io',
      bgColor: 'bg-[#8f837a]',
      icon: Music2,
      isExternal: true,
    },
    {
      name: 'Auracles Home',
      url: 'https://auracles.io',
      bgColor: 'bg-[#ee52cc]',
      icon: Globe,
      isExternal: true,
    },
  ];

  return (
    <DropdownMenu modal={false}>
      <motion.div
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
      >
        <DropdownMenuTrigger asChild>
          <div className="rgb-button bg-primary text-foreground shadow-sm transform transition-transform duration-300 h-[44px] w-[44px] rounded-2xl flex justify-center items-center cursor-pointer">
            <Logo className="w-6 h-6" fill="currentColor" />
          </div>
        </DropdownMenuTrigger>
      </motion.div>
      <DropdownMenuContent align="start" className="w-[200px]">
        {websites.map((site, index) => (
          <motion.div
            key={site.name}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.3,
              delay: index * 0.1,
              ease: 'easeOut',
            }}
          >
            <DropdownMenuItem
              asChild
              className={cn(
                'cursor-pointer',
                !site.isExternal &&
                  location.pathname === site.url &&
                  'bg-accent/10',
              )}
            >
              {site.isExternal ? (
                <a href={site.url} className="flex items-center gap-3">
                  <div
                    className={`${site.bgColor} h-[33px] w-[33px] rounded-xl flex justify-center items-center shadow-[inset_0px_0px_18px_0px_rgba(255,_255,_255,_0.8)]`}
                  >
                    <site.icon className="w-6 h-6 text-white" />
                  </div>
                  <span className="text-sm text-foreground ">{site.name}</span>
                </a>
              ) : (
                <Link to={site.url} className="flex items-center gap-3 p-2">
                  <div
                    className={`${site.bgColor} h-[33px] w-[33px] rounded-xl flex justify-center items-center shadow-[inset_0px_0px_18px_0px_rgba(255,_255,_255,_0.8)]`}
                  >
                    <site.icon className="w-6 h-6 text-white" />
                  </div>
                  <span className="text-sm text-foreground">{site.name}</span>
                </Link>
              )}
            </DropdownMenuItem>
          </motion.div>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const SignedInHeader = () => {
  const [inputValue, setInputValue] = useState('');
  const [currentPlaceholder, setCurrentPlaceholder] = useState(0);
  const [animating, setAnimating] = useState(false);
  const inputRef = useRef(null);
  const { scrollY } = useScroll();
  const [hidden, setHidden] = useState(false);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [atTop, setAtTop] = useState(true);
  const lastScrollY = useRef(0);
  const navigate = useNavigate();
  const auth = useAuth();

  const placeholders = [
    'Bands in London',
    'Artists working with AI',
    'MI.MU gloves',
    'Violin player',
    'Guitar player in Auckland',
    'Lighting technician in Hull',
    'Imogen Heap',
    'Mixing Engineer in Mexico City',
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentPlaceholder((prev) => (prev + 1) % placeholders.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  // Scroll handling
  useMotionValueEvent(scrollY, 'change', (latest) => {
    const currentScroll = latest;
    setAtTop(currentScroll <= 0);

    const isAtBottom =
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight - 100;

    if (!isAtBottom) {
      if (currentScroll > lastScrollY.current && currentScroll > 25) {
        setHidden(true);
      } else if (currentScroll < lastScrollY.current) {
        setHidden(false);
      }
    }

    lastScrollY.current = currentScroll;
  });

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim() && !animating) {
      e.preventDefault();
      navigate(`/search?q=${encodeURIComponent(inputValue)}`);
    }
  };

  return (
    <>
      <motion.nav
        variants={{
          visible: { y: 0 },
          hidden: { y: '-100%' },
        }}
        animate={hidden ? 'hidden' : 'visible'}
        transition={{ duration: 0.35, ease: 'easeInOut' }}
        className="fixed top-0 left-0 right-0 z-30 mx-auto flex justify-center w-full max-w-[1800px] select-none px-0 pt-0 py-8 sm:px-10 sm:py-8"
      >
        <div
          className="backdrop-blur-2xl bg-gradient-to-b from-primary/20 via-primary/20 to-[#fd0bb5]/5 sm:rounded-[20px] p-4 flex flex-row justify-between items-center text-sm w-full"
          style={{
            WebkitBackdropFilter: 'blur(24px)',
            isolation: 'isolate',
            backgroundColor: 'rgba(255, 255, 255, 0.01)',
          }}
        >
          {/* Logo Section */}
          <div className="w-fit">
            <WebsiteSwitcher />
          </div>

          {/* Search Section */}
          <motion.div
            layout
            transition={{
              type: 'spring',
              stiffness: 300,
              damping: 30,
            }}
            className="flex justify-center grow max-w-xl mx-4"
          >
            <motion.div
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 1.0 }}
              transition={{ type: 'spring', stiffness: 400, damping: 17 }}
              className={`flex-grow overflow-hidden text-foreground items-center rounded-full bg-card/50 hover:bg-card disabled:bg-bg-card/50 focus:bg-card active:bg-card w-full py-2 px-1 h-11 border shadow-xs hover:shadow-sm relative ${
                !atTop
                  ? 'border-white/20 focus:border-white/50 hover:border-white/50 active:border-white/50'
                  : 'border-white/50 focus:border-white hover:border-white active:border-white'
              } flex`}
            >
              <div className="inline-flex items-center justify-center h-9 w-9 aspect-square">
                <Search className="w-4 h-4" />
              </div>
              <input
                ref={inputRef}
                type="text"
                className={cn(
                  'text-sm font-normal justify-start border-none bg-transparent focus:outline-none w-full text-ellipsis',
                  animating && 'text-transparent',
                )}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                value={inputValue}
                disabled={animating}
                style={{ textOverflow: 'ellipsis' }}
              />
              <AnimatePresence mode="wait">
                {!inputValue && !animating && (
                  <motion.p
                    key={currentPlaceholder}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="absolute left-10 text-sm text-accent/50 pointer-events-none text-ellipsis overflow-hidden whitespace-nowrap"
                    style={{ width: 'calc(100% - 110px)' }}
                  >
                    {placeholders[currentPlaceholder]}
                  </motion.p>
                )}
              </AnimatePresence>
            </motion.div>
          </motion.div>

          {/* Right Section */}
          <div className="sm:ml-0 gap-x-4 flex justify-end">
            {auth.isAuthenticated ? (
              <>
                <PersonaDropdown />
                {/*
                <motion.div
                  initial={{ opacity: 0, width: 0 }}
                  animate={{ opacity: 1, width: 'auto' }}
                  transition={{
                    opacity: { duration: 0.1 },
                    width: {
                      type: 'spring',
                      stiffness: 120,
                      damping: 17,
                    },
                  }}
                >
                  <Button
                    onClick={() => setInviteModalOpen(true)}
                    variant="gradient"
                    size="lg"
                    className="flex flex-row items-center hidden sm:block"
                    animated
                  >
                    <div className="relative gap-2 flex flex-row items-center">
                      <UserRoundPlus className="h-4 w-4" />
                      <span className="hidden md:flex">Invite</span>
                    </div>
                  </Button>
                </motion.div>*/}
              </>
            ) : (
              <div className="flex gap-x-2">
                <Button
                  size="lg"
                  animated
                  onClick={() => auth.signinRedirect()}
                >
                  <KeyRoundIcon className="h-4 w-4" />
                  <span>Sign in</span>
                </Button>
                <Button
                  size="lg"
                  animated
                  onClick={() =>
                    window.location.replace(directRegistrationLink)
                  }
                >
                  <UserRoundPlus className="h-4 w-4" />
                  <span className="sm:hidden md:inline">Register</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </motion.nav>
      <InviteValidate
        open={inviteModalOpen}
        onOpenChange={(open) => setInviteModalOpen(open)}
      />
    </>
  );
};

export default SignedInHeader;
