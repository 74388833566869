const styles = (theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%', // Added to ensure container has a width
  },
  image: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 3,
    width: '100% !important',
    height: '100% !important',
    aspectRatio: '1 / 1',
    borderRadius: '50%',
    objectFit: 'cover',
    margin: 'auto',
    padding: 0,
  },
  emptyImage: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 3,
    width: '100% !important',
    height: '100% !important',
    aspectRatio: '1 / 1',
    margin: 'auto',
    backgroundColor: '#d8d8d8',
    color: '#8c8c8c',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '50px',
    borderRadius: '50%',
  },
  avatarContainer: {
    width: '100%',
    paddingTop: '100%', // Changed from paddingBottom to paddingTop
    position: 'relative',
    margin: 0, // Added to prevent unwanted margins
  },
  avatarWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0, // Added to ensure full width
    bottom: 0, // Added to ensure full height
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    overflow: 'hidden',
  },
  avatarImage: {
    position: 'absolute', // Added to ensure proper sizing within wrapper
    height: '100%',
    width: '100%',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  inputField: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 5,
    width: '100%',
    height: '100%',
    opacity: '0',
  },
  inputHelper: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 4,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255, 0.4)',
    borderRadius: '50%',
    fontSize: '50px',
  },
  checkmark: {
    position: 'absolute',
    bottom: '-8px',
    left: '68px',
    padding: '5px',
    paddingTop: '2px',
    backgroundColor: '#ffffff',
    zIndex: 100,
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  inputLoader: {
    animation: '$spin 1s linear infinite',
  },
});

export default styles;
