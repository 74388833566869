import React from 'react';
import { motion } from 'framer-motion';
import { Button } from 'src/components/ui/button';
import { AutosizeTextarea } from 'src/components/ui/autosizetextarea';
import { Input } from 'src/components/ui/input';
import CPSwitch from '../../CPSwitch';
import { Trash2 } from 'lucide-react';

interface EditProjectProps {
  fieldName: string;
  value: string;
  handleChange: (changes: { fieldName?: string; value?: string }) => void;
  onDelete: () => void;
  isVisible: boolean;
  setVisible: (visible: boolean) => void;
  areProjectsHidden: boolean;
}

export const EditProject: React.FC<EditProjectProps> = ({
  fieldName,
  value,
  handleChange,
  onDelete,
  isVisible,
  setVisible,
  areProjectsHidden,
}) => {
  return (
    <motion.div layout className="space-y-4">
      <motion.div layout className="flex justify-between items-center">
        <Input
          type="text"
          value={fieldName}
          onChange={(e) => handleChange({ fieldName: e.target.value })}
          onKeyDown={(e) => e.stopPropagation()} // fixes a dnd-kit bug
          placeholder="Project Name"
          className="flex-1 mr-4 font-medium"
        />

        <div className="flex items-center gap-2">
          <CPSwitch
            size="large"
            icon
            animated
            checked={isVisible}
            onCheckedChange={setVisible}
            disabled={areProjectsHidden}
          />
          <Button
            animated
            variant="accent"
            size="icon"
            className="h-8 w-8"
            onClick={onDelete}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </motion.div>

      <AutosizeTextarea
        value={value}
        onChange={(e) => handleChange({ value: e.target.value })}
        onKeyDown={(e) => e.stopPropagation()} // fixes a dnd-kit bug
        placeholder="Project Description"
        className="w-full resize-y"
      />
    </motion.div>
  );
};

export default EditProject;
