const styles = (theme) => ({
  root: {
    padding: '1.5rem',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      borderRadius: '0rem',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  headerImg: {
    height: '100px',
    width: '100px',
  },
  headerH3: {
    width: '100%',
    maxWidth: '220px',
    fontWeight: '700',
    fontFamily: ['Inter', 'sans-serif'].join(','),
    color: '#313233',
    margin: 0,
    fontSize: '18px',
  },
  description: {
    display: '-webkit-box',
    fontWeight: '400',
    fontFamily: ['Inter', 'sans-serif'].join(','),
    height: '90px',
    margin: 0,
    color: '#313233',
    fontSize: '14px',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  bottom: {
    marginTop: theme.spacing(1),
  },
  bottomFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonCalm: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: '#5079A1',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    height: '20px',
    fontSize: '12px',
    textTransform: 'lowercase',
  },
  buttonFill: {
    width: '100%',
    maxWidth: '144px',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: '#626466',
    backgroundColor: '#fff',
    border: 'none',
    padding: '20px 14px',
    borderRadius: '26px',
    cursor: 'pointer',
    height: '20px',
    fontWeight: '500',
    fontSize: '12px',
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    boxShadow: '0.67px 0.67px 4px 0 rgba(0,0,0,0.1)',
  },
  buttonOutline: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: '#777',
    border: 'none',
    padding: '5px 14px',
    borderRadius: '26px',
    cursor: 'pointer',
    height: '20px',
    fontWeight: '500',
    fontSize: '12px',
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.1)',
  },
  integratedText: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: '##626466',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(0.3),
  },

  integratedTextIcon: {
    fontSize: '27px',
  },
  integrationLogo: {
    backgroundColor: 'transparent',
    borderRadius: '0.75rem',
    width: '48px',
    height: '48px',
  },
});

export default styles;
