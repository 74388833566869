import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent } from 'src/components/ui/dialog';
import { QRCodeDisplay } from './QRCodeDisplay';
import { useAuth } from 'react-oidc-context';
import { getValidations } from '../v2api/P2P/http';
import { UserValidations } from '../v2api/P2P/types';
import { Button } from 'src/components/ui/button';
import ConnectionManagement from './Connections/ConnectionManagement';

interface GetValidatedProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

export const GetValidated: React.FC<GetValidatedProps> = ({
  isOpen,
  onOpenChange,
}) => {
  const { user } = useAuth();
  const [validations, setValidations] = useState<UserValidations | null>(null);
  const [lastValidationCount, setLastValidationCount] = useState<number | null>(
    null,
  );
  const [wasJustValidated, setWasJustValidated] = useState<boolean>(false);
  const [showConnections, setShowConnections] = useState(false);

  // Poll for validations while dialog is open
  useEffect(() => {
    if (!isOpen || !user?.access_token) {
      // Reset states when dialog closes
      setWasJustValidated(false);
      setLastValidationCount(null);
      return;
    }

    const pollValidations = async () => {
      try {
        const newValidations = await getValidations(user.access_token);
        const currentCount = newValidations.incoming.length;

        // Initialize lastValidationCount if it's null
        if (lastValidationCount === null) {
          setLastValidationCount(currentCount);
        }
        // Check if we got a new validation
        else if (currentCount > lastValidationCount) {
          setWasJustValidated(true);
        }

        setValidations(newValidations);
        setLastValidationCount(currentCount);
      } catch (error) {
        console.error('Failed to fetch validations:', error);
      }
    };

    // Poll every 1 second
    pollValidations();
    const interval = setInterval(pollValidations, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [isOpen, user?.access_token, lastValidationCount]);

  const handleViewConnections = () => {
    onOpenChange(false); // Close current dialog
    setShowConnections(true); // Open connections dialog
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <div className="space-y-4">
            <h2>Get validated</h2>
            {wasJustValidated ? (
              <div className="text-center space-y-4">
                <div className="text-2xl text-green-600">✓</div>
                <p className="text-sm text-muted-foreground">
                  You've been successfully validated!
                </p>
                <Button onClick={handleViewConnections}>
                  View connections
                </Button>
              </div>
            ) : (
              <>
                <p className="text-sm text-muted-foreground">
                  Show this QR code to another user to get validated, and help
                  grow your network. If someone in your network gets verified,
                  you'll receive verification too.
                </p>
                <QRCodeDisplay />
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>

      <ConnectionManagement
        isOpen={showConnections}
        onOpenChange={setShowConnections}
      />
    </>
  );
};
