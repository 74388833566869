import React, { useState, useContext, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { AutosizeTextarea } from 'src/components/ui/autosizetextarea';
import CMSModal from '../../CMSModal';
import { Trash2, Plus } from 'lucide-react';
import CPSwitch from '../../CPSwitch';
import { PersonaContext } from '../../../../v2api/PersonaContext/PersonaContext';
import { PersonaAccessContext } from '../../../../v2api/PersonaAccessContext/PersonaAccessContext';
import { Progress } from 'src/components/ui/progress';

export const EditBio = ({ isOpen, closeModal }) => {
  const { persona, updatePersona } = useContext(PersonaContext);
  const { personaAccess, updateAccess } = useContext(PersonaAccessContext);
  const [personaPatch, setPersonaPatch] = useState({});
  const [personaVisibilityPatch, setPersonaVisibilityPatch] = useState({});
  const [state, setState] = useState({});

  useEffect(() => {
    if (!persona) return;
    const personaKeys = [
      'biographyHeadline',
      'biographyShort',
      'biographyLong',
    ];
    const patchedState = Object.fromEntries(
      personaKeys.map((key) => [
        key,
        key in personaPatch ? (personaPatch[key] ?? '') : (persona[key] ?? ''),
      ]),
    );
    setState(patchedState);
  }, [personaPatch, persona]);

  const patchedVisibility = {
    ...personaAccess,
    ...personaVisibilityPatch,
  };

  const handleSave = () => {
    const personaPatchCopy = { ...personaPatch };
    Object.keys(personaPatch).forEach((key) => {
      if (personaPatch[key] === '') {
        delete personaPatchCopy[key]; // undeleted blank entries are cancelled
      } else if (personaPatch[key] === null && !persona[key]) {
        delete personaPatchCopy[key]; // remove no-op deletes
      } else if (personaPatch[key] === persona[key]) {
        delete personaPatchCopy[key]; // remove no-op updates
      }
    });
    if (Object.keys(personaPatchCopy).length >= 1) {
      updatePersona({ method: 'PATCH', payload: personaPatchCopy });
    }

    const personaVisibilityPatchCopy = { ...personaVisibilityPatch };
    Object.keys(personaVisibilityPatch).forEach((key) => {
      if (personaVisibilityPatch[key] === personaAccess[key]) {
        delete personaVisibilityPatchCopy[key];
      }
    });
    if (Object.keys(personaVisibilityPatchCopy).length >= 1) {
      updateAccess({
        method: 'PATCH',
        payload: personaVisibilityPatchCopy,
      });
    }
    setPersonaVisibilityPatch({});
    setPersonaPatch({});
    closeModal();
  };

  const handleCancel = () => {
    setPersonaPatch({});
    setPersonaVisibilityPatch({});
    closeModal();
  };

  const handleVisibilityChange = useCallback((type, checked) => {
    setPersonaVisibilityPatch((prev) => ({
      ...prev,
      [type]: checked,
    }));
  }, []);

  return (
    <CMSModal onSave={handleSave} onClose={handleCancel} open={isOpen}>
      <div className="space-y-4">
        <BioSection
          type="biographyHeadline"
          maxLength={100}
          state={state}
          patchedVisibility={patchedVisibility}
          setPersonaPatch={setPersonaPatch}
          onVisibilityChange={handleVisibilityChange}
          personaAccess={personaAccess}
        />
        <BioSection
          type="biographyShort"
          maxLength={500}
          state={state}
          patchedVisibility={patchedVisibility}
          setPersonaPatch={setPersonaPatch}
          onVisibilityChange={handleVisibilityChange}
          personaAccess={personaAccess}
        />
        <BioSection
          type="biographyLong"
          maxLength={2000}
          state={state}
          patchedVisibility={patchedVisibility}
          setPersonaPatch={setPersonaPatch}
          onVisibilityChange={handleVisibilityChange}
          personaAccess={personaAccess}
        />
      </div>
    </CMSModal>
  );
};

const BioSection = React.memo(
  ({
    type,
    maxLength,
    state,
    patchedVisibility,
    setPersonaPatch,
    onVisibilityChange,
    personaAccess,
  }) => {
    const [charCount, setCharCount] = useState(state[type]?.length || 0);
    const [isEditing, setIsEditing] = useState(false);
    const titles = {
      biographyHeadline: 'headline',
      biographyShort: 'short',
      biographyLong: 'long',
    };

    useEffect(() => {
      if (state[type] !== null && state[type] !== '') {
        setIsEditing(true);
      }
    }, [state[type]]);

    const handleChange = useCallback((e) => {
      setCharCount(e.target.value.length);
    }, []);

    const handleBlur = useCallback(
      (e) => {
        const newValue = e.target.value;
        if (state[type] !== newValue) {
          setPersonaPatch((p) => ({
            ...p,
            [type]: newValue,
          }));
        }
      },
      [state, type, setPersonaPatch],
    );

    const handleDelete = useCallback(() => {
      setPersonaPatch((p) => ({ ...p, [type]: null }));
      setIsEditing(false);
    }, [type, setPersonaPatch]);

    return (
      <motion.div layout className="bg-accent/5 p-4 rounded-lg space-y-4">
        <motion.div layout className="flex justify-between">
          <div className="flex items-center gap-2">
            <h2 className="capitalize mb-0">{titles[type]}</h2>
          </div>

          <div className="flex items-center gap-2">
            <AnimatePresence mode="wait">
              {isEditing ? (
                <motion.div
                  key="editing"
                  initial={{ opacity: 0, x: 10 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -10 }}
                  className="flex items-center gap-2"
                  transition={{ duration: 0.1 }}
                >
                  <CPSwitch
                    icon
                    animated
                    size="large"
                    checked={patchedVisibility[type]}
                    onCheckedChange={(checked) =>
                      onVisibilityChange(type, checked)
                    }
                    disabled={!personaAccess?.personaId}
                  />
                  <Button
                    animated
                    variant="accent"
                    size="icon"
                    className="h-8 w-8"
                    onClick={handleDelete}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </motion.div>
              ) : (
                <motion.div
                  key="add"
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 10 }}
                  transition={{ duration: 0.1 }}
                >
                  <Button
                    animated
                    variant="accent"
                    className="h-8 px-3"
                    onClick={() => setIsEditing(true)}
                  >
                    <Plus className="h-4 w-4" />
                    <span>Add</span>
                  </Button>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </motion.div>

        <AnimatePresence>
          {isEditing && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.1 }}
            >
              <AutosizeTextarea
                defaultValue={state[type] || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full resize-y"
                placeholder={`Add a ${titles[type]} bio`}
                maxLength={maxLength}
              />
              {maxLength && (
                <div className="flex items-center gap-3 mt-2">
                  <Progress
                    value={(charCount / maxLength) * 100}
                    className="flex-1"
                  />
                  <div className="text-xs text-right font-medium text-foreground/30">
                    {charCount}/{maxLength}
                  </div>
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    );
  },
);

BioSection.displayName = 'BioSection';
