import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'src/components/ui/button';
import { Plus, Link } from 'lucide-react';
import ReadOnlyOfficialLink from './ReadOnlyOfficialLink';
import CMSTitleInfo from '../Title';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';
import { PersonaAccessContext } from '../../../v2api/PersonaAccessContext/PersonaAccessContext';
import EditOfficialLinks from './EditOfficialLinks';

const OfficialLinks = ({ isCMS = true }) => {
  const { persona } = useContext(PersonaContext);
  const accessContext = useContext(PersonaAccessContext);
  const [editorOpen, setEditorOpen] = useState(false);

  // Only use access context data in CMS
  const personaAccess = isCMS ? accessContext?.personaAccess : null;
  const linkAttrAccess = isCMS ? accessContext?.linkAttrAccess : null;
  const updateAccess = isCMS ? accessContext?.updateAccess : null;

  const linkList = persona?.officialLinks || [];
  const hasLinks = linkList.some((el) => el.fieldName);

  const handleOpenEditor = () => {
    setEditorOpen(true);
  };

  const handleCloseEditor = () => {
    setEditorOpen(false);
  };

  // On public page, return null if no data added
  if (!isCMS && !hasLinks) return null;

  // On public page, show all links, since visibility is handled server-side
  const visibleLinks = isCMS ? linkList : linkList;

  const renderLinks = () => (
    <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-2">
      {visibleLinks.map((oLink) => (
        <ReadOnlyOfficialLink
          key={oLink.uniqueKey}
          customAttr={oLink}
          isVisible={isCMS ? linkAttrAccess?.[oLink.uniqueKey] : true}
          isCMS={isCMS}
        />
      ))}
    </div>
  );

  // In CMS, when no data has been added, render a different card that prompts users to add data
  if (isCMS && !hasLinks) {
    return (
      <div className="card-inactive">
        <div className="flex flex-col gap-4 items-center justify-center h-36">
          <div className="flex gap-2 opacity-20">
            <Link />
          </div>
          <p>
            Make sure your official links are always available and up to date
          </p>
          <Button
            size="lg"
            data-testid="add-data-button"
            onClick={handleOpenEditor}
          >
            <Plus className="w-4 h-4" />
            Link
          </Button>
        </div>
        {editorOpen && (
          <EditOfficialLinks
            isOpen={editorOpen}
            closeModal={handleCloseEditor}
          />
        )}
      </div>
    );
  }

  // When data has been added, render this card. The isCMS prop handles what should be rendered or not
  return (
    <div className="card">
      {isCMS && editorOpen && (
        <EditOfficialLinks isOpen={editorOpen} closeModal={handleCloseEditor} />
      )}
      <CMSTitleInfo
        isCMS={isCMS}
        title="Official Links"
        isNotEmpty={hasLinks}
        message="Edit Official Links"
        onClick={handleOpenEditor}
        isVisible={personaAccess?.officialLinks}
        setVisible={(v) =>
          updateAccess({
            method: 'PATCH',
            payload: { officialLinks: v },
          })
        }
        isVisibilityDisabled={!personaAccess?.personaId}
      />

      {renderLinks()}
    </div>
  );
};

OfficialLinks.propTypes = {
  isCMS: PropTypes.bool,
};

export default OfficialLinks;
