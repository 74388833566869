import React from 'react';
import { motion } from 'framer-motion';
import { Input } from 'src/components/ui/input';
import { AutosizeTextarea } from 'src/components/ui/autosizetextarea';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import { Button } from 'src/components/ui/button';
import { Trash2 } from 'lucide-react';
import CPSwitch from '../../CPSwitch';
import { repTypes } from '../repTypes';

const EditRepresentative = ({
  representativeType,
  repName,
  contactEmail,
  contactNote,
  contactPhone,
  handleChange,
  onDelete,
  isVisible,
  setVisible,
  areRepsHidden,
}) => {
  return (
    <motion.div layout className="bg-accent/5 p-4 rounded-lg space-y-4">
      <div className="flex items-center justify-between gap-4">
        <Select
          value={representativeType}
          onValueChange={(value) => handleChange({ representativeType: value })}
        >
          <SelectTrigger className="w-[200px] font-medium">
            <SelectValue placeholder="Select type" />
          </SelectTrigger>
          <SelectContent>
            {Object.entries(repTypes).map(([key, { label }]) => (
              <SelectItem key={key} value={key}>
                {label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <div className="flex items-center gap-2">
          <CPSwitch
            icon
            animated
            size="large"
            checked={isVisible}
            onCheckedChange={setVisible}
            disabled={areRepsHidden}
          />
          <Button
            variant="accent"
            size="icon"
            className="h-8 w-8"
            onClick={onDelete}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </div>

      <div className="space-y-4">
        <Input
          placeholder="Name"
          value={repName}
          onChange={(e) => handleChange({ repName: e.target.value })}
          onKeyDown={(e) => e.stopPropagation()}
        />

        <Input
          placeholder="Email"
          value={contactEmail || ''}
          onChange={(e) => handleChange({ contactEmail: e.target.value })}
          onKeyDown={(e) => e.stopPropagation()}
        />

        <Input
          placeholder="Phone Number"
          value={contactPhone || ''}
          onChange={(e) => handleChange({ contactPhone: e.target.value })}
          onKeyDown={(e) => e.stopPropagation()}
        />

        <AutosizeTextarea
          placeholder="Description"
          value={contactNote || ''}
          onChange={(e) => handleChange({ contactNote: e.target.value })}
          onKeyDown={(e) => e.stopPropagation()}
          className="resize-none"
        />
      </div>
    </motion.div>
  );
};

export default EditRepresentative;
