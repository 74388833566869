import React from 'react';
import { SvgIcon } from '@mui/material';

function Logo(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 198 171"
      {...props}
    >
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g
          id="Group-2"
          transform="translate(-26, -39)"
          fill="#FFFFFF"
          fill-rule="nonzero"
        >
          <g id="icon-WHT-Asset-1" transform="translate(26, 39)">
            <path
              d="M103.362445,0 C129.104803,0 150,20.9525845 150,46.7656432 C150,57.2747765 146.528384,66.9300428 140.764192,74.7462106 L83.9737991,150.214924 C75.4585153,161.643607 61.8995633,169 46.6375546,169 C20.8951965,169 0,148.047415 0,122.234357 C0,111.92227 3.34061135,102.332686 9.03930131,94.5821998 L65.9606987,18.8507579 C74.4759825,7.4220754 88.0349345,0 103.362445,0 Z M103,9 C82.0131795,9 65,26.0131795 65,47 C65,67.9868205 82.0131795,85 103,85 C123.98682,85 141,67.9868205 141,47 C141,26.0131795 123.98682,9 103,9 Z"
              id="Combined-Shape"
            ></path>
            <circle id="Oval" cx="160" cy="133" r="38"></circle>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Logo;
