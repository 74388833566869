import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import { Skeleton } from 'src/components/ui/skeleton';
import SignedInHeader from '../components/SignedInHeader';
import SearchCard from './SearchCard';

const RESULTS_PER_PAGE = 6;

const SearchPortal = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const query = urlParams.get('q');
  const [searchField, setSearchField] = useState(query || '');
  const [pageStatus, setPageStatus] = useState('init'); // init, found, not-found
  const [results, setResults] = useState([]);
  const [pagesLoaded, setPagesLoaded] = useState(0);
  const [moreToLoad, setMoreToLoad] = useState(false);
  const [visibleCards, setVisibleCards] = useState(0);

  useEffect(() => {
    if (query) {
      setSearchField(query);
    }
  }, [query]);

  useEffect(() => {
    handleSearch();
  }, [searchField]);

  useEffect(() => {
    if (pageStatus === 'found' && visibleCards < results.length) {
      const timer = setTimeout(() => {
        setVisibleCards((prev) => prev + 1);
      }, 70); // delay between each card appearing

      return () => clearTimeout(timer);
    }
  }, [visibleCards, results.length, pageStatus]);

  const handleSearch = () => {
    setVisibleCards(0);
    if (searchField && searchField !== '') {
      setPageStatus('loading');
      setResults([]);
      setPagesLoaded(0);
      setMoreToLoad(false);

      urlParams.set('q', searchField);
      window.history.replaceState(
        null,
        '',
        `${location.pathname}?${urlParams.toString()}`,
      );

      fetch(
        `${process.env.REACT_APP_V2_API_BASE}/search/personas/?query=${encodeURIComponent(searchField)}&limit=${RESULTS_PER_PAGE}`,
      ).then((response) => {
        if (response.status === 404) {
          return setPageStatus('not-found');
        }
        if (!response.ok) {
          return setPageStatus('error');
        }
        setPageStatus('found');
        setPagesLoaded(1);
        response.json().then((rslts) => {
          setResults(rslts);
          setMoreToLoad(rslts?.length >= RESULTS_PER_PAGE);
        });
      });
    } else {
      urlParams.delete('q');
      window.history.replaceState(null, '', location.pathname);
      setPageStatus('init');
      setResults([]);
      setPagesLoaded(0);
      setMoreToLoad(false);
    }
  };

  const loadMore = () => {
    fetch(
      `${process.env.REACT_APP_V2_API_BASE}/search/personas/?query=${encodeURIComponent(searchField)}&offset=${pagesLoaded * RESULTS_PER_PAGE}&limit=${RESULTS_PER_PAGE}`,
    ).then((response) => {
      if (response.status === 404) {
        return setMoreToLoad(false);
      }
      if (!response.ok) {
        return setPageStatus('error');
      }
      setPagesLoaded((n) => n + 1);
      response.json().then((rslts) => {
        setResults((prevRslts) => [...prevRslts, ...rslts]);
        setMoreToLoad(rslts.length >= RESULTS_PER_PAGE);
      });
    });
  };

  const renderContent = () => {
    switch (pageStatus) {
      case 'init':
        return null;
      case 'not-found':
        return <p className="text-base">No Profiles Found</p>;
      case 'loading':
        return (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {Array(6)
                .fill()
                .map((_, i) => (
                  <Skeleton
                    key={i}
                    className="w-full h-52 rounded-lg bg-background opacity-0 transition-opacity duration-1000 delay-1000"
                  />
                ))}
            </div>
          </>
        );
      case 'found':
        return (
          <div>
            <div className="flex flex-col">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-2 md:gap-6 mx-4 sm:mx-0">
                {results.map((personaResult, index) => (
                  <div
                    key={personaResult.persona_id}
                    className={`flex items-stretch transition-opacity duration-300 ${
                      index < visibleCards ? 'opacity-100' : 'opacity-0'
                    }`}
                  >
                    <SearchCard personaResult={personaResult} />
                  </div>
                ))}
              </div>
              <div className="flex justify-center items-center my-12">
                {moreToLoad && (
                  <Button animated onClick={loadMore}>
                    Load more results
                  </Button>
                )}
              </div>
            </div>
          </div>
        );
      default:
        return <div>Error</div>;
    }
  };

  return (
    <>
      <SignedInHeader />
      <div className="h-[108px] sm:h-[140px]" />
      {/* Above is space to prevent content overlap of nav */}
      <main className="flex flex-col items-center justify-center flex-grow mx-0 sm:mx-10">
        <div className="items-center w-full max-w-screen-lg">
          {renderContent()}
        </div>
      </main>
    </>
  );
};

export default SearchPortal;
