import React from 'react';
import { motion } from 'framer-motion';
import { Input } from 'src/components/ui/input';
import { Button } from 'src/components/ui/button';
import { Trash2 } from 'lucide-react';
import CPSwitch from '../../CPSwitch';
import { text_fields } from '../../../../text_fields';

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)));
  return images;
}

const logos_links = importAll(
  require.context(
    '../../../../shared/icons/logos',
    false,
    /\.(png|jpe?g|svg)$/,
  ),
);

const specialLinks = text_fields['knownOfficialLinkTypes'];

const absolutifyUrl = (userUrl) => {
  if (!/^https?:\/\//i.test(userUrl)) {
    return 'http://' + userUrl;
  }
  return userUrl;
};

const EditOfficialLink = ({
  fieldName,
  value,
  handleChange,
  onDelete,
  isVisible,
  setVisible,
  areLinksHidden,
}) => {
  const isSpecial = fieldName in specialLinks && fieldName !== 'website'; // FIXME: prepend with OFFICIAL::?

  const imgFile = isSpecial
    ? logos_links[`${fieldName}.svg`] || logos_links[`${fieldName}.png`]
    : logos_links['website.svg'];

  if (!imgFile) console.warn(`No img file found for ${fieldName}`);

  const logoIcon = (
    <img src={imgFile} className="h-5 w-5" alt={fieldName} draggable="false" />
  );

  if (isSpecial) {
    return (
      <motion.div layout className="space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {logoIcon}
            <h2 className="text-base font-medium m-0">
              {specialLinks[fieldName].label}
            </h2>
          </div>

          <div className="flex items-center gap-2">
            <CPSwitch
              icon
              size="large"
              animated
              checked={isVisible}
              onCheckedChange={setVisible}
              disabled={areLinksHidden}
            />
            <Button
              animated
              variant="accent"
              size="icon"
              className="h-8 w-8"
              onClick={onDelete}
            >
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        </div>

        <Input
          type="text"
          placeholder="example.com/@thecreativepassport"
          value={value}
          onChange={(e) => handleChange({ value: e.target.value })}
          onKeyDown={(e) => e.stopPropagation()}
          className="w-full"
        />
      </motion.div>
    );
  }

  return (
    <motion.div layout className="space-y-4">
      <div className="flex items-center justify-between">
        <h2 className="text-base font-medium m-0">Custom Link</h2>

        <div className="flex items-center gap-2">
          <CPSwitch
            icon
            animated
            size="large"
            checked={isVisible}
            onCheckedChange={setVisible}
            disabled={areLinksHidden}
          />
          <Button
            animated
            variant="accent"
            size="icon"
            className="h-8 w-8"
            onClick={onDelete}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </div>

      <div className="space-y-4">
        <Input
          type="text"
          placeholder="E.g. Portfolio, Personal, Webstore"
          value={fieldName}
          onChange={(e) => handleChange({ fieldName: e.target.value })}
          onKeyDown={(e) => e.stopPropagation()}
          className="w-full"
        />

        <Input
          type="text"
          placeholder="example.com"
          value={value}
          onChange={(e) => handleChange({ value: e.target.value })}
          onKeyDown={(e) => e.stopPropagation()}
          className="w-full"
        />
      </div>
    </motion.div>
  );
};

export default EditOfficialLink;
