import React, { useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import { Box, Tooltip } from '@mui/material';
import { toast } from 'react-hot-toast';
import { BiImage } from 'react-icons/bi';
import styles from './styles';
import CPDeleteButton from '../../CMS/components/CPDeleteButton';
import CMSModal from '../../CMS/components/CMSModal';
import emptyAvatar from '../../images/emptyavatar.svg';
import { PersonaImageContext } from '../../v2api/PersonaImageContext/PersonaImageContext';
//import CheckMark from '../../icons/checkMark';

const MAX_UPLOAD = 8 * 1024 ** 2 - 1; // 8MB

const useStyles = makeStyles(styles);

const ProfileImage = ({
  alt,
  disableUpload,
  toolTipTitle,
  showTooltip,
  isVerified = false,
  isPublicPage = false,
  imgObjectURLOverride,
}) => {
  const classes = useStyles();
  const [showImageDelete, setShowImageDelete] = useState(false);
  const personaImageContext = useContext(PersonaImageContext);

  if (!personaImageContext && !imgObjectURLOverride) {
    return null;
  }

  const { imgObjectURL, updateImage } = personaImageContext || {
    imgObjectURL: imgObjectURLOverride,
    updateImage: () => {},
  };

  if (isPublicPage) {
    // FIXME this is awful
    return (
      <div className={classes.avatarContainer}>
        <div className={classes.avatarWrapper}>
          <Box
            component="img"
            className={classes.avatarImage}
            src={imgObjectURL || emptyAvatar}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = emptyAvatar;
            }}
          />
        </div>
      </div>
    );
  }

  const handleOnChange = (e) => {
    const file = e.target.files[0];
    if (file.size > MAX_UPLOAD) {
      toast.error('Maximum of 8MB');
      return;
    }
    updateImage({ method: 'UPLOAD', payload: file });
  };

  const handleImageDelete = () => {
    updateImage({ method: 'DELETE' });
  };

  const mainImage = (
    <>
      <Box className={`relative ${classes.root} group`}>
        {!disableUpload && !isPublicPage ? (
          <>
            <label htmlFor="profile-image" style={{ display: 'none' }}>
              Profile Photo
            </label>
            <input
              className={classes.inputField}
              id="profile-image"
              data-testid="picture"
              type="file"
              accept="image/*"
              onChange={handleOnChange}
              style={{
                cursor: 'pointer',
              }}
            />
          </>
        ) : null}
        {imgObjectURL ? (
          <Avatar className={classes.image} role="button" tabIndex={0}>
            <AvatarImage
              src={imgObjectURL}
              alt={alt}
              onError={(e) => {
                console.warn('Failed to load avatar image');
                e.target.src = emptyAvatar; // Fallback to empty avatar on error
              }}
            />
            <AvatarFallback>
              <BiImage />
            </AvatarFallback>
          </Avatar>
        ) : (
          <Avatar className={classes.emptyImage}>
            <AvatarFallback>
              <BiImage />
            </AvatarFallback>
          </Avatar>
        )}
        {!disableUpload && !isPublicPage ? (
          <Box className="absolute top-2 right-2 z-20 hidden group-hover:flex">
            <CPDeleteButton onClick={() => setShowImageDelete(true)} />
          </Box>
        ) : null}
        {/*  {isVerified && (
          <Box className={classes.checkmark}>*/}
        {/* Change the Icon */}
        {/*<CheckMark sx={{ maxWidth: '13.66px', maxHeight: '13.33px' }} />
          </Box>
        )}*/}
        <CMSModal
          open={showImageDelete}
          onClose={() => setShowImageDelete(false)}
          onSave={() => {
            handleImageDelete();
            setShowImageDelete(false);
          }}
          altSaveText={'Confirm Delete'}
        >
          Are you sure you want to delete your profile photo?
        </CMSModal>
      </Box>
    </>
  );

  if (!showTooltip) return mainImage;
  else
    return (
      <Tooltip
        title={
          toolTipTitle
            ? toolTipTitle
            : 'Profile Image must be less than 2MB (recommended dimensions: at least 420px x 420px)'
        }
        classes={{ tooltip: classes.tooltip }}
      >
        {' '}
        {mainImage}{' '}
      </Tooltip>
    );
};

export default ProfileImage;
