import React, { useContext, useEffect, useState, useRef } from 'react';
import { P2PConnection } from 'src/v2api/P2P/types.ts';
import { P2PContext } from 'src/v2api/P2P/P2PContext';
import ConnectionCard from './ConnectionCard';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'src/components/ui/dialog';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from 'src/components/ui/button';
import { CalendarArrowDown, CalendarArrowUp, Search } from 'lucide-react';
import { Input } from 'src/components/ui/input';

interface ConnectionManagementProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

const ConnectionManagement: React.FC<ConnectionManagementProps> = ({
  isOpen,
  onOpenChange,
}) => {
  const { p2pConnections, p2pRefresh } = useContext(P2PContext);
  const [sortAscending, setSortAscending] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const searchContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      p2pRefresh();
    }
  }, [isOpen, p2pRefresh]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target as Node) &&
        isSearchExpanded
      ) {
        setIsSearchExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSearchExpanded]);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  const filteredAndSortedConnections = [...p2pConnections]
    .filter((connection) =>
      connection.connection.peer_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase()),
    )
    .sort((a, b) => {
      const comparison =
        new Date(b.connection.connection_made).getTime() -
        new Date(a.connection.connection_made).getTime();
      return sortAscending ? -comparison : comparison;
    });

  if (process.env.REACT_APP_P2P_DISABLED === 'true') {
    return <div>Coming soon!</div>;
  }

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px] max-h-[90vh] overflow-y-auto">
        <DialogHeader className="flex flex-col gap-4">
          <div className="flex flex-row justify-between items-center">
            <h2 className="m-0">My connections</h2>
            <div className="flex gap-2">
              <AnimatePresence>
                {isSearchExpanded && (
                  <motion.div
                    ref={searchContainerRef}
                    initial={{ width: 0, opacity: 0 }}
                    animate={{ width: '200px', opacity: 1 }}
                    exit={{ width: 0, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="relative"
                  >
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                    <Input
                      placeholder="Search connections..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="pl-9 h-9"
                      autoFocus
                    />
                  </motion.div>
                )}
              </AnimatePresence>
              <Button
                variant="accent"
                size="icon"
                onClick={() => setIsSearchExpanded(!isSearchExpanded)}
                title="Search connections"
              >
                <Search className="h-4 w-4" />
              </Button>
              <Button
                variant="accent"
                size="icon"
                onClick={() => setSortAscending(!sortAscending)}
                title={sortAscending ? 'Newest first' : 'Oldest first'}
              >
                {sortAscending ? (
                  <CalendarArrowUp className="h-4 w-4" />
                ) : (
                  <CalendarArrowDown className="h-4 w-4" />
                )}
              </Button>
            </div>
          </div>
        </DialogHeader>
        <motion.div
          className={'flex flex-col gap-4 mt-4'}
          variants={container}
          initial="hidden"
          animate="show"
        >
          {filteredAndSortedConnections.length === 0 ? (
            <p className="text-center text-muted-foreground">
              {searchQuery ? 'No matching connections' : 'No connections yet'}
              <Button
                className="mt-2"
                animated
                onClick={() => setSearchQuery('')}
              >
                Show all results
              </Button>
            </p>
          ) : (
            <>
              {filteredAndSortedConnections.map((connection) => (
                <motion.div key={connection.connection_id} variants={item}>
                  <ConnectionCard p2pConnection={connection} />
                </motion.div>
              ))}
              {searchQuery && (
                <p className="text-sm text-muted-foreground text-center">
                  Showing results for "{searchQuery}".{' '}
                  <Button
                    className="mt-2"
                    animated
                    onClick={() => setSearchQuery('')}
                  >
                    Show all results
                  </Button>
                </p>
              )}
            </>
          )}
        </motion.div>
      </DialogContent>
    </Dialog>
  );
};

export default ConnectionManagement;
