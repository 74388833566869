import React from 'react';
import { Dialog, DialogContent } from 'src/components/ui/dialog';
import { X, Users, Search, Globe, Plug } from 'lucide-react';

const AboutCreativePassport = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl p-6 max-h-[90vh] overflow-y-auto">
        <div className="grid gap-4">
          <div>
            <h3>About The Project</h3>
          </div>
          <p>
            Auracle ID is a digital identity management tool created by music
            makers, for music makers. Here's what you can do with your ID:
          </p>
          {/* Create profiles section */}
          <div className="bg-accent/5 p-4 rounded-md">
            <div className="flex items-center gap-2">
              <Users className="h-4 w-4" />
              <h2 className="mb-0">Create profiles</h2>
            </div>
            <p className="mt-2 text-sm text-muted-foreground">
              Create a profile and add information related to your career as a
              music professional. Invite your friends via QR code and extend the
              functionality of your own Auracle by having it validated by your
              peers.
            </p>
          </div>

          {/* Discover others section */}
          <div className="bg-accent/5 p-4 rounded-md">
            <div className="flex items-center gap-2">
              <Search className="h-4 w-4" />
              <h2 className="mb-0">Discover others</h2>
            </div>
            <p className="mt-2 text-sm text-muted-foreground">
              Become searchable in our portal of music makers, and find your
              next collaborator. You can search via name, project, skill, or any
              other attribute. Opt into our interactive map and discover people
              where you live.
            </p>
          </div>

          {/* Share your public page section */}
          <div className="bg-accent/5 p-4 rounded-md">
            <div className="flex items-center gap-2">
              <Globe className="h-4 w-4" />
              <h2 className="mb-0">Share your public page</h2>
            </div>
            <p className="mt-2 text-sm text-muted-foreground">
              Display all of your verified data in one standardised place, and
              enable a smoother interaction between you, music services, and the
              broader industry. Decide what to keep private, and customise how
              your public page looks.
            </p>
          </div>

          {/* Integrate with services section */}
          <div className="bg-accent/5 p-4 rounded-md">
            <div className="flex items-center gap-2">
              <Plug className="h-4 w-4" />
              <h2 className="mb-0">Integrate with services</h2>
            </div>
            <p className="mt-2 text-sm text-muted-foreground">
              Through our integration with industry partners you can ensure that
              every place your work shows up, so do you.
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AboutCreativePassport;
