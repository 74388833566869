import ReactGA from 'react-ga4';

type EventParams = {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
  transport?: 'beacon' | 'xhr' | 'image';
};

let isInitialized = false;

export const initGA = (measurementId: string): void => {
  try {
    if (!measurementId) {
      console.warn('GA4 measurement ID is missing');
      return;
    }

    ReactGA.initialize(measurementId);
    isInitialized = true;
  } catch (error) {
    console.error('Failed to initialize GA4:', error);
  }
};

export const logPageView = (path: string = window.location.pathname): void => {
  if (!isInitialized) {
    console.warn('GA4 not initialized');
    return;
  }

  try {
    ReactGA.send({ hitType: 'pageview', page: path });
  } catch (error) {
    console.error('Failed to log page view:', error);
  }
};

export const logEvent = ({
  category,
  action,
  label,
  value,
  nonInteraction,
  transport,
}: EventParams): void => {
  if (!isInitialized) {
    console.warn('GA4 not initialized');
    return;
  }

  try {
    ReactGA.event({
      category,
      action,
      label,
      value,
      nonInteraction,
      transport,
    });
  } catch (error) {
    console.error('Failed to log event:', error);
  }
};
