import React from 'react';

const thisYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="flex justify-center w-full my-10 text-sm text-foreground/20 text-center">
      <p>
        Auracle ID 3.0 © {thisYear}{' '}
        <a
          className="hover:underline cursor-pointer text-foreground/20"
          href="http://hablab.london/"
        >
          Hablab London Ltd
        </a>
      </p>
    </footer>
  );
};

Footer.displayName = 'Footer';
export default Footer;
