import React from 'react';
import { Dialog, DialogContent, DialogFooter } from 'src/components/ui/dialog';
import { Button } from 'src/components/ui/button';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { Check, TriangleAlert } from 'lucide-react';

const CMSModal = ({
  children,
  open,
  onClose,
  onSave,
  isLoading,
  altSaveText,
}) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-screen sm:max-w-[600px] max-h-screen sm:max-h-[90vh] p-0">
        <ScrollArea className="px-8 pt-8 max-h-[100vh] sm:max-h-[70vh] overflow-y-auto">
          {children}
          <div className="h-[108px]"></div>
        </ScrollArea>
        <DialogFooter className="backdrop-blur-lg bg-primary/20 p-4 rounded-t-none rounded-b-[20px] flex flex-row justify-end gap-2 absolute bottom-0 left-0 right-0">
          <Button
            animated
            variant="accent"
            size="lg"
            data-testid="cancel-button"
            onClick={onClose}
            className="flex-1 sm:flex-initial"
          >
            Cancel
          </Button>
          {isLoading ? (
            <Button size="lg" disabled className="flex-1 sm:flex-initial">
              <TriangleAlert className="h-4 w-4" />
              Saving...
            </Button>
          ) : (
            <Button
              animated
              size="lg"
              onClick={onSave}
              data-testid="save-button"
              className="gap-2 flex-1 sm:flex-initial"
            >
              <Check className="h-4 w-4" />
              {altSaveText || 'Save Edits'}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CMSModal;
