import React, { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'src/components/ui/dialog';
import { Input } from 'src/components/ui/input';
import { Button } from 'src/components/ui/button';
import { InviteData } from '../v2api/P2P/types';
import { postSubmitInvite } from '../v2api/P2P/http';
import { useAuth } from 'react-oidc-context';
import { UserRoundPlus, Copy } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const IMO = '6113890d-f6db-4b4d-838a-f811d60f4409';

interface InviteValidateProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const InviteValidate: React.FC<InviteValidateProps> = ({
  open,
  onOpenChange,
}) => {
  const auth = useAuth();
  const [inviteData, setInviteData] = useState<InviteData>({
    firstname: '',
    lastname: '',
  });
  const [inviteURL, setInviteURL] = useState<string | null>(null);

  const submitInvite = async () => {
    if (
      !inviteData.firstname ||
      !inviteData.lastname ||
      !auth?.user?.access_token
    ) {
      return;
    }
    const url = await postSubmitInvite(auth.user.access_token, inviteData);
    setInviteURL(url);
  };

  const handleClose = () => {
    setInviteURL(null);
    setInviteData({ firstname: '', lastname: '' });
    onOpenChange(false);
  };

  if (
    process.env.REACT_APP_P2P_DISABLED === 'true' &&
    auth?.user?.profile?.sub !== IMO
  ) {
    return (
      <Dialog open={open} onOpenChange={handleClose}>
        <DialogContent className="max-w-screen sm:max-w-[600px] max-h-screen sm:max-h-[90vh]">
          <DialogHeader>
            <DialogTitle>Coming soon</DialogTitle>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="max-w-screen sm:max-w-[600px] h-screen sm:h-auto max-h-screen sm:max-h-[90vh] flex flex-col justify-start sm:justify-center">
        <h2>Invite new user</h2>
        <div>
          {inviteURL ? (
            <>
              <p className="text-sm text-muted-foreground mb-4">
                Pre-validated invite created. The other user can now scan this
                QR code using their device's camera.
              </p>
              <div className="w-full max-w-[361px] aspect-square relative cursor-pointer rounded-2xl">
                <div
                  className="absolute inset-0 text-[#23a194] hover:text-foreground/80
          bg-[#c3f990] shadow-[inset_0px_0px_58px_8px_rgba(255,_255,_255,_0.9)] rounded-2xl"
                />
                <div className="absolute inset-[4px] flex items-center justify-center">
                  <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <QRCodeSVG
                        value={inviteURL}
                        size={300}
                        level="H"
                        fgColor="#2c4722"
                        bgColor="transparent"
                      />
                    </motion.div>
                  </AnimatePresence>
                </div>
              </div>

              {auth?.user?.profile?.sub === IMO && (
                <div className="bg-accent p-2 rounded-lg mt-4 flex items-center gap-2 justify-between">
                  <a
                    href={inviteURL}
                    className="pl-1 w-[280px] text-foreground underline decoration-dotted text-ellipsis overflow-hidden whitespace-nowrap hover:underline"
                  >
                    {inviteURL}
                  </a>
                  <Button
                    animated
                    variant="accent"
                    size="icon"
                    onClick={() => {
                      if (inviteURL) {
                        navigator.clipboard.writeText(inviteURL);
                      }
                    }}
                    title="Copy link"
                  >
                    <Copy className="h-4 w-4" />
                  </Button>
                </div>
              )}
            </>
          ) : (
            <>
              <p className="text-sm text-muted-foreground mb-4">
                Inviting someone will automatically validate them.
              </p>
              <div className="space-y-4 bg-accent p-3 rounded-lg">
                <Input
                  value={inviteData.firstname}
                  onChange={(e) =>
                    setInviteData((d) => ({
                      ...d,
                      firstname: e.target.value,
                    }))
                  }
                  placeholder="First name"
                  maxLength={100}
                />
                <Input
                  value={inviteData.lastname}
                  onChange={(e) =>
                    setInviteData((d) => ({
                      ...d,
                      lastname: e.target.value,
                    }))
                  }
                  placeholder="Last name"
                  maxLength={100}
                />
              </div>
              <div className="h-[68px]"></div>
              <DialogFooter className="backdrop-blur-lg bg-primary/20 p-4 rounded-t-none rounded-b-[20px] flex flex-row justify-end gap-2 absolute bottom-0 left-0 right-0">
                <Button
                  animated
                  size="lg"
                  variant="accent"
                  data-testid="cancel-button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  animated
                  size="lg"
                  onClick={submitInvite}
                  data-testid="save-button"
                >
                  <UserRoundPlus className="h-4 w-4" /> Invite
                </Button>
              </DialogFooter>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
