import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Html5Qrcode, Html5QrcodeCameraScanConfig } from 'html5-qrcode';

interface QRScannerProps {
  qrCodeSuccessCallback: (result: string) => void;
  isEnabled: boolean;
}

interface QRScannerHandle {
  forceStop: () => void;
}

export const QRScanner = forwardRef<QRScannerHandle, QRScannerProps>(
  ({ isEnabled, qrCodeSuccessCallback }, ref) => {
    const scannerRef = useRef<Html5Qrcode | null>(null);
    const [code, setCode] = useState<string | null>(null);

    useEffect(() => {
      if (code) {
        qrCodeSuccessCallback(code);
        setCode(null);
      }
    }, [code, qrCodeSuccessCallback]);

    const deactivate = useCallback(async () => {
      setCode(null);
      try {
        if (scannerRef.current) {
          await scannerRef.current.stop();
          scannerRef.current.clear();
          scannerRef.current = null;
        }
      } catch (error) {
        console.error('Error stopping scanner:', error);
      }
    }, []);

    const activate = useCallback(async () => {
      const config: Html5QrcodeCameraScanConfig = {
        fps: 10,
        qrbox: { height: 250, width: 250 },
      };
      try {
        await deactivate(); // Clean up any existing instance
        setCode(null);
        const htmlQrCode = new Html5Qrcode('reader');

        await htmlQrCode.start(
          { facingMode: 'environment' },
          config,
          (decodedText: string) => setCode(decodedText),
          () => {},
        );

        scannerRef.current = htmlQrCode;
      } catch (error) {
        console.error('Error starting scanner:', error);
      }
    }, [deactivate]);

    useImperativeHandle(ref, () => ({
      forceStop: deactivate,
    }));

    // Handle the isEnabled prop changes
    useEffect(() => {
      if (isEnabled) {
        activate();
      } else {
        deactivate();
      }
    }, [isEnabled, activate, deactivate]);

    // Cleanup on unmount
    useEffect(() => {
      return () => {
        deactivate();
      };
    }, [deactivate]);

    return (
      <div className="flex items-center justify-center w-full aspect-square">
        <div
          id="reader"
          className="w-full aspect-square [&_video]:rounded-xl [&_video]:w-full [&_video]:aspect-square [&_video]:object-cover [&_#qr-shaded-region]:rounded-xl [&_#qr-shaded-region]:border-primary-accent [&_#qr-shaded-region]:opacity-30"
        />
      </div>
    );
  },
);
