import React from 'react';
import CPEditButton from './CPEditButton';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { Switch } from 'src/components/ui/switch';

const CMSTitleInfo = ({
  title,
  isNotEmpty,
  message,
  onClick,
  isVisible,
  setVisible,
  isVisibilityDisabled,
  isCMS = true,
}) => {
  return (
    <div className="flex w-full justify-between">
      <h3 className="mb-4">{title}</h3>
      {isCMS && (
        <div className="flex space-x-2">
          {setVisible && (
            <TooltipProvider delayDuration={0}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="flex items-center h-8">
                    <Switch
                      animated
                      size="large"
                      icon
                      checked={isVisible}
                      onCheckedChange={setVisible}
                      disabled={isVisibilityDisabled}
                    />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <p className="text-xs font-medium">
                    Toggle public visibility
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
          {isNotEmpty && <CPEditButton title={message} onClick={onClick} />}
        </div>
      )}
    </div>
  );
};

export default CMSTitleInfo;
