import React, { useContext, useState } from 'react';
import { Plus, BookOpenText } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import CPVisibleIcon from '../CPVisibleIcon';
import { EditBio } from './EditBio';
import CMSTitleInfo from '../Title';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';
import { PersonaAccessContext } from '../../../v2api/PersonaAccessContext/PersonaAccessContext';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'src/components/ui/tabs';
import PropTypes from 'prop-types';

const BioTabPanel = ({ data }) => (
  <div className="flex p-4">
    <p className="flex-1 whitespace-pre-wrap">{data}</p>
  </div>
);

const Bio = ({ isCMS = true }) => {
  const { persona } = useContext(PersonaContext);
  const personaAccessContext = useContext(PersonaAccessContext);
  const { personaAccess } =
    isCMS && personaAccessContext
      ? personaAccessContext
      : { personaAccess: null };
  const [editorOpen, setEditorOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('headline');

  // Get first available tab with content
  const getDefaultTab = () => {
    if (persona?.biographyHeadline) return 'headline';
    if (persona?.biographyShort) return 'short';
    if (persona?.biographyLong) return 'long';
    return 'headline';
  };

  const getVisibilityForTab = (tab) => {
    switch (tab) {
      case 'headline':
        return personaAccess?.biographyHeadline;
      case 'short':
        return personaAccess?.biographyShort;
      case 'long':
        return personaAccess?.biographyLong;
      default:
        return false;
    }
  };

  const hasBios = !!(
    persona?.biographyLong ||
    persona?.biographyShort ||
    persona?.biographyHeadline
  );

  if (!isCMS && !hasBios) {
    return null;
  }

  return (
    <div>
      {isCMS && (
        <EditBio isOpen={editorOpen} closeModal={() => setEditorOpen(false)} />
      )}
      {hasBios ? (
        <div className="card">
          <CMSTitleInfo
            isCMS={isCMS}
            title="Bios"
            isNotEmpty={true}
            message="Edit bio"
            onClick={() => setEditorOpen(true)}
          />

          <Tabs
            defaultValue={getDefaultTab()}
            className="w-full -mt-10"
            onValueChange={setActiveTab}
          >
            <div
              className={`flex items-center justify-end gap-2 ${isCMS ? 'mr-10' : ''}`}
            >
              {isCMS && (
                <CPVisibleIcon isVisible={getVisibilityForTab(activeTab)} />
              )}
              <TabsList className="grid w-[250px] grid-cols-3">
                <TabsTrigger
                  value="headline"
                  disabled={!persona?.biographyHeadline}
                >
                  Headline
                </TabsTrigger>
                <TabsTrigger value="short" disabled={!persona?.biographyShort}>
                  Short
                </TabsTrigger>
                <TabsTrigger value="long" disabled={!persona?.biographyLong}>
                  Long
                </TabsTrigger>
              </TabsList>
            </div>

            <TabsContent value="headline">
              {persona?.biographyHeadline && (
                <BioTabPanel data={persona.biographyHeadline} />
              )}
            </TabsContent>
            <TabsContent value="short">
              {persona?.biographyShort && (
                <BioTabPanel data={persona.biographyShort} />
              )}
            </TabsContent>
            <TabsContent value="long">
              {persona?.biographyLong && (
                <BioTabPanel data={persona.biographyLong} />
              )}
            </TabsContent>
          </Tabs>
        </div>
      ) : (
        <div className="card-inactive">
          <div className="flex flex-col gap-4 items-center justify-center h-36">
            <div className="flex gap-2 opacity-20">
              <BookOpenText />
            </div>
            <p>Add a couple of lines about yourself</p>
            <Button
              size="lg"
              data-testid="add-data-button"
              onClick={() => setEditorOpen(true)}
            >
              <Plus className="w-4 h-4" />
              Biography
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

Bio.propTypes = {
  isCMS: PropTypes.bool,
};

export default Bio;
