// See passport context for documentation

import * as React from 'react';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import { PassportContext } from '../PassportContext/PassportContext.tsx';
import { Action, IPersonaAccess, PersonaAccessContextType } from './types';
import {
  getAttrsAccess,
  getPersonaAccess,
  patchAttrAccess,
  patchPersonaAccess,
  postPersonaAccess,
} from './http';
import { PersonaContext } from '../PersonaContext/PersonaContext.tsx';
import { ICustomAccess } from '../extraTypes.ts';
import { useAuth } from 'react-oidc-context';

const initPersonaState: IPersonaAccess | null = null;
const initAttrState: ICustomAccess | null = null;

export const PersonaAccessContext =
  createContext<PersonaAccessContextType | null>(null);

// Reducers for React context state "mirrored" state.
function personaReducer(
  state: IPersonaAccess | null,
  action: Action,
): IPersonaAccess | null {
  switch (action.method) {
    case 'LOAD':
      return action.payload;
    case 'PATCH':
      return { ...state, ...action.payload } as IPersonaAccess;
    case 'POST':
      if (action.payload === false) {
        // @ts-ignore - come on type script
        return Object.fromEntries(
          Object.keys(state).map((k) => [k, false]),
        ) as IPersonaAccess;
      } else {
        console.warn(
          'Cannot simply POST public to reducer - must fetch computed values',
        );
        return state;
      }
    default:
      console.warn(
        `Unhandled persona state action: <${JSON.stringify(action)}>`,
      );
      return state;
  }
}

function attrReducer(
  state: ICustomAccess | null,
  action: Action,
): ICustomAccess | null {
  switch (action.method) {
    case 'ATTR_LOAD':
      return action.payload;
    case 'ATTR_PATCH':
      return { ...state, ...action.payload } as ICustomAccess;
    default:
      console.warn(
        `Unhandled persona attribute state action: <${JSON.stringify(action)}>`,
      );
      return state;
  }
}

interface PersonaAccessProviderProps {
  children: ReactNode;
}

export const PersonaAccessProvider: React.FC<PersonaAccessProviderProps> = ({
  children,
}) => {
  const auth = useAuth();
  const [personaAccess, personaDispatch] = useReducer(
    personaReducer,
    initPersonaState,
  );
  const [linkAttrAccess, officialLinkDispatch] = useReducer(
    attrReducer,
    initAttrState,
  );
  const [projAttrAccess, customProjectDispatch] = useReducer(
    attrReducer,
    initAttrState,
  );
  const [idAttrAccess, customIdDispatch] = useReducer(
    attrReducer,
    initAttrState,
  );
  const [representativeAccess, repDispatch] = useReducer(
    attrReducer,
    initAttrState,
  );
  const [currentAction, setCurrentAction] = useState<Action | null>(null);

  const [isBusy, setIsBusy] = useState(false);

  const { activePersonaId } = useContext(PassportContext);
  const { persona } = useContext(PersonaContext);

  const isPagePublic = personaAccess?.personaId || false;

  /* All callback chains must eventually `setIsBusy(false)` !! */
  /* See PassportProvider for full docs */
  useEffect(() => {
    if (currentAction === null) return;
    const oldState = { ...personaAccess };
    const asyncEffect = async (action: Action, token: string) => {
      if (isBusy)
        return console.warn(
          `access action in progress, ${action.method} REJECTED`,
        );
      setIsBusy(true);
      // console.log(`access action initiated: ${JSON.stringify(action)}`)
      switch (action.method) {
        case 'SERVERLOAD':
          await getPersonaAccess(token, action.payload).then((data) => {
            personaDispatch({ method: 'LOAD', payload: data });
            return setIsBusy(false);
          });
        // eslint-disable-next-line no-fallthrough
        case 'ATTR_SERVERLOAD':
          if (persona === null) {
            // This is a hack. the proper way would be to only fire ATTR_SERVERLOAD after the persona call has returned
            // Even if this polling spins for a whole second the UX is unaffected, attribute access is nested in the UI
            console.warn(
              'persona not yet loaded, delaying attribute access query',
            );
            setTimeout(
              () =>
                setCurrentAction({
                  method: 'ATTR_SERVERLOAD',
                  payload: action.payload,
                }),
              200,
            );
            return setIsBusy(false);
          }
          const projectKeys = persona.customProjects.map(
            (proj) => proj.uniqueKey,
          );
          const idKeys = persona.customIds.map((cid) => cid.uniqueKey);
          const linkKeys = persona.officialLinks.map((oli) => oli.uniqueKey);
          await Promise.all([
            getAttrsAccess(
              token,
              action.payload,
              'customProjects',
              projectKeys,
            ).then((data) =>
              customProjectDispatch({
                method: 'ATTR_LOAD',
                payload: data,
              }),
            ),
            getAttrsAccess(token, action.payload, 'customIds', idKeys).then(
              (data) =>
                customIdDispatch({
                  method: 'ATTR_LOAD',
                  payload: data,
                }),
            ),
            getAttrsAccess(
              token,
              action.payload,
              'officialLinks',
              linkKeys,
            ).then((data) =>
              officialLinkDispatch({
                method: 'ATTR_LOAD',
                payload: data,
              }),
            ),
            getAttrsAccess(
              token,
              action.payload,
              'representatives',
              linkKeys,
            ).then((data) =>
              repDispatch({
                method: 'ATTR_LOAD',
                payload: data,
              }),
            ),
          ]);
          return setIsBusy(false);

        case 'LOAD':
          personaDispatch(action);
          return setIsBusy(false);
        case 'ATTR_LOAD':
          console.warn('ATTR_LOAD not implemented'); // FIXME
          return setIsBusy(false);
        case 'POST':
          // POST is for whole-persona visibility, PATCH is per-field updates
          if (isPagePublic === action.payload) return setIsBusy(false);
          if (action.payload === false) {
            personaDispatch(action);
          }
          return await postPersonaAccess(token, activePersonaId, action.payload)
            .then((postSuccess) => {
              if (postSuccess) {
                if (action.payload === true) {
                  getPersonaAccess(token, activePersonaId).then((data) => {
                    personaDispatch({ method: 'LOAD', payload: data });
                  });
                }
                setTimeout(
                  () =>
                    setCurrentAction({
                      method: 'ATTR_SERVERLOAD',
                      payload: activePersonaId,
                    }),
                  1000,
                );
                toast.success('Profile visibility updated');
              } else {
                toast.error('Visibility change failed');
                setTimeout(
                  () => setCurrentAction({ method: 'LOAD', payload: oldState }),
                  1000,
                );
              }
              return setIsBusy(false);
            })
            .catch((err) => {
              console.warn(err);
              setTimeout(
                () => setCurrentAction({ method: 'LOAD', payload: oldState }),
                1000,
              );
              return setIsBusy(false);
            });
        case 'PATCH':
          if (!isPagePublic) {
            console.warn('Refusing to patch, profile not public');
            return setIsBusy(false);
          }
          personaDispatch(action);
          return await patchPersonaAccess(
            token,
            activePersonaId,
            action.payload,
          )
            .then((patchSuccess) => {
              if (patchSuccess) {
                toast.success('Profile access updated successfully!');
                if (
                  'customIds' in action.payload ||
                  'customProjects' in action.payload ||
                  'officialLinks' in action.payload
                ) {
                  setTimeout(
                    () =>
                      setCurrentAction({
                        method: 'ATTR_SERVERLOAD',
                        payload: activePersonaId,
                      }),
                    200,
                  );
                }
              } else {
                toast.error(`Update failed`);
                setTimeout(
                  () => setCurrentAction({ method: 'LOAD', payload: oldState }),
                  1000,
                );
              }
              setIsBusy(false);
            })
            .catch((err) => {
              console.warn(err);
              setTimeout(
                () => setCurrentAction({ method: 'LOAD', payload: oldState }),
                1000,
              );
              setIsBusy(false);
            });
        case 'ATTR_PATCH':
          if (!isPagePublic) {
            console.warn('Refusing to patch, profile not public');
            return setIsBusy(false);
          }
          const dispatch = {
            customProjects: customProjectDispatch,
            customIds: customIdDispatch,
            officialLinks: officialLinkDispatch,
            representatives: repDispatch,
          }[action.attrsType];
          const oldAttrState = {
            customProjects: projAttrAccess,
            customIds: idAttrAccess,
            officialLinks: linkAttrAccess,
            representatives: representativeAccess,
          }[action.attrsType];
          dispatch(action);
          return await patchAttrAccess(
            token,
            activePersonaId,
            action.attrsType,
            action.payload,
          )
            .then((patchSuccess) => {
              if (patchSuccess) {
                toast.success('Profile access updated successfully!');
              } else {
                toast.error(`Update failed`);
                setTimeout(
                  () =>
                    setCurrentAction({
                      method: 'ATTR_LOAD',
                      payload: oldAttrState,
                    }),
                  1000,
                );
              }
              return setIsBusy(false);
            })
            .catch((err) => {
              console.warn(err);
              setTimeout(
                () =>
                  setCurrentAction({
                    method: 'ATTR_LOAD',
                    payload: oldAttrState,
                  }),
                1000,
              );
              return setIsBusy(false);
            });
        default:
          console.warn(`Unknown action on persona access: ${action}`);
          setIsBusy(false);
      }
    };
    // Async chain starts
    const token = auth.user?.access_token;
    if (token) {
      asyncEffect(currentAction, token).catch((err: any) => {
        console.warn(err);
        setTimeout(
          () => setCurrentAction({ method: 'LOAD', payload: oldState }),
          1000,
        );
        setIsBusy(false);
      });
    } else {
      console.warn('Auth manager did not provide an access token');
      setIsBusy(false);
    }
    return () => setCurrentAction(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAction, personaAccess, activePersonaId, isPagePublic]);

  useEffect(() => {
    if (activePersonaId) {
      setCurrentAction({ method: 'SERVERLOAD', payload: activePersonaId });
    }
  }, [activePersonaId]);

  const updateAccess = useCallback((action: Action) => {
    setCurrentAction(action);
  }, []);

  return (
    <PersonaAccessContext.Provider
      value={{
        personaAccess,
        projAttrAccess,
        idAttrAccess,
        linkAttrAccess,
        representativeAccess,
        updateAccess,
      }}
    >
      {children}
    </PersonaAccessContext.Provider>
  );
};
