import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import EditRepresentative from './EditRepresentative';
import { useState } from 'react';
import { Box } from '@mui/system';

const DraggableRepresentativeItem = ({
  id,
  repData,
  handleChange,
  handleDelete,
  isVisible,
  setVisible,
  areRepsHidden,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [isDragging, setIsDragging] = useState(false);

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Box
        onMouseDown={() => setIsDragging(true)}
        onMouseUp={() => setIsDragging(false)}
        sx={[
          isDragging
            ? {
                cursor: 'grabbing',
              }
            : {
                cursor: 'grab',
              },
        ]}
        value={'Not implemented'}
      >
        <EditRepresentative
          representativeType={repData.representativeType}
          repName={repData.repName}
          contactEmail={repData.contactEmail}
          contactNote={repData.contactNote}
          contactPhone={repData.contactPhone}
          handleChange={(patch) =>
            handleChange({ ...patch, uniqueKey: repData.uniqueKey })
          }
          onDelete={() => handleDelete(repData.uniqueKey)}
          isVisible={isVisible}
          setVisible={setVisible}
          areRepsHidden={areRepsHidden}
        />
      </Box>
    </div>
  );
};

export default DraggableRepresentativeItem;
