import React from 'react';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';

const customBreakpointValues = {
  values: {
    xs: 0,
    sm: 600,
    md: 1024,
    lg: 1280,
    xl: 1536,
  },
};

export default function BaseThemeProvider(props) {
  const baseTheme = createTheme({
    breakpoints: {
      ...customBreakpointValues,
    },
  });
  const mainTheme = createTheme(baseTheme, {
    breakpoints: {
      ...customBreakpointValues,
    },
    palette: {
      primary: {
        //Grey
        dark: `hsl(210, 2%, 10%)`, //CP logo
        main: `hsl(210, 2%, 20%)`, //Main text
        hint: `hsl(210, 2%, 40%)`, //Button hover and sub-headings
      },
      green: {
        //Green
        lighter: `hsl(166, 100%, 95%)`, //Chip background
        light: `hsl(166, 100%, 90%)`,
        main: `hsl(166, 100%, 75%)`, //Chip border
        dark: `hsl(166, 100%, 50%)`,
        darker: `hsl(166, 100%, 35%)`,
        darkest: `hsl(166, 100%, 20%)`,
        contrastText: `hsl(166, 100%, 10%)`,
      },
      blue: {
        //Blue
        lighter: `hsl(210, 70%, 94%)`,
        light: `hsl(210, 70%, 81%)`,
        main: `hsl(210, 70%, 68%)`,
        dark: `hsl(210, 34%, 47%)`, //Label background
        darker: `hsl(210, 33%, 27%)`,
        darkest: `hsl(210, 33%, 20%)`,
        contrastText: `hsl(210, 33%, 10%)`,
      },
      background: {
        paper: `hsl(210, 14%, 100%)`, //White
        default: `hsl(210, 0%, 97%)`, //Dashboard background // FIXME this overrides dark public page
        dark: `hsl(210, 14%, 94%)`,
        darker: `hsl(210, 14%, 89%)`,
      },
    },
    typography: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      paddingLeft: '0.75rem',
      paddingRight: '0.75rem',
      h1: {
        //Artist Name
        fontFamily: ['Inter', 'sans-serif'].join(','),
        color: `hsl(210, 2%, 20%)`, //palette.primary.main
        fontSize: '28px',
        fontWeight: 700,
        letterSpacing: 'normal',
      },
      h2: {
        //Login, Sign up
        fontFamily: ['Inter', 'sans-serif'].join(','),
        color: `hsl(210, 2%, 20%)`, //palette.primary.main
        fontSize: '20px',
        fontWeight: 700,
        letterSpacing: 'normal',
      },
      h3: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        textTransform: 'Capitalize',
        fontWeight: '500',
        fontSize: 20,
        color: `hsl(210, 2%, 20%)`, //palette.primary.main
      },
      h4: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontWeight: '500',
        fontSize: 16,
        color: `hsl(210, 2%, 40%)`, //palette.primary.hint
      },
      h5: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        color: `hsl(210, 2%, 20%)`, //palette.primary.main
        fontSize: 14,
        fontWeight: 700,
      },
      body1: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontSize: 14,
        color: `hsl(210, 2%, 20%)`, //palette.primary.main
      },
      body2: {
        fontSize: 11,
        color: `hsl(210, 2%, 20%)`, //palette.primary.main
      },
      subtitle1: {
        fontSize: 12,
        lineHeight: 1.5,
        color: `hsl(210, 2%, 20%)`, //palette.primary.main
      },
      help: {
        height: '16px',
        color: `hsl(210, 34%, 47%)`, //palette.blue.dark
        width: '100%',
        textAlign: 'left',
        alignItems: 'center',
        fontFamily: 'Heebo, Sans-serif',
        fontSize: '12px',
        display: 'flex',
      },
    },
    props: {
      MuiButton: {
        color: 'primary',
        variant: 'contained',
        disableElevation: true,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: ({ breakpoints }) => ({
          body: {
            backgroundColor: '',
          },
        }),
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            padding: '1.5rem',
            '&:last-child': {
              padding: '1.5rem',
            },
            [baseTheme.breakpoints.only('xs')]: {
              padding: '1rem',
              '&:last-child': {
                padding: '1rem',
              },
            },
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: '0rem 1.5rem 1rem 1.5rem',
            '&:last-child': {
              padding: '0rem 1.5rem 1rem 1.5rem',
            },
            [baseTheme.breakpoints.only('xs')]: {
              padding: '0rem 1rem 1rem 1rem',
              '&:last-child': {
                padding: '0rem 1rem 1rem 1rem',
              },
            },
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: '1.5rem',
            '&:last-child': {
              padding: '0rem 1.5rem 1.5rem 1.5rem',
            },
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          maxWidthLg: {
            [baseTheme.breakpoints.up('lg')]: {
              maxWidth: '1536px',
            },
          },
          root: {
            [baseTheme.breakpoints.only('xl')]: {
              paddingRight: '4rem',
              paddingLeft: '4rem',
            },
            [baseTheme.breakpoints.only('lg')]: {
              paddingRight: '4rem',
              paddingLeft: '4rem',
            },
            [baseTheme.breakpoints.only('md')]: {
              paddingRight: '4rem',
              paddingLeft: '4rem',
            },
            [baseTheme.breakpoints.only('sm')]: {
              paddingLeft: '1rem',
              paddingRight: '1rem',
            },
            [baseTheme.breakpoints.only('xs')]: {
              paddingLeft: '0rem',
              paddingRight: '0rem',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          rounded: {
            [baseTheme.breakpoints.only('xs')]: {
              borderRadius: '0rem',
            },
            borderRadius: '1rem',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '28px',
            fontFamily: ['Roboto', 'sans-serif'].join(','),
            cursor: 'pointer',
            alignItems: 'center',
            boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
            '&:hover': {
              backgroundColor: '#D3D3D3',
              color: '#313233',
              boxShadow: 'none',
            },
          },
          sizeSmall: {
            minHeight: '1rem',
          },
          outlined: {
            // backgroundColor: '#F7F8F9',
            border: 'transparent',
            width: '100%',
            color: '#626466',
            boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
            '&:hover': {
              backgroundColor: '#D3D3D3',
              color: '#313233',
              boxShadow: 'none',
            },
          },
          contained: {
            // backgroundColor: '#F7F8F9',
            border: 'transparent',
            width: '100%',
            color: '#626466',
            boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
            '&:hover': {
              backgroundColor: '#D3D3D3',
              color: '#313233',
              boxShadow: 'none',
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          filled: {
            borderRadius: '1rem',
            backgroundColor: '#FFFFFF',
            boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.1)',
            border: 'none',
            height: '1rem',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            margin: '0px',
            borderRadius: '16px',
            '@media (max-width: 600px)': {
              width: '100%',
              borderRadius: '0px',
              minHeight: '100%',
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: '1.5rem!important',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '16px 24px',
            backgroundColor: `hsl(210, 14%, 94%)`,
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: '1px solid #8A8A8A',
            },
            '&:before': { borderBottom: '1px solid #00000010' },
            '&:after': { borderBottom: '1px solid #000' },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            borderLeft: 'none',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            marginLeft: 0,
            marginRight: 0,
          },
        },
      },
      MuiTabs: {
        //We have no design for how tabs are handled on mobile.  Maybe just remove gap and radius
        styleOverrides: {
          flexContainer: {
            display: 'flex',
            gap: '1rem',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            maxWidth: '50%',
            fontWeight: '400',
            display: 'inline',
            margin: 'auto',
            verticalAlign: 'middle',
            minHeight: 0,
            marginRight: '0',
            marginLeft: '0',
            '&.Mui-selected': {
              backgroundColor: 'var(--paper-background)',
              borderRadius: '0.75rem',
              fontFamily: 'Roboto, Sans-serif',
              color: '#313233',
              textStroke: '0.3px black',
              WebkitTextStroke: '0.3px black',
              strokeLinejoin: 'round',
              '&:hover': {
                backgroundColor: 'var(--paper-background)',
              },
            },
            '&:hover': {
              borderRadius: '0.75rem',
              backgroundColor: `hsl(210, 14%, 94%)`,
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: '0',
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '&.Mui-focusVisible': { backgroundColor: 'transparent' },
            },
          },
        },
      },
    },
  });

  mainTheme.components = {
    ...mainTheme.components,
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '--paper-background': mainTheme.palette.background.paper,
          '--default-background': mainTheme.palette.background.default,
        },
      },
    },
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mainTheme}>{props.children}</ThemeProvider>
    </StyledEngineProvider>
  );
}

export { BaseThemeProvider };
