import React, { useContext } from 'react';
import PersonaPrivateView from '../../CMS/components/PersonaPrivateView';
import ProgressTimeout from '../../components/ProgressTimeout';

import { PersonaProvider } from '../../v2api/PersonaContext/PersonaContext';
import { PassportContext } from '../../v2api/PassportContext/PassportContext';
import { useAuth } from 'react-oidc-context';

const CMSPrivateView = () => {
  const auth = useAuth();
  const { passport, activePersonaId, personaJustAdded, updatePassport } =
    useContext(PassportContext);

  const handleTabDelete = async (personaId) => {
    updatePassport({ method: 'DELETE_PERSONA', payload: personaId });
  };

  if (!passport?.personaIds) {
    return <ProgressTimeout timeoutMs={2000} />;
  }

  return (
    <PersonaProvider
      auth={auth}
      activePersonaId={activePersonaId}
      personaJustAdded={personaJustAdded}
      updatePassport={updatePassport}
    >
      {passport.personaSummaries?.map(
        (summary) =>
          summary.personaId === activePersonaId && (
            <div key={summary.personaId}>
              <PersonaPrivateView
                onDelete={() => handleTabDelete(summary.personaId)}
                isDeleting={false}
              />
            </div>
          ),
      )}
    </PersonaProvider>
  );
};

export default CMSPrivateView;
