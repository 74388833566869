import React, { useContext, useState } from 'react';
import { Button } from 'src/components/ui/button';
import {
  deleteConnection,
  deleteValidation,
  postValidationRequest,
} from 'src/v2api/P2P/http';
import toast from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { P2PConnection } from 'src/v2api/P2P/types.ts';
import { ConfirmAsync } from 'src/P2P/Connections/ConfirmAsync.tsx';
import { P2PContext } from 'src/v2api/P2P/P2PContext.tsx';
import { ArrowLeft, BadgeCheck, Trash2 } from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import { PersonaImageContext } from 'src/v2api/PersonaImageContext/PersonaImageContext.tsx';
import { Link } from 'react-router-dom';

type ConnectionCardProps = {
  p2pConnection: P2PConnection;
};

const ConnectionCard: React.FC<ConnectionCardProps> = ({ p2pConnection }) => {
  const { user } = useAuth();
  const { p2pRefresh } = useContext(P2PContext);
  const { imgObjectURL } = useContext(PersonaImageContext);
  const [confirmModal, setConfirmModal] = useState<string | null>(null);
  const {
    connection,
    incoming_validation,
    outgoing_validation,
    incoming_request_status,
    incoming_request_code,
    outgoing_request_status,
    incoming_request_id,
  } = p2pConnection;

  const handleDeleteConnection = async (connectionId: string) => {
    if (!user?.access_token) return;
    try {
      if (incoming_validation || outgoing_validation) {
        // FIXME make this a modal
        if (
          window.confirm(
            'This connection has associated validations. Would you like to delete the validation(s) as well?',
          )
        ) {
          // FIXME move up
          if (incoming_validation) {
            await deleteValidation(
              user.access_token,
              incoming_validation.validation_id,
            );
          }
          if (outgoing_validation) {
            await deleteValidation(
              user.access_token,
              outgoing_validation.validation_id.toString(),
            );
          }
        }
      }

      await deleteConnection(user.access_token, parseInt(connectionId));
      p2pRefresh();
      toast.success('Connection deleted successfully');
      // await fetchConnections();
    } catch (error) {
      toast.error('Failed to delete connection');
    }
  };

  const handleRequestValidation = async () => {
    if (!user?.access_token) return;
    try {
      await postValidationRequest(user.access_token, connection.connection_id);
      p2pRefresh();
      toast.success('Validation requested');
      // await fetchValidations();
    } catch (error) {
      toast.error(`Failed to request validation ${error.message}`);
    }
  };

  const getValidationStatusMessage = () => {
    // If we have only an incoming validation
    if (incoming_validation && !outgoing_validation) {
      return `${connection.peer_name} validated you`;
    }

    // If we have only an outgoing validation
    if (!incoming_validation && outgoing_validation) {
      return (
        <div className="flex flex-col items-center justify-center text-center">
          <div>
            <span>You validated {connection.peer_name}</span>
            {outgoing_request_status === 'PENDING' ? (
              <span className="text-muted-foreground">
                &nbsp;and have requested validation back.
              </span>
            ) : (
              <span>.</span>
            )}
          </div>
          {outgoing_request_status !== 'PENDING' && (
            <Button
              animated
              className="mt-1"
              onClick={() => handleRequestValidation()}
            >
              Request Back
            </Button>
          )}
        </div>
      );
    }

    // Return null if we don't have both validations
    if (!incoming_validation || !outgoing_validation) return null;

    if (incoming_validation.is_valid && outgoing_validation.is_valid) {
      return `You and ${connection.peer_name} have validated each other.`;
    }

    if (outgoing_validation.is_valid && !incoming_validation.is_valid) {
      return `Your connection with ${connection.peer_name} is no longer valid because you changed your name.`;
    }

    if (!outgoing_validation.is_valid && incoming_validation.is_valid) {
      return `Your connection with ${connection.peer_name} is no longer valid because they changed their name.`;
    }

    return `Your connection with ${connection.peer_name} is no longer valid because you both changed your name.`;
  };

  const getStatusGradient = (): string => {
    // First, ensure both validation objects exist and check their validity
    const hasIncoming =
      incoming_validation !== null && incoming_validation !== undefined;
    const hasOutgoing =
      outgoing_validation !== null && outgoing_validation !== undefined;

    // Case 1: Only incoming validation
    if (hasIncoming && !hasOutgoing) {
      return 'from-[#ddd] via-[#ddd] to-[#1ae0cd]'; // Peer validated you
    }

    // Case 2: Only outgoing validation
    if (!hasIncoming && hasOutgoing) {
      return 'from-[#ff00b3] via-[#ddd] to-[#ddd]'; // You validated peer
    }

    // Case 3: Both validations exist
    if (hasIncoming && hasOutgoing) {
      const bothValid =
        incoming_validation.is_valid && outgoing_validation.is_valid;
      return bothValid
        ? 'from-[#ff00b3] via-[#aa82ff] to-[#1ae0cd]' // Validated each other
        : 'from-[#ff00b3] via-[#ddd] to-[#1ae0cd]'; // Invalid validations
    }

    // Case 4: No validations
    return 'from-[#ddd] via-[#ddd] to-[#ddd]';
  };

  return (
    <>
      <ConfirmAsync
        validationCode={incoming_request_code}
        messageId={incoming_request_id}
        isOpen={confirmModal !== null}
        onClose={() => setConfirmModal(null)}
      />
      <div className="w-full bg-accent p-4 rounded-2xl">
        <div className="w-full" />
        <div className="flex items-center space-x-4 mb-4">
          <div className="w-full flex flex-row justify-between items-center">
            <div className="flex flex-row gap-2 items-center">
              {/*
              <Link to={`/page/${connection.peer_profile_id}`}>
                <Button
                  animated
                  variant="accent"
                  size="icon"
                  className="w-8 h-8"
                >
                  <LinkIcon className="w-4 h-4" />
                </Button>
              </Link>
              */}
              <p className="font-medium">
                {connection.peer_name || connection.passport_id}
              </p>
            </div>
            <div className="flex flex-row gap-2">
              <div className="flex items-center opacity-80">
                {new Date(connection.connection_made).toLocaleString(
                  undefined,
                  {
                    dateStyle: 'short',
                    timeStyle: 'short',
                  },
                )}
              </div>
              <Dialog>
                <DialogTrigger asChild>
                  <Button
                    animated
                    variant="accent"
                    size="icon"
                    className="w-8 h-8"
                  >
                    <Trash2 className="w-4 h-4" />
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Delete Connection</DialogTitle>
                    <DialogDescription>
                      Are you sure you want to delete this connection? This
                      action cannot be undone.
                    </DialogDescription>
                  </DialogHeader>
                  <div className="h-[68px]"></div>

                  <DialogFooter className="backdrop-blur-lg bg-primary/20 p-4 rounded-t-none rounded-b-[20px] flex flex-row justify-end gap-2 absolute bottom-0 left-0 right-0">
                    <DialogClose asChild>
                      <Button animated size="lg" variant="ghost">
                        Cancel
                      </Button>
                    </DialogClose>
                    <Button
                      animated
                      size="lg"
                      variant="destructive"
                      onClick={() =>
                        handleDeleteConnection(
                          connection.connection_id.toString(),
                        )
                      }
                    >
                      <Trash2 className="w-4 h-4" />
                      Delete
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>
        {/* Validations row */}
        <div
          className={`w-full grid grid-cols-3 gap-4 bg-gradient-to-r ${getStatusGradient()} shadow-[inset_0px_0px_88px_50px_rgba(255,_255,_255,_0.5)] p-1 rounded-full`}
        >
          {' '}
          {/* Out Validation */}
          <div className="w-full">
            {outgoing_validation ? (
              <div className="flex flex-col">
                <Link to={`/page/${connection.peer_profile_id}`}>
                  <Avatar className="h-20 w-20 hover:scale-110 transition-all">
                    <AvatarImage
                      src={`${process.env.REACT_APP_V2_API_BASE}/media/persona/${connection?.peer_profile_id}/small.webp`}
                    />
                    <AvatarFallback>
                      {outgoing_validation.validated_data.firstname.charAt(0)}
                      {outgoing_validation.validated_data.lastname.charAt(0)}
                    </AvatarFallback>
                  </Avatar>
                </Link>
              </div>
            ) : (
              <div className="flex flex-col">
                <Link to={`/page/${connection.peer_profile_id}`}>
                  <Avatar className="h-20 w-20 hover:scale-110 transition-all">
                    <AvatarImage
                      src={`${process.env.REACT_APP_V2_API_BASE}/media/persona/${connection?.peer_profile_id}/small.webp`}
                    />
                    <AvatarFallback className="uppercase">
                      {connection.peer_name?.slice(0, 2) || '??'}
                    </AvatarFallback>
                  </Avatar>
                </Link>
              </div>
            )}
          </div>
          {/* Arrows column */}
          <div className="flex flex-col items-center justify-center gap-2">
            {incoming_validation?.is_valid && outgoing_validation?.is_valid ? (
              <BadgeCheck className="w-8 h-8 text-foreground mix-blend-overlay" />
            ) : (
              <>
                <ArrowLeft
                  className={`w-6 h-6 ${incoming_validation ? 'text-foreground opacity-50 rotate-180' : 'text-foreground opacity-10 rotate-180'}`}
                />
                <ArrowLeft
                  className={`w-6 h-6 ${outgoing_validation ? 'text-foreground opacity-50' : 'text-foreground opacity-10'}`}
                />
              </>
            )}
          </div>
          {/* In Validation */}
          <div className="w-full">
            {incoming_validation ? (
              <div className="flex text-right justify-end">
                <Avatar className="h-20 w-20">
                  <AvatarImage src={imgObjectURL} />
                  <AvatarFallback>
                    {incoming_validation.validated_data.firstname.charAt(0)}
                    {incoming_validation.validated_data.lastname.charAt(0)}
                  </AvatarFallback>
                </Avatar>
                {/*<div>
                          Auracle ID:{' '}
                          {incoming_validation.validated_data.auracle_id}
                        </div>
                        <div>
                          Validated on:{' '}
                          {new Date(
                            incoming_validation.validation_made,
                          ).toLocaleDateString()}
                        </div>*/}
              </div>
            ) : (
              <div className="flex items-center justify-center text-muted-foreground">
                <div className="flex text-right justify-end w-full">
                  <Avatar className="h-20 w-20">
                    <AvatarImage src={imgObjectURL} />
                    <AvatarFallback>You</AvatarFallback>
                  </Avatar>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Add the validation status message */}
        {(incoming_validation || outgoing_validation) && (
          <div className="mt-4 text-center text-foreground/50 mx-0mx-16">
            {getValidationStatusMessage()}
            {incoming_request_status === 'PENDING' ? (
              <span>
                , and has requested you validate them back.<br></br>
                <Button
                  animated
                  className="mt-2"
                  size="sm"
                  onClick={() => setConfirmModal(incoming_request_code)}
                >
                  Validate Back
                </Button>
              </span>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default ConnectionCard;
