import React from 'react';
import CPVisibleIcon from '../../CPVisibleIcon';
import { Phone, Mail } from 'lucide-react';
import { repTypes } from '../repTypes';

const ReadOnlyRepresentative = ({
  representative,
  isVisible,
  isCMS = true,
}) => {
  const contactRepType = repTypes[representative?.representativeType]?.label;

  return (
    <div
      className={`${isVisible ? 'bg-blueberry-foreground/20' : 'bg-blueberry-foreground/5'} p-4 rounded-md h-full`}
    >
      <div className="flex flex-row items-center justify-between mb-2">
        <span className="text-xs font-medium px-2 py-1 bg-blueberry rounded">
          {contactRepType}
        </span>
        {isCMS && <CPVisibleIcon isVisible={isVisible} />}
      </div>

      <h2>{representative?.repName}</h2>

      <div className="space-y-2 mb-3">
        {representative?.contactPhone && (
          <div className="flex items-center gap-2">
            <Phone className="w-4 h-4" />
            <span className="underline decoration-dotted">
              {representative.contactPhone}
            </span>
          </div>
        )}

        {representative?.contactEmail && (
          <div className="flex items-center gap-2">
            <Mail className="w-4 h-4" />
            <span className="underline decoration-dotted">
              {representative.contactEmail}
            </span>
          </div>
        )}
      </div>

      {representative?.contactNote && (
        <p className="text-xs opacity-50">{representative.contactNote}</p>
      )}
    </div>
  );
};

export default ReadOnlyRepresentative;
