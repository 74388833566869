import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from './styles';
import { Button } from 'src/components/ui/button';
import CPDeleteButton from '../../CPDeleteButton';
import ProfileImage from '../../../../components/ProfileImage';
import CPRadio from '../../../../components/CPRadio';
import CPAutocomplete from '../../CPAutocomplete';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import CPSwitch from '../../CPSwitch';
import { PersonaContext } from '../../../../v2api/PersonaContext/PersonaContext';
import { PassportContext } from '../../../../v2api/PassportContext/PassportContext';
import SoloProject from '../../../../icons/atomSoloProject';
import Drum from '../../../../icons/atomDrum';
import OrchestraIcon from '../../../../icons/atomOrchestra';
import GroupIcon from '../../../../icons/atomGroup';
import Puzzle from '../../../../icons/atomPuzzle';
import { PersonaAccessContext } from '../../../../v2api/PersonaAccessContext/PersonaAccessContext';
import { useAuth } from 'react-oidc-context';
import { Check, Trash2, ChevronsUpDown } from 'lucide-react';
import { Input } from 'src/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import { Portal } from '@radix-ui/react-portal';
import { Label } from 'src/components/ui/label';
import { cn } from 'src/lib/utils';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from 'src/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/ui/popover';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'src/components/ui/dialog';
import { motion } from 'framer-motion';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'src/components/ui/alert-dialog';

const useStyles = makeStyles(styles);

const SummaryEdit = ({ isOpen, closeModal }) => {
  const auth = useAuth();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { persona, updatePersona } = useContext(PersonaContext);
  const { passport, updatePassport } = useContext(PassportContext);
  const { personaAccess, updateAccess } = useContext(PersonaAccessContext);

  const projectTypes = [
    { name: 'Solo project', slug: 'solo', icon: SoloProject },
    { name: 'Band', slug: 'band', icon: Drum },
    { name: 'Orchestra', slug: 'orchestra', icon: OrchestraIcon },
    { name: 'Collective', slug: 'collective', icon: GroupIcon },
    { name: 'Other', slug: 'other', icon: Puzzle },
  ];

  // The patch represents the changes we have made, (excluding no-ops) so we can press save and patch the React state
  // and db with the same patch. `key: null` indicates a deletion. If the value is set to "", this should indicate
  // that that key needs to be removed from the patch on submit - this is not a proper delete.
  // On submit, delete any keys where value is ""; delete key if value == oldValue, or if value=null and oldValue undefined
  const [personaPatch, setPersonaPatch] = useState({});
  const [passportPatch, setPassportPatch] = useState({});
  const [state, setState] = useState({});

  const [personaVisibilityPatch, setPersonaVisibilityPatch] = useState({});
  const [open, setOpen] = useState(false);
  const [showNameChangeAlert, setShowNameChangeAlert] = useState(false);
  const [pendingSave, setPendingSave] = useState(null);

  useEffect(() => {
    if (!persona) return;
    const personaKeys = [
      'aliasOverwritesName',
      'city',
      'musicRoles',
      'pronouns',
      'alias',
      'personaType',
      'features',
    ];
    const patchedState = Object.fromEntries(
      personaKeys.map((key) => [
        key,
        key in personaPatch ? personaPatch[key] : persona[key] || null,
      ]),
    );
    patchedState.aliasOverwritesName =
      patchedState.aliasOverwritesName || false;
    if (persona.isProfessionalProfile) {
      patchedState.unverifiedFirstname =
        'unverifiedFirstname' in passportPatch
          ? passportPatch.unverifiedFirstname
          : passport.unverifiedFirstname;
      patchedState.unverifiedLastname =
        'unverifiedLastname' in passportPatch
          ? passportPatch.unverifiedLastname
          : passport.unverifiedLastname;
      patchedState.countryAlpha2 =
        'unverifiedCountry' in passportPatch
          ? passportPatch.unverifiedCountry
          : passport.unverifiedCountry || null;
      patchedState.dateOfBirth =
        'unverifiedDob' in passportPatch
          ? passportPatch.unverifiedDob
          : passport.unverifiedDob || null;
      patchedState.gender =
        'unverifiedGender' in passportPatch
          ? passportPatch.unverifiedGender
          : passport.unverifiedGender || null;
    } else {
      patchedState.artistName =
        'artistName' in personaPatch
          ? personaPatch.artistName
          : persona.artistName;
      patchedState.countryAlpha2 =
        'countryAlpha2' in personaPatch
          ? personaPatch.countryAlpha2
          : persona.countryAlpha2 || null;
      patchedState.dateOfBirth =
        'dateOfBirth' in personaPatch
          ? personaPatch.dateOfBirth
          : persona.dateOfBirth || null;
      patchedState.gender =
        'gender' in personaPatch ? personaPatch.gender : persona.gender || null;
    }

    setState(patchedState);
  }, [personaPatch, passportPatch, passport, persona]);

  const patchedVisibility = {
    ...personaAccess,
    ...personaVisibilityPatch,
  };

  const handleSave = () => {
    // Check if name has changed
    const hasNameChange = persona.isProfessionalProfile
      ? state.unverifiedFirstname !== passport.unverifiedFirstname ||
        state.unverifiedLastname !== passport.unverifiedLastname
      : state.artistName !== persona.artistName;

    if (hasNameChange) {
      setPendingSave(() => saveChanges);
      setShowNameChangeAlert(true);
      return;
    }

    saveChanges();
  };

  const saveChanges = () => {
    const personaPatchCopy = { ...personaPatch };
    Object.keys(personaPatch).forEach((key) => {
      if (personaPatch[key] === '') {
        delete personaPatchCopy[key]; // undeleted blank entries are cancelled
      } else if (personaPatch[key] === null && !persona[key]) {
        delete personaPatchCopy[key]; // remove no-op deletes
      } else if (personaPatch[key] === persona[key]) {
        delete personaPatchCopy[key]; // remove no-op updates
      }
    });
    if (persona.isProfessionalProfile) {
      let computedDisplayName = `${passport.unverifiedFirstname || ''} ${passport.unverifiedLastname || ''}`;
      if (state.alias) {
        if (state.aliasOverwritesName) {
          computedDisplayName = state.alias;
        } else {
          computedDisplayName = computedDisplayName + ` (${state.alias})`;
        }
      }
      if (computedDisplayName !== persona.artistName) {
        personaPatchCopy['artistName'] = computedDisplayName;
      }
    }
    if (Object.keys(personaPatchCopy).length >= 1) {
      updatePersona({ method: 'PATCH', payload: personaPatchCopy });
    }
    const personaVisibilityPatchCopy = { ...personaVisibilityPatch };
    Object.keys(personaVisibilityPatch).forEach((key) => {
      if (personaVisibilityPatch[key] === personaAccess[key]) {
        delete personaVisibilityPatchCopy[key];
      }
    });
    if (Object.keys(personaVisibilityPatchCopy).length >= 1) {
      updateAccess({
        method: 'PATCH',
        payload: personaVisibilityPatchCopy,
      });
    }
    if (persona.isProfessionalProfile) {
      const passportPatchCopy = { ...passportPatch };
      Object.keys(passportPatch).forEach((key) => {
        if (passportPatch[key] === '') {
          delete passportPatchCopy[key]; // undeleted blank entries are cancelled
        } else if (passportPatch[key] === null && !passport[key]) {
          delete passportPatchCopy[key]; // remove no-op deletes
        } else if (passportPatch[key] === passport[key]) {
          delete passportPatchCopy[key]; // remove no-op updates
        }
      });
      if (Object.keys(passportPatchCopy).length >= 1) {
        updatePassport({ method: 'PATCH', payload: passportPatchCopy });
      }
    }
    setPersonaPatch({});
    setPassportPatch({});
    setPersonaVisibilityPatch({});
    closeModal();
    setPendingSave(null);
    setShowNameChangeAlert(false);
  };

  const handleCancel = () => {
    setPersonaPatch({});
    setPassportPatch({});
    setPersonaVisibilityPatch({});
    closeModal();
  };

  const handleDelete = () => {
    closeModal();
    updatePassport({ method: 'DELETE_PERSONA', payload: persona.personaId });
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form submission
    handleSave();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent Enter key from submitting
    }
  };

  // Update the container animation to include a custom delay
  const containerAnimation = (delay = 0) => ({
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        delay: delay,
      },
    },
  });

  return (
    <>
      <Dialog open={isOpen} onOpenChange={(open) => !open && closeModal()}>
        <DialogContent className="max-w-screen sm:max-w-[600px] max-h-screen sm:max-h-[90vh] flex flex-col">
          <DialogHeader>
            <DialogTitle>Edit Profile</DialogTitle>
          </DialogHeader>

          <div className="flex-1 overflow-y-auto pr-8 -mr-8">
            <form onSubmit={handleSubmit}>
              <div
                className="flex flex-col gap-4 mb-[68px]"
                onKeyDown={handleKeyDown}
              >
                {/* Editor: Avatar */}
                <div className="w-48 self-center">
                  <ProfileImage
                    personaId={persona?.personaId}
                    alt={state.artistName}
                  />
                </div>
                <div className="flex flex-col gap-4">
                  {persona?.isProfessionalProfile ? (
                    <>
                      {/*** <PROFESSIONAL> ***/}
                      {/* Professional Profile Editor: Person Name */}

                      {/* Professional Profile Editor: First & Last Name with visibility toggle */}
                      <motion.div
                        className="flex flex-col bg-accent/5 p-4 rounded-lg"
                        initial="hidden"
                        animate="show"
                        variants={containerAnimation(0)}
                      >
                        <div className="flex flex-col gap-4">
                          {/* Header row with 3 columns */}
                          <div className="grid grid-cols-2 items-center gap-2">
                            <h2 className="mb-0">First name</h2>
                            <div className="flex flex-row justify-between items-center">
                              <h2 className="mb-0">Surname</h2>
                              <CPSwitch
                                animated
                                size="large"
                                icon
                                checked={!state.aliasOverwritesName}
                                onChange={(checked) => {
                                  if (!checked && !state.alias) {
                                    return;
                                  }
                                  setPersonaPatch((p) => ({
                                    ...p,
                                    aliasOverwritesName: !checked,
                                  }));
                                }}
                              />
                            </div>
                          </div>
                          {/* Input row with 2 columns */}
                          <div className="grid grid-cols-2 gap-2">
                            <Input
                              value={state.unverifiedFirstname}
                              onChange={(e) =>
                                setPassportPatch((p) => ({
                                  ...p,
                                  unverifiedFirstname: e.target.value,
                                }))
                              }
                              className="w-full"
                              placeholder="First Name"
                              data-testid={`first-name-${persona?.personaId}-edit`}
                              required
                              onKeyDown={handleKeyDown}
                            />
                            <Input
                              value={state.unverifiedLastname}
                              onChange={(e) =>
                                setPassportPatch((p) => ({
                                  ...p,
                                  unverifiedLastname: e.target.value,
                                }))
                              }
                              className="w-full"
                              placeholder="Surname"
                              data-testid={`last-name-${persona?.personaId}-edit`}
                              required
                              onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                      </motion.div>
                      {/* nickname */}
                      <motion.div
                        className="flex flex-col bg-accent/5 p-4 rounded-lg space-y-4"
                        initial="hidden"
                        animate="show"
                        variants={containerAnimation(0.05)}
                      >
                        <div className="flex flex-row justify-between items-center">
                          <h2
                            className="mb-0"
                            data-testid={`first-last-name-${persona?.personaId}-edit`}
                          >
                            Known as
                          </h2>
                          <CPDeleteButton
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              setPersonaPatch((p) => ({
                                ...p,
                                alias: null,
                                aliasOverwritesName: false,
                              }));
                            }}
                          />
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          <Input
                            className="w-full"
                            placeholder="Known as"
                            value={state.alias || ''}
                            onChange={(e) =>
                              setPersonaPatch((p) => ({
                                ...p,
                                alias: e.target.value,
                              }))
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </motion.div>
                      {/*** </PROFESSIONAL> ***/}
                    </>
                  ) : (
                    <>
                      {/*** <ARTISTIC> ***/}
                      {/* Artist Profile Editor: Name */}
                      <motion.div
                        className="flex flex-col bg-accent/5 p-4 rounded-lg space-y-4"
                        initial="hidden"
                        animate="show"
                        variants={containerAnimation(0.1)}
                      >
                        <h2 className="mb-0">Name</h2>
                        <Input
                          value={state.artistName}
                          onChange={(e) =>
                            setPersonaPatch((p) => ({
                              ...p,
                              artistName: e.target.value,
                            }))
                          }
                          placeholder="Name"
                          className={classes.inputField}
                          fullWidth
                          onKeyDown={handleKeyDown}
                        />
                      </motion.div>
                      {/* Artist Profile Editor: Type */}
                      <motion.div
                        className="flex flex-col bg-accent/5 p-4 rounded-lg space-y-4"
                        initial="hidden"
                        animate="show"
                        variants={containerAnimation(0.15)}
                      >
                        <h2 className="mb-0">Type</h2>
                        <FormControl>
                          <RadioGroup
                            value={state.personaType}
                            onChange={(e) =>
                              setPersonaPatch((p) => ({
                                ...p,
                                personaType: e.target.value,
                              }))
                            }
                            defaultValue="band"
                            aria-label="artistic-project-type"
                            name="artistic-project-type"
                            className={classes.types}
                            onKeyDown={handleKeyDown}
                          >
                            {projectTypes.map((project) => {
                              const BaseIcon = project.icon;
                              return (
                                <FormControlLabel
                                  className={
                                    state.personaType === project.slug
                                      ? classes.radioSelected
                                      : classes.radio
                                  }
                                  value={project.slug}
                                  control={
                                    <Radio
                                      icon={<BaseIcon />}
                                      checkedIcon={
                                        <BaseIcon hasGradient={true} />
                                      }
                                      disableRipple
                                      sx={{
                                        '&.MuiButtonBase-root:hover': {
                                          bgcolor: 'transparent',
                                        },
                                      }}
                                      className={
                                        state.personaType === project.slug
                                          ? classes.radioIconSelected
                                          : classes.radioIcon
                                      }
                                    />
                                  }
                                  label={project.name}
                                />
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      </motion.div>
                      {/*** </ARTISTIC> ***/}
                    </>
                  )}

                  {/*** <SHARED> ***/}
                  {/* Country */}
                  <motion.div
                    className="flex flex-col bg-accent/5 p-4 rounded-lg space-y-4"
                    initial="hidden"
                    animate="show"
                    variants={containerAnimation(0.2)}
                  >
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="mb-0">Country</h2>
                      <CPSwitch
                        animated
                        size="large"
                        icon
                        checked={patchedVisibility.countryAlpha2}
                        onChange={(checked) =>
                          setPersonaVisibilityPatch((p) => ({
                            ...p,
                            countryAlpha2: checked,
                          }))
                        }
                      />
                    </div>
                    <div
                      className="w-full flex flex-row items-center gap-2"
                      data-testid={`edit-${persona?.personaId}-country`}
                    >
                      <Popover open={open} onOpenChange={setOpen}>
                        <PopoverTrigger asChild>
                          <Button
                            variant="outline"
                            role="combobox"
                            className={cn(
                              'h-10 shadow-none w-full justify-between',
                              !state.countryAlpha2 && 'text-muted-foreground',
                            )}
                          >
                            {state.countryAlpha2
                              ? countries.getName(state.countryAlpha2, 'EN')
                              : 'Select country...'}
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </Button>
                        </PopoverTrigger>
                        <Portal>
                          <PopoverContent className="z-[1301]">
                            <Command className="z-[1301]" shouldFilter={true}>
                              <CommandInput
                                placeholder="Search country..."
                                className="h-9"
                              />
                              <CommandEmpty>No country found.</CommandEmpty>
                              <CommandGroup className="max-h-[200px] overflow-y-auto">
                                {Object.entries(countries.getNames('EN')).map(
                                  ([code, label]) => (
                                    <CommandItem
                                      key={code}
                                      value={label}
                                      onSelect={() => {
                                        if (persona?.isProfessionalProfile) {
                                          setPassportPatch((p) => ({
                                            ...p,
                                            unverifiedCountry: code,
                                          }));
                                        } else {
                                          setPersonaPatch((p) => ({
                                            ...p,
                                            countryAlpha2: code,
                                          }));
                                        }
                                        setOpen(false);
                                      }}
                                    >
                                      {label}
                                      {state.countryAlpha2 === code && (
                                        <Check className="ml-auto h-4 w-4" />
                                      )}
                                    </CommandItem>
                                  ),
                                )}
                              </CommandGroup>
                            </Command>
                          </PopoverContent>
                        </Portal>
                      </Popover>
                    </div>
                  </motion.div>

                  {/* City Name */}
                  <motion.div
                    className="flex flex-col bg-accent/5 p-4 rounded-lg space-y-4"
                    initial="hidden"
                    animate="show"
                    variants={containerAnimation(0.25)}
                  >
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="mb-0">City</h2>
                      <CPSwitch
                        animated
                        size="large"
                        icon
                        checked={patchedVisibility.city}
                        onChange={(checked) =>
                          setPersonaVisibilityPatch((p) => ({
                            ...p,
                            city: checked,
                          }))
                        }
                      />
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                      <Input
                        className="w-full"
                        placeholder="City"
                        value={state.city || ''}
                        onChange={(e) =>
                          setPersonaPatch((p) => ({
                            ...p,
                            city: e.target.value,
                          }))
                        }
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                  </motion.div>

                  {/* dateOfBirth: date of birth or project founding date*/}
                  <motion.div
                    className="flex flex-col bg-accent/5 p-4 rounded-lg space-y-4"
                    initial="hidden"
                    animate="show"
                    variants={containerAnimation(0.3)}
                  >
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="mb-0">
                        {persona?.isProfessionalProfile
                          ? 'Date of birth'
                          : 'Founding date'}
                      </h2>
                      <CPSwitch
                        animated
                        size="large"
                        icon
                        checked={patchedVisibility.dateOfBirth}
                        onChange={(checked) =>
                          setPersonaVisibilityPatch((p) => ({
                            ...p,
                            dateOfBirth: checked,
                          }))
                        }
                      />
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                      <Input
                        value={state.dateOfBirth || ''}
                        onChange={(e) => {
                          if (persona?.isProfessionalProfile) {
                            setPassportPatch((p) => ({
                              ...p,
                              unverifiedDob: e.target.value,
                            }));
                          } else {
                            setPersonaPatch((p) => ({
                              ...p,
                              dateOfBirth: e.target.value,
                            }));
                          }
                        }}
                        className="w-full"
                        type="date"
                        placeholder={
                          persona?.isProfessionalProfile
                            ? 'Date of birth'
                            : 'Founding date'
                        }
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                  </motion.div>

                  {/*** </SHARED> ***/}
                  {persona?.isProfessionalProfile && (
                    <>
                      <motion.div
                        className="flex flex-col bg-accent/5 p-4 rounded-lg space-y-4"
                        initial="hidden"
                        animate="show"
                        variants={containerAnimation(0.35)}
                      >
                        <div className="flex flex-row justify-between items-center">
                          <h2 className="mb-0">Gender</h2>
                          <CPSwitch
                            animated
                            size="large"
                            icon
                            checked={patchedVisibility.gender}
                            onChange={(checked) =>
                              setPersonaVisibilityPatch((p) => ({
                                ...p,
                                gender: checked,
                              }))
                            }
                          />
                        </div>
                        <div className="flex flex-row gap-2 items-center">
                          <Select
                            value={state.gender || ''}
                            onValueChange={(value) =>
                              setPassportPatch((p) => ({
                                ...p,
                                unverifiedGender: value,
                              }))
                            }
                          >
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Select gender" />
                            </SelectTrigger>
                            <Portal>
                              <SelectContent className="z-[1301]">
                                <SelectItem value="Male">Male</SelectItem>
                                <SelectItem value="Female">Female</SelectItem>
                                <SelectItem value="Non-Binary">
                                  Non-Binary
                                </SelectItem>
                                <SelectItem value="Genderfluid">
                                  Genderfluid
                                </SelectItem>
                                <SelectItem value="Agender">Agender</SelectItem>
                              </SelectContent>
                            </Portal>
                          </Select>
                        </div>
                      </motion.div>

                      <motion.div
                        className="flex flex-col bg-accent/5 p-4 rounded-lg space-y-4"
                        initial="hidden"
                        animate="show"
                        variants={containerAnimation(0.4)}
                      >
                        <div className="flex flex-row justify-between items-center">
                          <h2 className="mb-0">Pronouns</h2>
                          <CPSwitch
                            animated
                            size="large"
                            icon
                            checked={patchedVisibility.pronouns}
                            onChange={(checked) =>
                              setPersonaVisibilityPatch((p) => ({
                                ...p,
                                pronouns: checked,
                              }))
                            }
                          />
                        </div>
                        <div className="flex flex-row gap-2 items-center">
                          <Select
                            value={state.pronouns || ''}
                            onValueChange={(value) =>
                              setPersonaPatch((p) => ({
                                ...p,
                                pronouns: value,
                              }))
                            }
                          >
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Select pronouns" />
                            </SelectTrigger>
                            <Portal>
                              <SelectContent className="z-[1301]">
                                <SelectItem value="she/her">she/her</SelectItem>
                                <SelectItem value="he/him">he/him</SelectItem>
                                <SelectItem value="they/them">
                                  they/them
                                </SelectItem>
                                <SelectItem value="it/its">it/its</SelectItem>
                                <SelectItem value="co/cos">co/cos</SelectItem>
                                <SelectItem value="hy/hym">hy/hym</SelectItem>
                                <SelectItem value="xe/xem">xe/xem</SelectItem>
                                <SelectItem value="ze/hir">ze/hir</SelectItem>
                              </SelectContent>
                            </Portal>
                          </Select>
                        </div>
                      </motion.div>
                    </>
                  )}
                </div>
              </div>
            </form>
          </div>
          <DialogFooter className="backdrop-blur-lg bg-primary/20 p-4 rounded-t-none rounded-b-[20px] flex flex-row justify-end gap-2 fixed bottom-0 left-0 right-0 border-t">
            <div className="w-full flex flex-row justify-between">
              <div>
                {!persona?.isProfessionalProfile && (
                  <Button
                    animated
                    aria-label="close"
                    onClick={handleDelete}
                    variant="destructive"
                    size="lg"
                  >
                    <Trash2 className="h-4 w-4" />
                    Delete project
                  </Button>
                )}
              </div>
              <div className="flex flex-row gap-2">
                <Button
                  animated
                  variant="accent"
                  size="lg"
                  onClick={handleCancel}
                  data-testid={`cancel-button`}
                >
                  Cancel
                </Button>
                <Button
                  animated
                  size="lg"
                  type="submit"
                  data-testid="save-button"
                  onClick={handleSave}
                >
                  <Check className="h-4 w-4" />
                  Save
                </Button>
              </div>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <AlertDialog
        open={showNameChangeAlert}
        onOpenChange={setShowNameChangeAlert}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Confirm Name Change</AlertDialogTitle>
            <AlertDialogDescription>
              Changing your name means you're no longer validated as your new
              name. Any validated connections under your old name will be lost.
              You will need to re-validate with your peers.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setShowNameChangeAlert(false)}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction onClick={() => pendingSave?.()}>
              Continue and lose validations
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default SummaryEdit;
