import { IPersona, IPersonaPatch } from './types.ts';
import { personaKeys } from '../api.ts';

const v2ApiBase = process.env.REACT_APP_V2_API_BASE;

export async function getPersona(
  jwtToken: string,
  personaId: string,
): Promise<IPersona> {
  const url = `${v2ApiBase}/persona/${personaId}/`;
  const headers = {
    Accept: 'application/json',
  };
  if (jwtToken) {
    headers['Authorization'] = 'Bearer ' + jwtToken;
  }

  const response = await fetch(url, { headers: headers });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const responseJson = await response.json();
  const renamedPersona = Object.fromEntries(
    Object.entries(personaKeys).map(([key, fk]) => [key, responseJson[fk]]),
  );
  if (renamedPersona['customProjects']) {
    renamedPersona['customProjects'] = renamedPersona['customProjects'].map(
      (jsonProj: { [key: string]: string }) => ({
        uniqueKey: jsonProj['unique_key'],
        fieldName: jsonProj['field_name'],
        value: jsonProj['value'],
      }),
    );
  }
  if (renamedPersona['customIds']) {
    renamedPersona['customIds'] = renamedPersona['customIds'].map(
      (jsonId: { [key: string]: string }) => ({
        uniqueKey: jsonId['unique_key'],
        fieldName: jsonId['field_name'],
        value: jsonId['value'],
      }),
    );
  }
  if (renamedPersona['officialLinks']) {
    renamedPersona['officialLinks'] = renamedPersona['officialLinks'].map(
      (jsonLink: { [key: string]: string }) => ({
        uniqueKey: jsonLink['unique_key'],
        fieldName: jsonLink['field_name'],
        value: jsonLink['value'],
      }),
    );
  }
  if (renamedPersona['representatives']) {
    renamedPersona['representatives'] = renamedPersona['representatives'].map(
      (jsonRep: { [key: string]: string }) => ({
        uniqueKey: jsonRep['unique_key'],
        representativeType: jsonRep['representative_type'],
        repName: jsonRep['rep_name'],
        repPassportId: jsonRep['rep_passport_id'],
        contactName: jsonRep['contact_name'],
        contactEmail: jsonRep['contact_email'],
        contactNote: jsonRep['contact_note'],
        contactPhone: jsonRep['contact_phone'],
      }),
    );
  }
  return renamedPersona as IPersona;
}

export async function patchPersona(
  jwtToken: string,
  personaId: string,
  patch: IPersonaPatch,
): Promise<boolean> {
  const url = `${v2ApiBase}/persona/${personaId}/`;
  const headers = {
    Authorization: 'Bearer ' + jwtToken,
    'Content-Type': 'application/json',
  };
  if (!jwtToken) throw new Error('No token provided');
  const patchJson = Object.fromEntries(
    Object.entries(patch).map(([key, val]) => [personaKeys[key], val]),
  );
  if (patchJson['custom_projects']) {
    patchJson['custom_projects'] = patchJson['custom_projects'].map(
      (jsonProj: { [key: string]: string }) => ({
        unique_key: jsonProj['uniqueKey'],
        field_name: jsonProj['fieldName'],
        value: jsonProj['value'],
      }),
    );
  }
  if (patchJson['custom_ids']) {
    patchJson['custom_ids'] = patchJson['custom_ids'].map(
      (jsonId: { [key: string]: string }) => ({
        unique_key: jsonId['uniqueKey'],
        field_name: jsonId['fieldName'],
        value: jsonId['value'],
      }),
    );
  }
  if (patchJson['official_links']) {
    patchJson['official_links'] = patchJson['official_links'].map(
      (jsonLink: { [key: string]: string }) => ({
        unique_key: jsonLink['uniqueKey'],
        field_name: jsonLink['fieldName'],
        value: jsonLink['value'],
      }),
    );
  }
  if (patchJson['representatives']) {
    patchJson['representatives'] = patchJson['representatives'].map(
      (jsonRep: { [key: string]: string }) => ({
        unique_key: jsonRep['uniqueKey'],
        representative_type: jsonRep['representativeType'],
        rep_name: jsonRep['repName'],
        rep_passport_id: jsonRep['repPassportId'],
        contact_name: jsonRep['contactName'],
        contact_email: jsonRep['contactEmail'],
        contact_note: jsonRep['contactNote'],
        contact_phone: jsonRep['contactPhone'],
      }),
    );
  }
  const response = await fetch(url, {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify(patchJson),
  });
  return response.ok;
}
