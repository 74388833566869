import React from 'react';
import { Button } from 'src/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from 'src/components/ui/tooltip';
import { Edit } from 'lucide-react';

const CPEditButton = ({ title, onClick, ...other }) => {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            animated
            variant="accent"
            size="icon"
            className="h-8 w-8"
            onClick={onClick}
            {...other}
          >
            <Edit className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        {title && (
          <TooltipContent className="max-w-52" side="top">
            <h3 className="text-xs font-medium text-foreground">{title}</h3>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};

CPEditButton.defaultProps = {
  title: '',
};

export default CPEditButton;
