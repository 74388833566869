import React, { useState, useContext } from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { BadgeHelp, UserRoundPlus, Share2 } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import { motion } from 'framer-motion';
import { ScanQrCode, BadgeCheck, QrCode } from 'lucide-react';
import { GetValidated } from '../../../P2P/GetValidated';
import { ValidateSomeone } from '../../../P2P/ValidateSomeone';
import { InviteValidate } from '../../../P2P/InviteValidate';
import { VisibilityCheckDialog } from './VisibilityCheckDialog';
import { PersonaAccessContext } from '../../../v2api/PersonaAccessContext/PersonaAccessContext';
import { toast } from 'react-hot-toast';
import ConnectionManagement from '../../../P2P/Connections/ConnectionManagement';
import { P2PContext } from 'src/v2api/P2P/P2PContext';

export const Verify = () => {
  const [isValidateSomeoneOpen, setIsValidateSomeoneOpen] = useState(false);
  const [isGetValidatedOpen, setIsGetValidatedOpen] = useState(false);
  const [isInviteValidateOpen, setIsInviteValidateOpen] = useState(false);
  const [showVisibilityDialog, setShowVisibilityDialog] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  const { personaAccess, updateAccess } = useContext(PersonaAccessContext);
  const [isConnectionsOpen, setIsConnectionsOpen] = useState(false);
  const { p2pConnections } = useContext(P2PContext);

  const p2pDisabled = process.env.REACT_APP_P2P_DISABLED === 'true';

  const handleActionWithVisibilityCheck = (action) => {
    const isPublicPageVisible = personaAccess?.personaId || false;

    if (!isPublicPageVisible) {
      setShowVisibilityDialog(true);
      setPendingAction(() => action);
    } else {
      action();
    }
  };

  const handleValidateSomeone = () => setIsValidateSomeoneOpen(true);
  const handleInviteValidate = () => setIsInviteValidateOpen(true);

  const handleVisibilityConfirm = async () => {
    try {
      // Enable public page visibility
      await updateAccess({
        method: 'POST',
        payload: true,
      });

      // Close the visibility dialog first
      setShowVisibilityDialog(false);

      // Execute the pending action after enabling visibility
      if (pendingAction) {
        pendingAction();
      }
    } catch (error) {
      toast.error('Failed to enable public page visibility');
    }
    setPendingAction(null);
  };

  const handleVisibilityCancel = () => {
    setShowVisibilityDialog(false);
    setPendingAction(null);
  };

  return (
    <div className="card pb-4">
      <div className="flex justify-between">
        <h3 className="mb-4">Peer Connections</h3>
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger className="bg-transparent h-5 w-5">
              <BadgeHelp className="h-4 hover:opacity-100 text-muted-foreground opacity-50" />
            </TooltipTrigger>
            <TooltipContent className="w-52" side="top">
              <h3 className="text-xs font-medium text-foreground">
                Why should I verify?
              </h3>
              <p className="text-xs text-muted-foreground mt-2">
                Verifications are needed to create a safer community by ensuring
                trusted accounts are connected to each other and trustworthy.
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      {p2pDisabled ? (
        <div>Coming Soon!</div>
      ) : (
        <div className="grid grid-cols-2 gap-x-2 gap-y-1.5">
          <motion.div
            whileHover={{ scale: 1.005 }}
            whileTap={{ scale: 0.995 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            className="w-full"
          >
            <Button
              animated
              variant="accent"
              className="relative text-[#761e5c] hover:text-foreground/80 h-20 md:h-[104px] w-full flex flex-col
              bg-gradient-to-br from-[#ff00b3] to-[#ff51cb]/0 via-[#ff51cb]/80
              hover:bg-[#ff51cb] shadow-[inset_0px_0px_58px_8px_rgba(255,_255,_255,_0.9)] cursor-pointer rounded-2xl"
              onClick={() =>
                handleActionWithVisibilityCheck(handleValidateSomeone)
              }
            >
              <ScanQrCode className="h-7 w-7 absolute top-4 left-4" />
              <span className="absolute bottom-4 left-4 right-4 font-semibold truncate text-left">
                {window.innerWidth < 400 ? 'Validate' : 'Validate existing'}
              </span>
            </Button>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.005 }}
            whileTap={{ scale: 0.995 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            className="w-full"
          >
            <Button
              animated
              variant="accent"
              className="relative text-[#30716a] hover:text-foreground/80 h-20 md:h-[104px] w-full flex-col
              bg-gradient-to-bl from-[#1ae0cd] to-[#86e7de]/0 via-[#86e7de]/80
              hover:bg-[#71cbc2] shadow-[inset_0px_0px_58px_8px_rgba(255,_255,_255,_0.9)] cursor-pointer rounded-2xl"
              onClick={() => setIsGetValidatedOpen(true)}
            >
              <QrCode className="h-7 w-7 absolute top-4 right-4" />
              <span className="absolute bottom-4 right-4 font-semibold text-right">
                Get validated
              </span>
              <span className="absolute bottom-3 left-4 font-extrabold text-5xl text-[#e2f8f6] bg-blend-soft-light font-rounded">
                {/* Ammount connect here */}
              </span>
            </Button>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.005 }}
            whileTap={{ scale: 0.995 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            className="w-full"
          >
            <Button
              animated
              variant="accent"
              className="relative text-[#446936] hover:text-foreground/80 h-20 md:h-[104px] w-full flex flex-col
              bg-gradient-to-tr from-[#98e054] to-[#a3f05c]/0 via-[#a3f05c]/80
              hover:bg-[#73d642] shadow-[inset_0px_0px_58px_8px_rgba(255,_255,_255,_0.9)] cursor-pointer rounded-2xl"
              onClick={() =>
                handleActionWithVisibilityCheck(handleInviteValidate)
              }
            >
              <UserRoundPlus className="h-7 w-7 absolute top-4 left-4" />
              <span className="absolute bottom-4 left-4 font-semibold">
                Invite new
              </span>
            </Button>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.005 }}
            whileTap={{ scale: 0.995 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            className="w-full"
          >
            <Button
              animated
              variant="accent"
              className="relative text-[#4f20b3] hover:text-foreground/80 h-20 md:h-[104px] w-full flex-col
              bg-gradient-to-tl from-[#8b54ff] to-[#aa82ff]/0 via-[#aa82ff]/80
              hover:bg-[#aa82ff] shadow-[inset_0px_0px_58px_8px_rgba(255,_255,_255,_0.9)] cursor-pointer rounded-2xl"
              onClick={() => setIsConnectionsOpen(true)}
            >
              <Share2 className="h-7 w-7 absolute top-4 right-4" />
              <span className="absolute bottom-4 right-4 font-semibold text-right">
                My connections
              </span>
              <span
                className="absolute bottom-3 left-4 font-extrabold text-5xl font-rounded
                bg-gradient-to-b from-[#aa82ff]/20 to-[#aa82ff]/40
                inline-block text-transparent bg-clip-text blur-[2px]"
              >
                {p2pConnections?.length || 0}
              </span>
            </Button>
          </motion.div>
        </div>
      )}

      <ValidateSomeone
        isOpen={isValidateSomeoneOpen}
        onClose={() => setIsValidateSomeoneOpen(false)}
      />

      <GetValidated
        isOpen={isGetValidatedOpen}
        onOpenChange={setIsGetValidatedOpen}
      />

      <InviteValidate
        open={isInviteValidateOpen}
        onOpenChange={setIsInviteValidateOpen}
      />

      <VisibilityCheckDialog
        isOpen={showVisibilityDialog}
        onClose={handleVisibilityCancel}
        onConfirm={handleVisibilityConfirm}
      />

      <ConnectionManagement
        isOpen={isConnectionsOpen}
        onOpenChange={setIsConnectionsOpen}
      />
    </div>
  );
};

export default Verify;
