import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AuthedPagesWrapper from './AuthedPagesWrapper'; // @deprecate
import GetInvolved from '../pages/GetInvolved';
import { PassportProvider } from '../v2api/PassportContext/PassportContext.tsx';
import CMSPrivateView from '../pages/CMS';
import IntegrationsPage from '../pages/integrations';
import { useAuth } from 'react-oidc-context';
import NotFoundPage from '../pages/pageNotFound';
import { useLocation } from 'react-router';

const SignedInRoutes = () => {
  const auth = useAuth();
  const location = useLocation();
  if (auth.isLoading) {
    return 'Loading';
  }

  if (!auth.isAuthenticated) {
    return <Navigate to="/welcome" state={{ from: location }} replace />;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <PassportProvider auth={auth}>
        <AuthedPagesWrapper>
          <Routes>
            <Route path="/" element={<CMSPrivateView />} />
            <Route exact path="/integrations" element={<IntegrationsPage />} />
            <Route exact path="/get_involved" element={<GetInvolved />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </AuthedPagesWrapper>
      </PassportProvider>
    </Suspense>
  );
};

export default SignedInRoutes;
