import PropTypes from 'prop-types';
import { useState, useContext } from 'react';
import CMSTitleInfo from '../Title';
import { Button } from 'src/components/ui/button';
import { Plus, BriefcaseBusiness } from 'lucide-react';
import { ReadOnlyProject } from './ReadOnlyProject';
import EditProjects from './EditProjects';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';
import { PersonaAccessContext } from '../../../v2api/PersonaAccessContext/PersonaAccessContext';

const Projects = ({ isCMS = true }) => {
  const { persona } = useContext(PersonaContext);
  const personaAccessContext = useContext(PersonaAccessContext);
  const { personaAccess, projAttrAccess, updateAccess } =
    isCMS && personaAccessContext
      ? personaAccessContext
      : { personaAccess: null, projAttrAccess: null, updateAccess: () => {} };
  const [editorOpen, setEditorOpen] = useState(false);

  const projectList = persona?.customProjects || [];
  const hasProjects = projectList.some((el) => el.fieldName);

  if (!isCMS && !hasProjects) {
    return null;
  }

  return (
    <div>
      {isCMS && (
        <EditProjects
          isOpen={editorOpen}
          closeModal={() => setEditorOpen(false)}
        />
      )}
      {hasProjects ? (
        <div className="card">
          <CMSTitleInfo
            isCMS={isCMS}
            title="Projects"
            isNotEmpty={true}
            message="Edit Projects"
            onClick={() => setEditorOpen(true)}
            isVisible={personaAccess?.customProjects}
            setVisible={(v) =>
              updateAccess({
                method: 'PATCH',
                payload: { customProjects: v },
              })
            }
            isVisibilityDisabled={!personaAccess?.personaId}
          />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
            {projectList.map((proj) => (
              <ReadOnlyProject
                key={proj.uniqueKey}
                customAttr={proj}
                isVisible={projAttrAccess?.[proj.uniqueKey]}
                isCMS={isCMS}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="card-inactive">
          <div className="flex flex-col gap-4 items-center justify-center h-36">
            <div className="flex gap-2 opacity-20">
              <BriefcaseBusiness />
            </div>
            <p>If you have any custom projects you can list them here</p>
            <Button
              size="lg"
              data-testid="add-data-button"
              onClick={() => setEditorOpen(true)}
            >
              <Plus className="w-4 h-4" />
              Project
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

Projects.propTypes = {
  isCMS: PropTypes.bool,
};

export default Projects;
