import React from 'react';
import { Trash2 } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';

const CPDeleteButton = ({ title = '', onClick, ...props }) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            animated
            variant="accent"
            size="md"
            onClick={onClick}
            aria-label="Delete"
            className="h-8 w-8"
            {...props}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        {title && <TooltipContent>{title}</TooltipContent>}
      </Tooltip>
    </TooltipProvider>
  );
};

export default CPDeleteButton;
