import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogFooter } from 'src/components/ui/dialog';
import { Input } from 'src/components/ui/input';
import { Button } from 'src/components/ui/button';
import { UserSettingsContext } from 'src/v2api/UserSettingsContext/UserSettingsContext';
import { PersonaContext } from 'src/v2api/PersonaContext/PersonaContext';
import { PassportContext } from 'src/v2api/PassportContext/PassportContext';
import CPSwitch from '../CPSwitch';
import { Switch } from 'src/components/ui/switch.tsx';

interface FirstSigninModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const FirstSigninModal: React.FC<FirstSigninModalProps> = ({
  open,
  onOpenChange,
}) => {
  const { updateUserSettings } = useContext(UserSettingsContext);
  const { persona, updatePersona } = useContext(PersonaContext);
  const { passport } = useContext(PassportContext);
  const [alias, setAlias] = useState<string | null>(persona?.alias || null);
  const [aliasOverwrites, setAliasOverwrites] = useState<boolean>(
    persona?.aliasOverwritesName || false,
  );
  const [artistName, setArtistName] = useState<string>('');

  useEffect(() => {
    if (alias && aliasOverwrites) {
      return setArtistName(alias);
    } else if (alias) {
      return setArtistName(
        `${passport.unverifiedFirstname} ${passport.unverifiedLastname} (${alias})`,
      );
    } else {
      return setArtistName(
        `${passport.unverifiedFirstname} ${passport.unverifiedLastname}`,
      );
    }
  }, [
    alias,
    aliasOverwrites,
    passport.unverifiedFirstname,
    passport.unverifiedLastname,
  ]);

  const submitAlias = () => {
    updatePersona({
      method: 'PATCH',
      payload: {
        alias: alias,
        aliasOverwritesName: aliasOverwrites,
        artistName: artistName,
      },
    });
    updateUserSettings({ method: 'PATCH', payload: { dismissedAlias: true } });
    onOpenChange(false);
  };

  const handleClose = () => {
    // Will dismiss for this session, but open again unless they explicitly dismiss
    onOpenChange(false);
  };

  const handleDismiss = () => {
    updateUserSettings({ method: 'PATCH', payload: { dismissedAlias: true } });
    onOpenChange(false);
  };

  const handleAliasChange = (a) => {
    if (!a) {
      setAliasOverwrites(false);
    }
    setAlias(a);
  };

  if (!persona.isProfessionalProfile) return null;

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="max-w-screen sm:max-w-[600px] h-screen sm:h-auto max-h-screen sm:max-h-[90vh] flex flex-col justify-start sm:justify-center">
        <h2>Your display name: {artistName}</h2>
        <div>
          <>
            <p className="text-sm text-muted-foreground mb-4">
              Is there a name you would rather be known as?
            </p>
            <div className="space-y-4 bg-accent p-3 rounded-lg">
              <Input
                value={alias || ''}
                onChange={(e) => handleAliasChange(e.target.value)}
              />
              <div className="flex flex-row justify-between">
                <p className="mt-1">Keep real name visible?</p>
                <CPSwitch
                  icon
                  animated
                  size="large"
                  checked={!aliasOverwrites}
                  onCheckedChange={(o) =>
                    o || alias ? setAliasOverwrites(!o) : undefined
                  }
                />
              </div>
            </div>
            <div className="h-[68px]"></div>
            <DialogFooter className="backdrop-blur-lg bg-primary/20 p-4 rounded-t-none rounded-b-[20px] flex flex-row justify-end gap-2 absolute bottom-0 left-0 right-0">
              <Button
                animated
                size="lg"
                variant="accent"
                data-testid="cancel-button"
                onClick={handleDismiss}
              >
                Dismiss
              </Button>
              {alias && (
                <Button
                  animated
                  size="lg"
                  onClick={submitAlias}
                  data-testid="save-button"
                >
                  Save
                </Button>
              )}
            </DialogFooter>
          </>
        </div>
      </DialogContent>
    </Dialog>
  );
};
