import React from 'react';
import { makeStyles } from '@mui/styles';
import styles from './styles';
import CPVisibleIcon from '../../CPVisibleIcon';
import { Box } from '@mui/system';
import CMSTitleInfo from '../../Title';

const useStyles = makeStyles(styles);

const InfoField = ({
  Icon,
  title,
  values,
  isVisible,
  onEdit,
  isCMS = false,
}) => {
  if (typeof values === 'string') {
    values = values ? [values] : [];
  }
  const classes = useStyles();

  const hasContent = Array.isArray(values) && values.length > 0;

  return (
    <div className="card h-full">
      <div className="flex items-center content-center justify-end gap-2">
        <CMSTitleInfo
          title={title}
          message={`Edit ${title}`}
          onClick={onEdit}
          isVisible={isVisible}
          setVisible={undefined}
          isNotEmpty={hasContent}
          isCMS={isCMS}
        />
        <div className="absolute m-10 flex items-center pb-2">
          {isCMS && <CPVisibleIcon isVisible={isVisible} />}
        </div>
      </div>
      <Box className={classes.items}>
        {values?.length ? (
          <div className={classes.items}>
            {values.map((el, i) => (
              <div key={i} className={classes.itemFull}>
                <div className="text-xs px-3 py-2 inline-flex items-center justify-center rounded-lg border border-solid border-[#5de9d5]/30 bg-[#5de9d5]/20 ">
                  {el}
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </Box>
    </div>
  );
};

export default InfoField;
