import React, { useContext, useEffect, useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { Plus, Trash2, HelpCircle } from 'lucide-react';
import CMSModal from '../../CMSModal';
import CPSwitch from '../../CPSwitch';
import { PersonaContext } from '../../../../v2api/PersonaContext/PersonaContext';
import { PersonaAccessContext } from '../../../../v2api/PersonaAccessContext/PersonaAccessContext';
import { areAttrsUnchanged } from '../../../../v2api/extraTypes';
import { EditCustomID } from '../EditCustomID';
import { v4 as uuidv4 } from 'uuid';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';

const EditIDs = ({ isOpen, closeModal }) => {
  const { persona, updatePersona } = useContext(PersonaContext);
  const { personaAccess, idAttrAccess, updateAccess } =
    useContext(PersonaAccessContext);
  const [personaPatch, setPersonaPatch] = useState({});
  const [personaVisibilityPatch, setPersonaVisibilityPatch] = useState({});
  const [attrVisibilityPatch, setAttrVisibilityPatch] = useState({});
  const [localCustomIds, setLocalCustomIds] = useState(
    persona?.customIds || [],
  );
  const [state, setState] = useState({});

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    if (!persona) return;
    const personaKeys = ['unverifiedIpn', 'unverifiedIpi', 'unverifiedIsni'];
    const patchedState = Object.fromEntries(
      personaKeys.map((key) => [
        key,
        key in personaPatch ? personaPatch[key] : persona[key] || null,
      ]),
    );
    setState(patchedState);
  }, [personaPatch, persona]);

  useEffect(() => {
    if (isOpen) {
      setLocalCustomIds(persona?.customIds || []);
    } else {
      setLocalCustomIds([]);
    }
  }, [isOpen, persona?.customIds]);

  const handleSave = useCallback(() => {
    const personaPatchCopy = { ...personaPatch };
    Object.keys(personaPatch).forEach((key) => {
      if (personaPatch[key] === null && !persona[key]) {
        delete personaPatchCopy[key];
      }
    });

    const personaVisibilityPatchCopy = { ...personaVisibilityPatch };
    Object.keys(personaVisibilityPatch).forEach((key) => {
      if (personaVisibilityPatch[key] === personaAccess[key]) {
        delete personaVisibilityPatchCopy[key];
      }
    });

    if (Object.keys(personaVisibilityPatchCopy).length >= 1) {
      updateAccess({
        method: 'PATCH',
        payload: personaVisibilityPatchCopy,
      });
    }

    if (Object.keys(attrVisibilityPatch).length > 0) {
      updateAccess({
        method: 'ATTR_PATCH',
        attrsType: 'customIds',
        payload: attrVisibilityPatch,
      });
    }

    if (!areAttrsUnchanged(persona.customIds || [], localCustomIds)) {
      personaPatchCopy['customIds'] = localCustomIds;
    }

    if (Object.keys(personaPatchCopy).length >= 1) {
      updatePersona({ method: 'PATCH', payload: personaPatchCopy });
    }

    setAttrVisibilityPatch({});
    setPersonaVisibilityPatch({});
    setPersonaPatch({});
    closeModal();
  }, [
    personaPatch,
    personaVisibilityPatch,
    attrVisibilityPatch,
    localCustomIds,
    persona,
    updateAccess,
    updatePersona,
    closeModal,
  ]);

  const handleCancel = useCallback(() => {
    setPersonaPatch({});
    setPersonaVisibilityPatch({});
    setAttrVisibilityPatch({});
    closeModal();
  }, [closeModal]);

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setLocalCustomIds((oldIdObjs) => {
        const oldIndex = oldIdObjs.findIndex(
          (obj) => obj.uniqueKey === active.id,
        );
        const newIndex = oldIdObjs.findIndex(
          (obj) => obj.uniqueKey === over.id,
        );
        return arrayMove(oldIdObjs, oldIndex, newIndex);
      });
    }
  }, []);

  const appendNewCustomId = useCallback(() => {
    setLocalCustomIds((prev) => [
      ...prev,
      { uniqueKey: uuidv4(), fieldName: '', value: '' },
    ]);
  }, []);

  const patchedPersonaVisibility = {
    ...personaAccess,
    ...personaVisibilityPatch,
  };
  const patchedAttrVisibility = { ...idAttrAccess, ...attrVisibilityPatch };
  const customIDKeys = localCustomIds.map((idObj) => idObj.uniqueKey);
  const isHidden = !personaAccess?.customIds;

  const standardIdTitles = {
    unverifiedIsni: 'ISNI',
    unverifiedIpn: 'IPN',
    unverifiedIpi: 'IPI',
  };

  return (
    <CMSModal onSave={handleSave} onClose={handleCancel} open={isOpen}>
      <div className="space-y-4">
        {Object.entries(standardIdTitles).map(([key, label]) => (
          <motion.div key={key} layout className="bg-accent/5 p-4 rounded-lg">
            <motion.div layout className="flex justify-between items-center">
              <h2 className="capitalize mb-0">{label}</h2>
              <AnimatePresence mode="wait">
                {state[key] !== null ? (
                  <motion.div
                    key="editing"
                    initial={{ opacity: 0, x: 10 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -10 }}
                    className="flex items-center gap-2"
                    transition={{ duration: 0.1 }}
                  >
                    <CPSwitch
                      icon
                      animated
                      size="large"
                      checked={patchedPersonaVisibility[key]}
                      onCheckedChange={(checked) =>
                        setPersonaVisibilityPatch((p) => ({
                          ...p,
                          [key]: checked,
                        }))
                      }
                      disabled={isHidden}
                    />
                    <Button
                      animated
                      variant="accent"
                      size="icon"
                      className="h-8 w-8"
                      onClick={() =>
                        setPersonaPatch((p) => ({ ...p, [key]: null }))
                      }
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  </motion.div>
                ) : (
                  <motion.div
                    key="add"
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 10 }}
                    transition={{ duration: 0.1 }}
                  >
                    <Button
                      animated
                      variant="accent"
                      className="h-8 px-3"
                      onClick={() =>
                        setPersonaPatch((p) => ({ ...p, [key]: '' }))
                      }
                    >
                      <Plus className="h-4 w-4" />
                      <span>Add</span>
                    </Button>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>

            {state[key] !== null && (
              <motion.div
                layout
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.1 }}
              >
                <Input
                  type="text"
                  value={state[key] || ''}
                  onChange={(e) =>
                    setPersonaPatch((p) => ({ ...p, [key]: e.target.value }))
                  }
                  placeholder={`Enter your ${label} ID`}
                  className="w-full mt-4"
                />
              </motion.div>
            )}
          </motion.div>
        ))}

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={customIDKeys}
            strategy={verticalListSortingStrategy}
          >
            {localCustomIds.map((idObj) => (
              <motion.div
                key={idObj.uniqueKey}
                layout
                className="bg-accent/5 p-4 rounded-lg"
              >
                <EditCustomID
                  id={idObj.uniqueKey}
                  uniqueKey={idObj.uniqueKey}
                  label={idObj.fieldName}
                  setLabel={(val) =>
                    setLocalCustomIds((oldCidList) =>
                      oldCidList.map((oldObj) =>
                        oldObj.uniqueKey === idObj.uniqueKey
                          ? { ...oldObj, fieldName: val }
                          : oldObj,
                      ),
                    )
                  }
                  value={idObj.value}
                  setValue={(val) =>
                    setLocalCustomIds((oldCidList) =>
                      oldCidList.map((oldObj) =>
                        oldObj.uniqueKey === idObj.uniqueKey
                          ? { ...oldObj, value: val }
                          : oldObj,
                      ),
                    )
                  }
                  isVisible={patchedAttrVisibility[idObj.uniqueKey]}
                  setVisible={(v) =>
                    setAttrVisibilityPatch((ps) => ({
                      ...ps,
                      [idObj.uniqueKey]: v,
                    }))
                  }
                  onDelete={() =>
                    setLocalCustomIds((oldCidList) =>
                      oldCidList.filter(
                        (oldObj) => oldObj.uniqueKey !== idObj.uniqueKey,
                      ),
                    )
                  }
                  areIdsHidden={isHidden}
                />
              </motion.div>
            ))}
          </SortableContext>
        </DndContext>
        <div className="flex flex-row bg-accent/5 p-4 rounded-lg justify-between">
          <h2 className="mb-0">Custom ID</h2>
          <Button
            variant="accent"
            className="h-8 px-3"
            onClick={appendNewCustomId}
          >
            <Plus className="h-4 w-4" />
            <span>Add</span>
          </Button>
        </div>
      </div>
    </CMSModal>
  );
};

export default EditIDs;
