import { useState, useContext, useCallback } from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import CPSwitch from '../CPSwitch';
import SettingsModal from '../SettingsModal';
import countries from 'i18n-iso-countries';
import {
  BadgeHelp,
  Settings,
  Link as LinkIcon,
  TriangleAlert,
} from 'lucide-react';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';
import { PassportContext } from '../../../v2api/PassportContext/PassportContext';
import { PersonaAccessContext } from '../../../v2api/PersonaAccessContext/PersonaAccessContext';
import { Button } from 'src/components/ui/button';
import PublicPageVisualization from '../PublicPageVisualization';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'src/components/ui/dialog';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const CMSPublicProfile = ({ allowOnMap }) => {
  const { persona } = useContext(PersonaContext);
  const { personaAccess, updateAccess } = useContext(PersonaAccessContext);
  const { passport } = useContext(PassportContext);
  const [open, setOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [customiserOpen, setCustomiserOpen] = useState(false);

  const handleCancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleConfirmVisibilityChange = useCallback(() => {
    updateAccess({
      method: 'POST',
      payload: false,
    });
    setOpen(false);
  }, [updateAccess, setOpen]);

  const isVisible = personaAccess?.personaId || false;

  return (
    <TooltipProvider delayDuration={0}>
      <div className="card">
        <div className="flex justify-between">
          <h3 className="mb-4">Public Page</h3>
          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger className="bg-transparent h-5 w-5">
                <BadgeHelp className="h-4 hover:opacity-100 text-muted-foreground opacity-50" />
              </TooltipTrigger>
              <TooltipContent className="w-52" side="top">
                <h3 className="text-xs font-medium text-foreground">
                  What is a public page?
                </h3>
                <p className="text-xs text-muted-foreground mt-2">
                  Public pages are individual links to your profile including
                  all the information you have decided to share publicly. You
                  can share the link with others so they can access all your
                  data in one place.
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="space-y-2">
          <div className="flex items-center bg-accent rounded-md py-2 px-4 justify-between">
            <p>Visibility</p>
            <div className="flex items-center gap-2">
              {isVisible && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      animated
                      variant="accent"
                      size="icon"
                      className="h-8 w-8"
                      onClick={() => setCustomiserOpen(true)}
                    >
                      <Settings className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p className="text-xs font-medium">Customise public page</p>
                  </TooltipContent>
                </Tooltip>
              )}

              {isVisible && (
                <a
                  href={`/page/${persona?.personaId}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        animated
                        variant="accent"
                        size="icon"
                        className="h-8 w-8"
                      >
                        <LinkIcon className="h-4 w-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p className="text-xs font-medium">Page URL</p>
                    </TooltipContent>
                  </Tooltip>
                </a>
              )}

              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="flex items-center h-8">
                    <CPSwitch
                      animated
                      size="large"
                      icon
                      checked={isVisible}
                      onClick={() => {
                        if (isVisible) {
                          setOpen(true);
                        } else {
                          updateAccess({
                            method: 'POST',
                            payload: true,
                          });
                        }
                      }}
                    />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <p className="text-xs font-medium">Toggle page visibility</p>
                </TooltipContent>
              </Tooltip>
            </div>
          </div>

          <PublicPageVisualization personaId={persona?.personaId} />
        </div>
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Confirm Visibility Change</DialogTitle>
              <DialogDescription>
                Any validations made of other users will be lost. This change is
                irreversible and will impact all the users you've connected to.
                We strongly recommend toggling your individual privacy
                preferences instead of disabling your entire public page.
              </DialogDescription>
            </DialogHeader>
            <div className="h-[68px]"></div>
            <DialogFooter className="backdrop-blur-lg bg-primary/20 p-4 rounded-t-none rounded-b-[20px] flex flex-row gap-2 absolute bottom-0 left-0 right-0">
              <div className="flex flex-row justify-between w-full">
                <Button
                  animated
                  size="lg"
                  variant="destructive"
                  onClick={handleConfirmVisibilityChange}
                >
                  <TriangleAlert className="h-4 w-4" />
                  Disable and lose validations
                </Button>
                <Button
                  animated
                  size="lg"
                  variant="accent"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        <Dialog open={customiserOpen} onOpenChange={setCustomiserOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Coming Soon</DialogTitle>
              <DialogDescription>
                We're working hard on letting you have full customisation over
                your public page. Thanks for your patience.
              </DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      </div>
    </TooltipProvider>
  );
};

export default CMSPublicProfile;
