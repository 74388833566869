import React from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { ChevronDown } from 'lucide-react';
import { Button } from 'src/components/ui/button';

const PublicPageVisualization = ({ personaId }) => {
  const [period, setPeriod] = React.useState('thisWeek');

  const getPeriodLabel = () => {
    const labels = {
      thisWeek: 'Week',
      thisMonth: 'Month',
      thisYear: 'Year',
    };
    return labels[period];
  };

  return (
    <div className="flex items-center bg-accent rounded-lg py-2 px-4 justify-between">
      <p>Analytics</p>

      <div className="flex items-center space-x-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="accent"
              className="flex items-center px-2 h-8 min-w-[72px]"
            >
              <span className="text-sm">{getPeriodLabel()}</span>
              <ChevronDown className="ml-1 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent className="w-[200px]">
            <DropdownMenuItem onClick={() => setPeriod('thisWeek')}>
              This Week
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setPeriod('thisMonth')}>
              This Month
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setPeriod('thisYear')}>
              This Year
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <div className="bg-primary px-2 min-w-12 h-8 rgb-background90 rounded-full flex items-center justify-center">
          <h3>{0}</h3>
        </div>
      </div>
    </div>
  );
};

export default PublicPageVisualization;
