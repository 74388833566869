import { Plus } from 'lucide-react';
import QuestionMark from '../../../icons/cpQuestion';
import { Button } from 'src/components/ui/button';

const CPAddButton = ({
  children,
  hasGradient,
  help,
  size,
  className,
  ...rest
}) => {
  return (
    <Button animated {...rest} variant="accent" className="gap-1">
      <Plus className="h-4 w-4" hasGradient={hasGradient} />
      <div>
        <p>{children}</p>
        {help ? <QuestionMark fontSize="13px" /> : null}
      </div>
    </Button>
  );
};

export default CPAddButton;
