import { objectURL } from './types.ts';

const v2ApiBase = process.env.REACT_APP_V2_API_BASE;

export async function getPersonaImage(
  jwtToken: string,
  personaId: string,
  size: string | undefined = undefined,
): Promise<objectURL | null> {
  const headers = jwtToken
    ? { Authorization: `Bearer ${jwtToken}` }
    : undefined;
  const sizes = size ? [size] : ['large', 'medium', 'small'];

  for (const size of sizes) {
    try {
      const response = await fetch(
        `${v2ApiBase}/media/persona/${personaId}/${size}.webp`,
        { headers },
      );

      if (response.ok) {
        const imgBlob = await response.blob();
        return URL.createObjectURL(imgBlob) as objectURL;
      }
    } catch (err) {
      console.warn(`Failed to fetch ${size} image:`, err);
    }
  }
  return null;
}

export async function deletePersonaImage(
  jwtToken: string,
  personaId: string,
): Promise<boolean> {
  if (!jwtToken) {
    console.warn(`No token provided`);
    return false;
  }

  try {
    const headers = { Authorization: `Bearer ${jwtToken}` };
    const response = await fetch(
      `${v2ApiBase}/media/persona/${personaId}/picture/`,
      { method: 'DELETE', headers },
    );
    return response.ok;
  } catch (err) {
    console.warn(err);
    return false;
  }
}

export async function uploadPersonaImage(
  jwtToken: string,
  personaId: string,
  file: File,
): Promise<boolean> {
  if (!jwtToken) {
    console.warn(`No token provided`);
    return false;
  }
  const formData = new FormData();
  formData.append('file', file);
  const headers = {
    Authorization: `Bearer ${jwtToken}`,
  };
  const response = await fetch(
    `${process.env.REACT_APP_V2_API_BASE}/media/persona/${personaId}/picture/`,
    {
      method: 'POST',
      headers: headers,
      body: formData,
    },
  ).catch((err) => {
    console.warn(err);
    return { ok: false };
  });

  return response.ok;
}
