import * as React from 'react';
import '../styles/PublicPage.css';
import { useParams } from 'react-router-dom';
import SignedInHeader from 'src/components/SignedInHeader';
import { PersonaProvider } from 'src/v2api/PersonaContext/PersonaContext.tsx';
import PersonaPublicView from './PersonaPublicView';

export default function PublicPageBeta() {
  const { personaId } = useParams();
  return (
    <PersonaProvider auth={null} activePersonaId={personaId}>
      <SignedInHeader />
      <div className="h-[108px] sm:h-[140px]" />
      <main className="flex flex-col items-center justify-center flex-grow mx-0 sm:mx-10">
        <div className="items-center w-full max-w-screen-lg">
          <PersonaPublicView />
        </div>
      </main>
    </PersonaProvider>
  );
}
