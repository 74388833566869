import React, { useState, useContext } from 'react';
import SkillsIcon from '../../../icons/atomsSkills';
import LanguagesIcon from '../../../icons/languages';
import RolesIcon from '../../../icons/atomsRoles';
import FeaturesIcon from '../../../icons/atomsFeatures';
import InstrumentsIcon from '../../../icons/instruments';
import ToolsAndGearIcon from '../../../icons/toolsAndGear';
import InspirationsIcon from '../../../icons/inspirations';
import FavoriteArtistsIcon from '../../../icons/favoriteArtist';
import EndorsementIcon from '../../../icons/endorsement';
import GenresIcon from '../../../icons/genres';
import SupportedCauses from '../../../icons/supportedCauses';
import QuotesIcon from '../../../icons/quotes';
import InfoField from './InfoField';
import { Button } from 'src/components/ui/button';
import { Guitar, Plus, Hand, KeyboardMusic } from 'lucide-react';
import PersonalInfoEdit from './PersonalInfoEdit';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';
import { PersonaAccessContext } from '../../../v2api/PersonaAccessContext/PersonaAccessContext';

const PersonalInfo = ({ isCMS }) => {
  const { persona } = useContext(PersonaContext);
  const personaAccessContext = useContext(PersonaAccessContext);
  const { personaAccess } =
    isCMS && personaAccessContext
      ? personaAccessContext
      : { personaAccess: null };
  const [editorOpen, setEditorOpen] = useState(false);

  const hasContent =
    persona?.features?.length ||
    persona?.toolsUsed?.length ||
    persona?.toolsOwned?.length ||
    persona?.instrumentsPlayed?.length ||
    persona?.instrumentsOwned?.length ||
    persona?.musicRoles?.length ||
    persona?.otherSkills?.length ||
    persona?.genres?.length ||
    persona?.favouriteMusicMakers?.length ||
    persona?.endorsements?.length ||
    persona?.interestsInspiration?.length ||
    persona?.charitiesSupported?.length ||
    persona?.favouriteQuote?.length ||
    persona?.spokenLanguages?.length;
  const isEmpty = !hasContent;

  if (isEmpty && !isCMS) {
    return null;
  }

  return (
    <>
      {isCMS && (
        <PersonalInfoEdit
          isOpen={editorOpen}
          closeModal={() => setEditorOpen(false)}
        />
      )}
      {/* Profile InfoField Display */}
      <div>
        {isEmpty ? (
          <div
            className="card-inactive"
            data-testid={`empty-${persona?.personaId}-display`}
          >
            <div className="flex flex-col gap-4 items-center justify-center h-36">
              <div className="flex gap-2 opacity-20">
                <Hand />
                <KeyboardMusic />
                <Guitar />
              </div>
              <p>
                Add Skills, Instruments, Tools, Gear and other info that define
                your uniqueness
              </p>
              <Button
                size="lg"
                data-testid="add-data-button"
                onClick={() => {
                  setEditorOpen(true);
                }}
              >
                <Plus className="w-4 h-4" />
                Info
              </Button>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-4">
            {persona.features?.length ? (
              <div className="col-span-2">
                <InfoField
                  Icon={FeaturesIcon}
                  title="Keywords"
                  values={persona.features}
                  isVisible={personaAccess?.features}
                  onEdit={() => setEditorOpen(true)}
                  isCMS={isCMS}
                />
              </div>
            ) : null}
            {persona.musicRoles?.length ? (
              <div className="col-span-2">
                <InfoField
                  Icon={RolesIcon}
                  title="Roles"
                  values={persona.musicRoles}
                  isVisible={personaAccess?.musicRoles}
                  onEdit={() => setEditorOpen(true)}
                  isCMS={isCMS}
                />
              </div>
            ) : null}
            {persona.otherSkills?.length ? (
              <div className="col-span-2">
                <InfoField
                  Icon={SkillsIcon}
                  title="Skills"
                  values={persona.otherSkills}
                  isVisible={personaAccess?.otherSkills}
                  onEdit={() => setEditorOpen(true)}
                  isCMS={isCMS}
                />
              </div>
            ) : null}
            {persona.instrumentsPlayed?.length ? (
              <div className="col-span-2 md:col-span-1">
                <InfoField
                  Icon={InstrumentsIcon}
                  title="Instruments Used"
                  values={persona.instrumentsPlayed}
                  isVisible={personaAccess?.instrumentsPlayed}
                  onEdit={() => setEditorOpen(true)}
                  isCMS={isCMS}
                />
              </div>
            ) : null}
            {persona.instrumentsOwned?.length ? (
              <div className="col-span-2 md:col-span-1">
                <InfoField
                  Icon={InstrumentsIcon}
                  title="Instruments Owned"
                  values={persona.instrumentsOwned}
                  isVisible={personaAccess?.instrumentsOwned}
                  onEdit={() => setEditorOpen(true)}
                  isCMS={isCMS}
                />
              </div>
            ) : null}
            {persona.toolsUsed?.length ? (
              <div className="col-span-2 md:col-span-1">
                <InfoField
                  Icon={ToolsAndGearIcon}
                  title="Tools and Gear Used"
                  values={persona.toolsUsed}
                  isVisible={personaAccess?.toolsUsed}
                  onEdit={() => setEditorOpen(true)}
                  isCMS={isCMS}
                />
              </div>
            ) : null}
            {persona.toolsOwned?.length ? (
              <div className="col-span-2 md:col-span-1">
                <InfoField
                  Icon={ToolsAndGearIcon}
                  title="Tools and Gear Owned"
                  values={persona.toolsOwned}
                  isVisible={personaAccess?.toolsOwned}
                  onEdit={() => setEditorOpen(true)}
                  isCMS={isCMS}
                />
              </div>
            ) : null}
            {persona?.favouriteMusicMakers?.length ? (
              <div className="col-span-2 md:col-span-1">
                <InfoField
                  Icon={FavoriteArtistsIcon}
                  title="Favorite Artists"
                  values={persona.favouriteMusicMakers}
                  isVisible={personaAccess?.favouriteMusicMakers}
                  onEdit={() => setEditorOpen(true)}
                  isCMS={isCMS}
                />
              </div>
            ) : null}
            {persona.genres?.length ? (
              <div className="col-span-2 md:col-span-1">
                <InfoField
                  Icon={GenresIcon}
                  title="Genres"
                  values={persona.genres}
                  isVisible={personaAccess?.genres}
                  onEdit={() => setEditorOpen(true)}
                  isCMS={isCMS}
                />
              </div>
            ) : null}
            {persona.spokenLanguages?.length ? (
              <div className="col-span-2 md:col-span-1">
                <InfoField
                  Icon={LanguagesIcon}
                  title="Spoken Languages"
                  values={persona.spokenLanguages}
                  isVisible={personaAccess?.spokenLanguages}
                  onEdit={() => setEditorOpen(true)}
                  isCMS={isCMS}
                />
              </div>
            ) : null}
            {persona.endorsements?.length ? (
              <div className="col-span-2 md:col-span-1">
                <InfoField
                  Icon={EndorsementIcon}
                  title="Endorsements"
                  values={persona.endorsements}
                  isVisible={personaAccess?.endorsements}
                  onEdit={() => setEditorOpen(true)}
                  isCMS={isCMS}
                />
              </div>
            ) : null}
            {persona.interestsInspiration?.length ? (
              <div className="col-span-2 md:col-span-1">
                <InfoField
                  Icon={InspirationsIcon}
                  title="Interests / Inspirations"
                  values={persona.interestsInspiration}
                  isVisible={personaAccess?.interestsInspiration}
                  onEdit={() => setEditorOpen(true)}
                  isCMS={isCMS}
                />
              </div>
            ) : null}
            {persona.charitiesSupported?.length ? (
              <div className="col-span-2 md:col-span-1">
                <InfoField
                  Icon={SupportedCauses}
                  title="Supported Causes"
                  values={persona.charitiesSupported}
                  isVisible={personaAccess?.charitiesSupported}
                  onEdit={() => setEditorOpen(true)}
                  isCMS={isCMS}
                />
              </div>
            ) : null}
            {persona.favouriteQuote?.length ? (
              <div className="col-span-2 md:col-span-1">
                <InfoField
                  Icon={QuotesIcon}
                  title="Quote"
                  values={persona.favouriteQuote}
                  isVisible={personaAccess?.favouriteQuote}
                  onEdit={() => setEditorOpen(true)}
                  isCMS={isCMS}
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default PersonalInfo;
