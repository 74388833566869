import React from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { Toaster } from 'react-hot-toast';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { AuthProvider } from 'react-oidc-context';
import GradientBackground from './components/GradientBackground';
import BaseThemeProvider from './themes/main';
import './styles/App.css';
import './global.css';
// FIXME switch colouring
// import 'antd/lib/switch/style/index.css'; // Just import switch CSS
import SignedInRoutes from './components/SignedInRoutes';
import SearchPortal from './search-portal/SearchPortal';
import PublicPage from './public-pages/components/PublicPage';
import UrlShortener from './public-pages/components/UrlShortener';
import TrackingCode from './fathomtracking';
import WelcomePage from './pages/welcome';
import OldSiteWelcome from './pages/creativePassportWelcome';
import SigninCallback from './pages/SigninCallback';
import { initGA, logPageView } from './utils/analytics';
import { useEffect } from 'react';
import PublicPageBeta from './beta-public-pages';

export const userManager = new UserManager({
  authority: process.env.REACT_APP_KEYCLOAK_REALM_URI,
  client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  redirect_uri: `${window.location.origin}/signin-callback`,
  post_logout_redirect_uri: window.location.origin,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  monitorSession: true, // this allows cross tab login/logout detection
});

export const onSigninCallback = (user) => {
  console.warn('onSigninCallback');
  window.history.replaceState({}, document.title, window.location.pathname);
};

// Create a separate component for routes to use useLocation
const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page views whenever the location changes
    logPageView(location.pathname + location.search);
  }, [location]);

  return (
    <Routes>
      <Route exact path="/signin-callback" element={<SigninCallback />} />
      <Route path="/welcome" element={<WelcomePage />} />
      <Route path="/creative-passport" element={<OldSiteWelcome />} />
      <Route exact path="/search" element={<SearchPortal />} />
      <Route path="/page/:passportId/:personaId" element={<UrlShortener />} />
      <Route path="/page/:personaId" element={<PublicPageBeta />} />
      <Route path="*" element={<SignedInRoutes />} />
    </Routes>
  );
};

const App = () => {
  useEffect(() => {
    // Initialize GA4 when the app loads
    if (process.env.REACT_APP_GA4_MEASUREMENT_ID) {
      initGA(process.env.REACT_APP_GA4_MEASUREMENT_ID);
    } else {
      console.warn('GA4 measurement ID not found in environment variables');
    }
  }, []);

  return (
    <BrowserRouter>
      <BaseThemeProvider>
        <GradientBackground />
        <CssBaseline />
        <TrackingCode />
        <AuthProvider
          userManager={userManager}
          onSigninCallback={onSigninCallback}
        >
          <Toaster position="top-center" />
          <AppRoutes />
        </AuthProvider>
      </BaseThemeProvider>
    </BrowserRouter>
  );
};

export default App;
