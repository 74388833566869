import React from 'react';
import { motion } from 'framer-motion';
import { Input } from 'src/components/ui/input';
import { Trash2, GripVertical } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import CPSwitch from '../../CPSwitch';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export const EditCustomID = ({
  uniqueKey,
  label,
  setLabel,
  value,
  setValue,
  isVisible,
  setVisible,
  onDelete,
  areIdsHidden,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: uniqueKey });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className="space-y-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="space-y-3">
            <h2 className="mb-0">Custom ID</h2>
          </div>
        </div>

        <div className="flex items-center gap-2">
          <div
            {...attributes}
            {...listeners}
            className={`cursor-${isDragging ? 'grabbing' : 'grab'} text-muted-foreground hover:text-foreground`}
          >
            <GripVertical className="h-4 w-4" />
          </div>
          <CPSwitch
            icon
            animated
            size="large"
            checked={isVisible}
            onCheckedChange={setVisible}
            disabled={areIdsHidden}
          />
          <Button
            variant="accent"
            size="icon"
            className="h-8 w-8"
            onClick={onDelete}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </div>
      <Input
        type="text"
        value={label}
        onChange={(e) => setLabel(e.target.value)}
        placeholder="ID Label"
        onKeyDown={(e) => e.stopPropagation()} // fixes a dnd-kit bug
        className="w-full font-medium"
      />
      <Input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="ID Code"
        onKeyDown={(e) => e.stopPropagation()} // fixes a dnd-kit bug
        className="w-full"
      />
    </div>
  );
};
