import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import EditLink from './EditOfficialLink';
import { useState } from 'react';
import { cn } from 'src/lib/utils';

const DraggableLinkItem = ({
  id,
  linkData,
  handleChange,
  handleDelete,
  isVisible,
  setVisible,
  areLinksHidden,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const [isDragging, setIsDragging] = useState(false);

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="relative"
    >
      <div
        onMouseDown={() => setIsDragging(true)}
        onMouseUp={() => setIsDragging(false)}
        className={cn(
          'bg-accent/5 rounded-lg p-4 mb-2 transition-colors',
          isDragging ? 'cursor-grabbing' : 'cursor-grab',
        )}
      >
        <EditLink
          fieldName={linkData.fieldName}
          value={linkData.value}
          handleChange={(patch) =>
            handleChange({ ...patch, uniqueKey: linkData.uniqueKey })
          }
          onDelete={() => handleDelete(linkData.uniqueKey)}
          isVisible={isVisible}
          setVisible={setVisible}
          areLinksHidden={areLinksHidden}
        />
      </div>
    </div>
  );
};

export default DraggableLinkItem;
