import React, { useContext } from 'react';
import { useAuth } from 'react-oidc-context';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  DropdownMenuLabel,
} from 'src/components/ui/dropdown-menu';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import {
  LogOut,
  Users,
  BadgeHelp,
  Music,
  Plus,
  ChevronDownIcon,
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { PassportContext } from '../../v2api/PassportContext/PassportContext';
import { motion } from 'motion/react';
import { RadioButtonChecked } from '../RadioButtonChecked.tsx';
import { RadioButtonUnchecked } from '../RadioButtonUnchecked.tsx';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { PersonaImageContext } from '../../v2api/PersonaImageContext/PersonaImageContext';

interface PersonaDropdownProps {
  userInitials?: string;
}

export function PersonaDropdown({ userInitials = 'ID' }: PersonaDropdownProps) {
  const auth = useAuth();
  const passportContext = useContext(PassportContext);
  const personaImageContext = useContext(PersonaImageContext);

  const passport = passportContext?.passport;
  const activePersonaId = passportContext?.activePersonaId;
  const switchActivePersona = passportContext?.switchActivePersona;
  const updatePassport = passportContext?.updatePassport;

  if (!passportContext) {
    return null;
  }

  const handleLogout = () => {
    auth.signoutRedirect({
      post_logout_redirect_uri: `${window.location.origin}/welcome`,
    });
  };

  const handleCreateNewPersona = () => {
    if (updatePassport) {
      updatePassport({
        method: 'ADD_PERSONA',
        payload: {
          personaId: null,
          artistName: 'New Project',
          personaType: 'solo',
          isProfessionalProfile: false,
        },
      });
    }
  };

  const getInitials = (name: string) => {
    return name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const getProfileImageUrl = (personaId: string): string => {
    return `${process.env.REACT_APP_V2_API_BASE}/media/persona/${personaId}/small.webp`;
  };

  return (
    <DropdownMenu modal={false}>
      <motion.div
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
      >
        <DropdownMenuTrigger asChild>
          <div className="active:brightness-70 hover:brightness-90 hover:cursor-pointer filter brightness-100 flex flex-row items-center gap-x-[10px]">
            <ChevronDownIcon
              className="hidden md:flex w-5 h-5 text-primary-foreground"
              strokeWidth={2.5}
            />
            <Avatar className="h-[44px] w-[44px] select-none">
              <AvatarImage
                src={
                  activePersonaId
                    ? getProfileImageUrl(activePersonaId)
                    : undefined
                }
                alt="Profile"
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.style.display = 'none';
                }}
              />
              <AvatarFallback>
                {getInitials(
                  passport?.personaSummaries?.find(
                    (p) => p.personaId === activePersonaId,
                  )?.artistName || userInitials,
                )}
              </AvatarFallback>
            </Avatar>
          </div>
        </DropdownMenuTrigger>
      </motion.div>
      <DropdownMenuContent
        className="w-[206px] font-seminormal mt-3"
        align="start"
      >
        <ScrollArea className="flex max-h-[160px] flex-col overflow-y-auto">
          {passport?.personaSummaries?.map((persona) => (
            <DropdownMenuItem
              key={persona.personaId}
              onClick={() => switchActivePersona(persona.personaId)}
              className={`flex items-center justify-between ${
                activePersonaId === persona.personaId
                  ? 'bg-accent/5 hover:bg-accent'
                  : ''
              }`}
            >
              <div className="flex items-center gap-2">
                <Avatar className="h-4 w-4">
                  <AvatarImage
                    src={getProfileImageUrl(persona.personaId)}
                    alt={persona.artistName}
                    className="h-4 w-4"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.style.display = 'none';
                    }}
                  />
                  <AvatarFallback className="text-[9px]">
                    {getInitials(persona.artistName).slice(0, 1)}
                  </AvatarFallback>
                </Avatar>
                <span>
                  {persona.artistName.length > 15
                    ? persona.artistName.slice(0, 15) + '...'
                    : persona.artistName}
                </span>
              </div>
              {activePersonaId === persona.personaId ? (
                <RadioButtonChecked className="text-bg-primary opacity-20" />
              ) : (
                <RadioButtonUnchecked className="text-bg-primary opacity-20" />
              )}
            </DropdownMenuItem>
          ))}
        </ScrollArea>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={handleCreateNewPersona}
          className="flex items-center gap-2"
        >
          <Plus className="h-4 w-4" />
          <span>New persona</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem>
          <Link
            to="https://song.auracles.io/"
            className="flex items-center gap-2 w-full text-foreground"
          >
            <Music className="h-4 w-4" />
            <span>Song Auracles</span>
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <Link
            to="https://discord.gg/ZJABRw4yYe"
            className="flex items-center gap-2 w-full text-foreground"
          >
            <Users className="h-4 w-4" />
            <span>Community</span>
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <Link
            to="/support"
            className="flex items-center gap-2 w-full text-foreground"
          >
            <BadgeHelp className="h-4 w-4" />
            <span>Support</span>
          </Link>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={handleLogout}>
          <div className="flex items-center gap-2 w-full">
            <LogOut className="h-4 w-4" />
            <span>Log out</span>
          </div>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
