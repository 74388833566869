'use client';

import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';
import { Eye, EyeOff } from 'lucide-react';

import { cn } from 'src/lib/utils';
import { motion } from 'framer-motion';

interface SwitchProps
  extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
  size?: 'default' | 'large';
  icon?: boolean;
  animated?: boolean;
}

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(
  (
    { className, size = 'default', icon = false, animated = false, ...props },
    ref,
  ) => {
    const SwitchContent = (
      <SwitchPrimitives.Root
        className={cn(
          'peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input',
          size === 'default' ? 'h-6 w-11' : 'h-8 w-12',
          className,
        )}
        {...props}
        ref={ref}
      >
        <SwitchPrimitives.Thumb
          className={cn(
            'pointer-events-none rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-1 flex items-center justify-center',
            size === 'default' ? 'h-5 w-5' : 'h-6 w-6',
            icon && props.checked ? 'text-primary' : 'text-foreground/20',
          )}
        >
          {icon &&
            (props.checked ? (
              <Eye className="h-4 w-4" />
            ) : (
              <EyeOff className="h-4 w-4" />
            ))}
        </SwitchPrimitives.Thumb>
      </SwitchPrimitives.Root>
    );

    if (animated) {
      return (
        <motion.div
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          transition={{ type: 'spring', stiffness: 400, damping: 17 }}
        >
          {SwitchContent}
        </motion.div>
      );
    }

    return SwitchContent;
  },
);

Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
