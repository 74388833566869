import { IUserSettings, IUserSettingsPatch } from './types.ts';

const v2ApiBase = process.env.REACT_APP_V2_API_BASE;

export async function getUserSettings(jwtToken: any): Promise<IUserSettings> {
  const url = `${v2ApiBase}/user_settings/`;
  const headers = {
    Authorization: 'Bearer ' + jwtToken,
    Accept: 'application/json',
  };
  if (!jwtToken) throw new Error('No token provided');
  const response = await fetch(url, { method: 'GET', headers: headers });
  if (!response.ok) {
    throw new Error(JSON.stringify(await response.json()));
  }
  const responseJson = await response.json();
  return {
    dismissedAlias: responseJson['dismissed_alias'],
    dismissedMailingList: responseJson['dismissed_mailing_list'],
    mailingListEmail: responseJson['mailing_list_email'],
  };
}

export async function patchUserSettings(
  jwtToken: any,
  patch: IUserSettingsPatch,
): Promise<boolean> {
  const url = `${v2ApiBase}/user_settings/`;
  const headers = {
    Authorization: 'Bearer ' + jwtToken,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (!jwtToken) throw new Error('No token provided');
  const patchJson = Object.fromEntries(
    Object.entries(patch).map(([key, val]) => [
      {
        dismissedAlias: 'dismissed_alias',
        dismissedMailingList: 'dismissed_mailing_list',
        mailingListEmail: 'mailing_list_email',
      }[key],
      val,
    ]),
  );
  const response = await fetch(url, {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify(patchJson),
  });
  return response.ok;
}
