import React, { useRef, useEffect, useState } from 'react';
import { MapPin } from 'lucide-react';
import { Highlight } from './Highlight';
import ProfileImage from 'src/components/ProfileImage';
import { motion } from 'framer-motion';
import Tilt from 'react-parallax-tilt';
import { Badge } from 'src/components/ui/badge';
import PropTypes from 'prop-types';
import { getPersonaImage } from '../v2api/PersonaImageContext/http';

const SearchCard = ({ personaResult }) => {
  const tiltRef = useRef(null);
  const lastAngleRef = useRef(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [imageObjectURL, setImageObjectURL] = useState(null);

  useEffect(() => {
    getPersonaImage(null, personaResult.persona_id, 'small')
      .then((img) => setImageObjectURL(img))
      .catch(() => null);
  }, [personaResult.persona_id]);

  useEffect(() => {
    const handleResize = () => {
      const isNowMobile = window.innerWidth < 640;
      if (isNowMobile !== isMobile) {
        setIsMobile(isNowMobile);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  useEffect(() => {
    const handleTilt = (e) => {
      if (tiltRef.current) {
        const tiltElement = tiltRef.current;
        const rect = tiltElement.getBoundingClientRect();

        if (
          e.clientX >= rect.left &&
          e.clientX <= rect.right &&
          e.clientY >= rect.top &&
          e.clientY <= rect.bottom
        ) {
          const x = e.clientX - rect.left;
          const y = e.clientY - rect.top;
          const centerX = rect.width / 2;
          const centerY = rect.height / 2;
          const sensitivity = 0.2;
          const tiltX = (y - centerY) / centerY;
          const tiltY = (x - centerX) / centerX;

          const angle =
            parseInt(tiltY * sensitivity * 90, 10) +
            parseInt(tiltX * sensitivity * 90, 10);
          lastAngleRef.current = angle;
          tiltElement.style.setProperty('--angle', `${angle}deg`);
        }
      }
    };

    document.addEventListener('mousemove', handleTilt);
    return () => {
      document.removeEventListener('mousemove', handleTilt);
    };
  }, []);

  const publicProfileLink = `/page/${personaResult.persona_id}`;

  const modalVariants = {
    hidden: {
      // x: 150,
      // y: -150,
      filter: 'blur(10px)',
      opacity: 0,
      scale: 0.7,
      rotateX: -90,
    },
    visible: {
      x: 0,
      y: 0,
      filter: 'blur(0px)',
      opacity: 1,
      scale: 1,
      rotateX: 0,
      transition: {
        type: 'spring',
        stiffness: 100,
        damping: 12,
      },
    },
  };

  return (
    <div className="w-full h-full flex flex-col">
      <a target="_blank" rel="noopener noreferrer" href={publicProfileLink}>
        <div
          className="w-full flex flex-col  h-[280px] sm:h-[350px] md:h-[250px] lg:h-[280px] items-center justify-between inset-0 z-40"
          id="holographic"
        >
          <motion.div
            className=" w-full h-full outline-none focus:outline-none ring-0 flex-grow flex items-center justify-center"
            initial="hidden"
            animate="visible"
            exit="hidden"
            whileHover={{ scale: 1.0 }}
            whileTap={{ scale: 0.98 }}
            transition={{
              opacity: { delay: 0, ease: 'easeInOut', duration: 0.5 },
              scale: { type: 'spring', stiffness: 400, damping: 17 },
            }}
            viewport={{ amount: 0 }}
            variants={modalVariants}
          >
            <Tilt
              className="w-full h-full card rounded-[20px] shadow-lg bg-secondary p-0 overflow-hidden"
              tiltMaxAngleX={15}
              tiltMaxAngleY={15}
              perspective={1000}
              scale={1.05}
              transitionSpeed={1000}
              tiltEnable={!isMobile}
            >
              {personaResult.persona_id && (
                <>
                  <img
                    className="pointer-events-none -z-10 opacity-50 absolute w-full h-full object-cover"
                    src={`${process.env.REACT_APP_V2_API_BASE}/media/persona/${personaResult.persona_id}/background.webp`}
                    alt=""
                    onError={(e) => {
                      console.error('Error loading background image:', e);
                      e.target.style.display = 'none';
                    }}
                  />
                </>
              )}
              <div>
                <div
                  ref={tiltRef}
                  className="holographic-card absolute inset-0 w-full h-full z-50 opacity-50"
                  style={{ '--angle': '0deg' }}
                />
                <p className="flex justify-end w-full opacity-30 font-mono text-purple-500 font-black pointer-events-none z-20 mix-blend-color-dodge absolute top-0 p-6">
                  AURACLE ID
                </p>
                <img
                  className="pointer-events-none z-20 mix-blend-color-dodge absolute w-44 top-0 left-0 p-6"
                  src="https://i.imgur.com/2looaHu.png"
                  alt=""
                />

                <img
                  className="pointer-events-none z-10 mix-blend-color-burn opacity-70 absolute w-full"
                  src="https://i.imgur.com/kCLeTh7.jpeg"
                  alt=""
                />
                {/* Stamp
                  <img
                    className="pointer-events-none mix-blend-soft-light opacity-40 absolute w-40 bottom-0 right-0 pb-14 pr-4"
                    src="https://i.imgur.com/R3Uzgip.png"
                  />
                  */}
                <div className="m-8 holographic-card-content">
                  <div className="flex items-center space-x-4 mb-4">
                    <div className="relative h-28 w-28 p-1 bg-gradient-to-r from-teal-300 via-blue-400 to-pink-500 rounded-full">
                      <div className="flex items-center justify-center h-full aspect-square rounded-full">
                        <div className="h-full w-full">
                          <ProfileImage
                            alt={`${personaResult.artist_name}'s profile picture`}
                            disableUpload
                            isPublicPage
                            imgObjectURLOverride={imageObjectURL}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <h1 className="text-xl font-bold font-rounded mb-2 text-foreground">
                        {personaResult.artist_name}
                      </h1>
                      <div className="space-x-1 mb-3.5">
                        {personaResult.personaSummary?.music_roles?.length >
                          0 &&
                          personaResult.personaSummary.music_roles
                            .slice(0, 3)
                            .map((role, index) => (
                              <Badge key={index} variant="accent">
                                {role}
                              </Badge>
                            ))}
                      </div>
                      <div className="flex items-center opacity-80">
                        {(personaResult.city || personaResult.country) && (
                          <div className="flex items-center gap-1.5 text-sm capitalize text-gray-900 mt-6">
                            <MapPin className="h-4 w-4" />
                            {[personaResult.city, personaResult.country]
                              .filter(Boolean)
                              .join(', ')}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="p-4 bg-muted/30 rounded-md mb-4">
                    <p className="text-xs text-foreground/80 font-mono">
                      {personaResult?.biography
                        ? personaResult.biography.length > 250
                          ? personaResult.biography.slice(0, 250) + '…'
                          : personaResult.biography
                        : 'No bio available'}
                    </p>
                  </div>
                </div>
              </div>
            </Tilt>
          </motion.div>
        </div>
      </a>
      <div className="flex-1 py-0 mt-4">
        {personaResult.highlights?.length > 0 && (
          <Highlight highlights={personaResult.highlights} />
        )}
      </div>
    </div>
  );
};

SearchCard.propTypes = {
  personaResult: PropTypes.shape({
    passport_id: PropTypes.string.isRequired,
    persona_id: PropTypes.string.isRequired,
    artist_name: PropTypes.string.isRequired,
    profile_image_url: PropTypes.string,
    biographyShort: PropTypes.string,
    biographyLong: PropTypes.string,
    biographyHeadline: PropTypes.string,
    personaSummary: PropTypes.shape({
      music_roles: PropTypes.arrayOf(PropTypes.string),
    }),
    city: PropTypes.string,
    country: PropTypes.string,
    highlights: PropTypes.array,
  }).isRequired,
};

export default SearchCard;
