import React from 'react';

export const RadioButtonChecked = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
    <path
      d="M7.00065 10.333C7.92287 10.333 8.70898 10.008 9.35898 9.35801C10.009 8.70801 10.334 7.9219 10.334 6.99967C10.334 6.07745 10.009 5.29134 9.35898 4.64134C8.70898 3.99134 7.92287 3.66634 7.00065 3.66634C6.07843 3.66634 5.29232 3.99134 4.64232 4.64134C3.99232 5.29134 3.66732 6.07745 3.66732 6.99967C3.66732 7.9219 3.99232 8.70801 4.64232 9.35801C5.29232 10.008 6.07843 10.333 7.00065 10.333ZM7.00065 13.6663C6.07843 13.6663 5.21176 13.4913 4.40065 13.1413C3.58954 12.7913 2.88398 12.3163 2.28398 11.7163C1.68398 11.1163 1.20898 10.4108 0.858984 9.59968C0.508984 8.78856 0.333984 7.9219 0.333984 6.99967C0.333984 6.07745 0.508984 5.21079 0.858984 4.39967C1.20898 3.58856 1.68398 2.88301 2.28398 2.28301C2.88398 1.68301 3.58954 1.20801 4.40065 0.858008C5.21176 0.508008 6.07843 0.333008 7.00065 0.333008C7.92287 0.333008 8.78954 0.508008 9.60065 0.858008C10.4118 1.20801 11.1173 1.68301 11.7173 2.28301C12.3173 2.88301 12.7923 3.58856 13.1423 4.39967C13.4923 5.21079 13.6673 6.07745 13.6673 6.99967C13.6673 7.9219 13.4923 8.78856 13.1423 9.59968C12.7923 10.4108 12.3173 11.1163 11.7173 11.7163C11.1173 12.3163 10.4118 12.7913 9.60065 13.1413C8.78954 13.4913 7.92287 13.6663 7.00065 13.6663ZM7.00065 12.333C8.48954 12.333 9.75065 11.8163 10.784 10.783C11.8173 9.74967 12.334 8.48856 12.334 6.99967C12.334 5.51079 11.8173 4.24967 10.784 3.21634C9.75065 2.18301 8.48954 1.66634 7.00065 1.66634C5.51176 1.66634 4.25065 2.18301 3.21732 3.21634C2.18398 4.24967 1.66732 5.51079 1.66732 6.99967C1.66732 8.48856 2.18398 9.74967 3.21732 10.783C4.25065 11.8163 5.51176 12.333 7.00065 12.333Z"
      fill="currentColor"
    />
  </svg>
);
