import React from 'react';

export const displayNames = {
  alias: 'Alias',
  biography: 'Biography',
  country: 'Country',
  city: 'City',
  endorsements: 'Endorsement',
  custom_projects: 'Current Projects',
  genres: 'Genres',
  features: 'Keywords',
  instruments_played: 'Instruments Played',
  music_roles: 'Music Roles',
  tools_used: 'Tools Used',
  charities_supported: 'Supported Causes',
  favourite_music_makers: 'Favourite Artists',
  instruments_owned: 'Instruments owned',
  interests_inspiration: 'Instruments inspiration',
  other_skills: 'Other skills',
  spoken_languages: 'Languages Spoken',
  tools_owned: 'Tools Owned',
};

export function Highlight({ highlights }) {
  // TODO: more than just hits/highlighting. If match on name, don't own name in other fields, show e.g. headline bio
  return highlights.slice(0, 3).map((highlight) => (
    <div key={highlight.field_name} className="text-xs">
      <p className="font-bold text-foreground/50">
        {displayNames[highlight.field_name]}
      </p>
      {highlight.highlights.map((h) => (
        <p
          className="text-foreground/30"
          dangerouslySetInnerHTML={{ __html: h }}
        />
      ))}
    </div>
  ));
}
