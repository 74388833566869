import {
  InviteData,
  Messages,
  PendingValidation,
  UserConnections,
  UserValidations,
} from './types';

const v2ApiBase = process.env.REACT_APP_V2_API_BASE;

export const fetchValidationCode = async (
  accessToken: string,
): Promise<string> => {
  const response = await fetch(`${v2ApiBase}/p2p/validations/code/`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    credentials: 'include',
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};

export const createConnection = async (
  accessToken: string,
  validationCode: string,
): Promise<PendingValidation> => {
  const response = await fetch(`${v2ApiBase}/p2p/connections/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ validation_code: validationCode }),
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(
      errorData.detail || `Connection failed with status: ${response.status}`,
    );
  }

  return response.json();
};

export const getPendingAsyncValidation = async (
  accessToken: string,
  validationCode: string,
): Promise<PendingValidation> => {
  const response = await fetch(
    `${v2ApiBase}/p2p/async/validations/${validationCode}/`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch pending validation: ${response.status}`);
  }

  return response.json();
};

export const validateConnection = async (
  accessToken: string,
  connectData: PendingValidation,
  isAync: boolean = false,
): Promise<void> => {
  const response = await fetch(
    `${v2ApiBase}/p2p/${isAync ? 'async/' : ''}validations/`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(connectData),
    },
  );

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(errorData.detail || `status: ${response.status}`);
  }
};

export const getConnections = async (
  accessToken: string,
): Promise<UserConnections> => {
  const response = await fetch(`${v2ApiBase}/p2p/connections/`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch connections: ${response.status}`);
  }

  return response.json();
};

export const deleteConnection = async (
  accessToken: string,
  connectionId: number,
): Promise<void> => {
  const response = await fetch(
    `${v2ApiBase}/p2p/connections/${connectionId}/`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  if (!response.ok) {
    throw new Error(`Failed to delete connection: ${response.status}`);
  }
};

export const getValidations = async (
  accessToken: string,
): Promise<UserValidations> => {
  const response = await fetch(`${v2ApiBase}/p2p/validations/`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch validations: ${response.status}`);
  }

  return response.json();
};

export const deleteValidation = async (
  accessToken: string,
  validationId: string,
): Promise<void> => {
  const response = await fetch(
    `${v2ApiBase}/p2p/validations/${validationId}/`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  if (!response.ok) {
    throw new Error(`Failed to delete validation: ${response.status}`);
  }
};

export const postSubmitInvite = async (
  accessToken: string,
  inviteData: InviteData,
): Promise<string> => {
  const response = await fetch(`${v2ApiBase}/p2p/connections/invite/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(inviteData),
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(
      errorData.detail || `Connection failed with status: ${response.status}`,
    );
  }

  const responseJson = await response.json();
  const inviteCode = responseJson['invite_code'];
  let inviteURL = `${process.env.REACT_APP_KEYCLOAK_REALM_URI}/protocol/openid-connect/`;
  inviteURL += `registrations?invitation_code=${inviteCode}`;
  inviteURL += `&client_id=${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`;
  inviteURL += `&response_type=code&scope=openid`;
  inviteURL += `&redirect_uri=${`${encodeURIComponent(window.location.origin)}/signin-callback`}`;
  return inviteURL;
};

export const getMessages = async (accessToken: string): Promise<Messages> => {
  const response = await fetch(`${v2ApiBase}/p2p/async/messages/`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch validations: ${response.status}`);
  }

  return response.json();
};

export const postValidationRequest = async (
  accessToken: string,
  connectionId: number,
): Promise<void> => {
  const response = await fetch(`${v2ApiBase}/p2p/async/validations/request/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ connection_id: connectionId }),
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(
      errorData.detail ||
        `Validation request failed with status: ${response.status}`,
    );
  }

  return;
};

export const dismissIncomingMessage = async (
  accessToken: string,
  messageId: string,
): Promise<void> => {
  const response = await fetch(
    `${v2ApiBase}/p2p/async/messages/${messageId}/dismiss/`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(
      errorData.detail ||
        `Message dismissal failed with status: ${response.status}`,
    );
  }

  return;
};
