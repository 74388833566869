import React from 'react';
import { FileQuestion } from 'lucide-react';
import { Button } from 'src/components/ui/button';

const PageNotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-[calc(100vh-240px)] text-center space-y-4">
      <FileQuestion className="text-accent w-12 h-12" />
      <h3>Page Not Found</h3>
      <Button
        animated
        size="lg"
        onClick={() => window.location.replace(window.location.origin)}
      >
        Return Home
      </Button>
    </div>
  );
};

export default PageNotFound;
