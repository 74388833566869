import { useAuth } from 'react-oidc-context';
import React, { useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import { UserRoundPlus, KeyRoundIcon, BookOpen } from 'lucide-react';
import Logo from '../../icons/auraclesLogo';
import useDisclosure from '../../old/hooks/useDisclosure';
import AboutCreativePassport from '../modals/AboutCreativePassport.js';
import Footer from '../../components/Footer/index.js';
import { SignedInHeader } from '../../components/SignedInHeader';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'src/components/ui/dialog';
import auraclesTransition from '../../images/auracles_transition.png';

// This is generally not supported by Keycloak, and not at all by react-oidc-provider, hence this hardcode.
let directRegistrationLink = `${process.env.REACT_APP_KEYCLOAK_REALM_URI}/protocol/openid-connect/registrations`;
directRegistrationLink += `?client_id=${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`;
directRegistrationLink += `&response_type=code&scope=openid`;
directRegistrationLink += `&redirect_uri=${encodeURIComponent(`${window.location.origin}/signin-callback`)}`;

const WelcomePage = () => {
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const [isAuthed, setIsAuthed] = React.useState(false);
  const [showCprDialog, setShowCprDialog] = React.useState(false);
  const {
    isOpen: isAboutCreativePassportOpen,
    onOpen: onAboutCreativePassportOpen,
    onClose: onAboutCreativePassportClose,
  } = useDisclosure(false);

  useEffect(() => {
    if (auth.isAuthenticated) {
      setIsAuthed(true);
    } else {
      setIsAuthed(false);
    }
  }, [auth.isAuthenticated]);

  useEffect(() => {
    if (searchParams.get('cpr') === 'true') {
      setShowCprDialog(true);
    }
  }, [searchParams]);

  const welcomePageContent = (
    <div className="h-screen w-screen flex items-center justify-center">
      <AboutCreativePassport
        isOpen={isAboutCreativePassportOpen}
        onClose={onAboutCreativePassportClose}
      />

      <div
        className="px-0 sm:px-4 h-full w-full bg-gradient-to-br from-[#00FFCC] via-[#669ACB] to-[#ff2667] shadow-lg
        flex flex-col items-center justify-between"
      >
        {/* Content Section */}
        <div className="flex flex-col items-center justify-center h-full">
          <Logo className="w-32 h-auto m-4" />
          <h2 className="text-white text-center text-2xl">Auracle ID</h2>

          {/* Info Box */}
          <div className="flex flex-col gap-4 items-center justify-center text-center max-w-[460px] text-white">
            <p className="text-base font-semibold">
              Introducing the missing digital foundation layer for music. A
              verified digital ID with an information and permissions source for
              music makers, services, and representatives. Founded by Imogen
              Heap.
            </p>
            <div
              className="flex flex-row items-center hover:opacity-60 transition-opacity cursor-pointer gap-2"
              onClick={onAboutCreativePassportOpen}
            >
              <BookOpen className="h-3 w-3" />
              <p className="underline decoration-dotted text-sm">Learn more</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );

  return (
    <>
      <SignedInHeader />
      {isAuthed ? <Navigate to="/" /> : welcomePageContent}

      <Dialog open={showCprDialog} onOpenChange={setShowCprDialog}>
        <DialogContent className="max-h-[90vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Welcome Creative Passport User!</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <p>
              As an initiative to expand the toolkit available to creatives,
              Imogen has decided to rebrand The Creative Passport as part of her
              Auracles suite of services.
            </p>
            <div className="flex justify-center">
              <img
                src={auraclesTransition}
                alt="Auracles Transition"
                className="max-w-48 h-auto"
              />
            </div>
            <p>
              Based on the physical concept of auras, an Auracle is a place for
              everything surrounding a person or a thing.
            </p>
            <p>
              <b>Auracles for music makers:</b> Formerly your Creative Passport,
              your Auracle is the centralised store for “the everything of you”
              as an artist. For example, are you or your team tired of updating
              your bio on multiple services? Maybe you don’t even have that
              level of control on some services? With Auracles you simply update
              your bio in one place, then participating services will know where
              to find and update their version. You can add all your known
              identifiers (ISNI, IPI, IPN etc), add all of your contacts
              (manager, agent etc), add your skills, your instruments, your
              interests, inspirations etc. The more the industry knows about
              you, the easier it is to direct business. And when business is
              easy, it’s more likely to flow your way!
            </p>
            <p>
              <b>Auracles for songs:</b> Once you have verified your artist
              Auracle, you’ll soon be able to create Auracles of your works.
              Imagine a centralised place to store, permission and share
              everything about a song, including not only credits, and metadata,
              but also where you can set your permissions for other services,
              such as stem usage, sample use, AI training etc. A place where
              every service can access your source of truth and honour your
              permissions. Furthermore, each song Auracle becomes a central
              place for other artists to submit their derivative works of the
              song, to make remix and sample clearance easier. A song Auracle
              truly is “the everything” of a song.
            </p>
          </div>
          <div className="mt-4 flex justify-center">
            <Button onClick={() => setShowCprDialog(false)}>Continue</Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default WelcomePage;
