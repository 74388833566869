import React, { useEffect, useRef, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useAuth } from 'react-oidc-context';
import { fetchValidationCode } from '../v2api/P2P/http.ts';
import { motion, AnimatePresence } from 'framer-motion';

class ValidationCodeManager {
  private codes: string[] = [];
  private progress = 0; // %100
  private isLoading = false;
  private hasSwitched = false;
  private codeInterval = null;
  private progressInterval = null;
  public token: string | null = null;

  start(onUpdate: () => void) {
    if (!this.codeInterval) {
      this.codeTick().then(onUpdate);
      this.codeInterval = setInterval(() => {
        this.codeTick().then(onUpdate);
      }, 500);
    }
    if (!this.progressInterval) {
      this.progressInterval = setInterval(() => {
        this.progressTick().then(onUpdate);
      }, 50);
    }
  }

  stop() {
    if (this.codeInterval) {
      clearInterval(this.codeInterval);
      this.codeInterval = null;
    }
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
      this.progressInterval = null;
    }
    this.token = null;
  }

  getCode() {
    if (this.codes.length === 0) return null;
    return this.codes[0];
  }

  getProgress() {
    return this.progress;
  }

  async codeTick(): Promise<void> {
    if (!this.token) return;
    const msIntoCurrentCycle = Date.now() % 5000;

    // If no codes, get a code
    if (!this.isLoading && this.codes.length === 0) {
      this.isLoading = true;
      try {
        const code = await fetchValidationCode(this.token);
        this.codes = [...this.codes, code];
      } finally {
        this.isLoading = false;
      }
    }

    // Just before we reset, fetch the next code
    if (
      !this.isLoading &&
      this.codes.length === 1 &&
      msIntoCurrentCycle > 3500
    ) {
      this.isLoading = true;
      try {
        const code = await fetchValidationCode(this.token);
        this.codes = [...this.codes, code];
      } finally {
        this.isLoading = false;
      }
    }

    // switch out the current code for the cached one
    if (msIntoCurrentCycle < 800 && !this.hasSwitched) {
      this.codes = this.codes.length ? this.codes.slice(1) : this.codes;
      this.hasSwitched = true;
    }

    // reset hasSwitched when we reset the timer
    if (msIntoCurrentCycle > 2000 && this.hasSwitched) {
      this.hasSwitched = false;
    }
  }

  async progressTick(): Promise<void> {
    const msIntoCurrentCycle = Date.now() % 5000;
    this.progress = Math.round(msIntoCurrentCycle / 50);
  }
}

export const QRCodeDisplay = () => {
  const { user } = useAuth();
  const codeManagerRef = useRef<ValidationCodeManager | null>(null);
  const [, forceUpdate] = useState({});

  useEffect(() => {
    if (codeManagerRef.current === null) {
      codeManagerRef.current = new ValidationCodeManager();
    }
    codeManagerRef.current.start(() => forceUpdate({}));
    return () => codeManagerRef.current.stop();
  }, []);

  useEffect(() => {
    if (codeManagerRef.current) {
      codeManagerRef.current.token = user?.access_token;
    }
  }, [user?.access_token]);

  const progress = codeManagerRef.current?.getProgress() || 0;

  const opacity = 1 - (progress / 100) * 0.7; // Calculate opacity based on progress

  return (
    <div className="flex flex-col items-center justify-center flex-grow">
      <div className="w-full max-w-[361px] aspect-square relative cursor-pointer rounded-2xl">
        {/* Background div with dynamic opacity */}
        <div
          className="absolute inset-0 text-[#23a194] hover:text-foreground/80
          bg-[#71cbc2] shadow-[inset_0px_0px_58px_8px_rgba(255,_255,_255,_0.9)] rounded-2xl"
          style={{ opacity }}
        />
        {/* Content remains at full opacity */}
        <div className="absolute inset-0">
          <BorderTimer progress={progress} />
        </div>
        <div className="absolute inset-[4px] flex items-center justify-center">
          <AnimatePresence>
            {codeManagerRef.current?.getCode() && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <QRCodeSVG
                  value={codeManagerRef.current.getCode()}
                  size={300}
                  level="H"
                  marginSize={1}
                  fgColor="#124a45"
                  bgColor="transparent"
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

const BorderTimer = ({ progress }) => {
  return (
    <div className="w-full h-full relative rounded-2xl">
      {/* Background border */}
      <div className="absolute inset-0 rounded-2xl border-2 border-accent" />

      {/* Animated border */}
      <div
        className="absolute inset-0 rounded-xl"
        style={{
          background: `conic-gradient(
            from 0deg,
            #008578 ${progress}%,
            #8de0d8 ${progress}%
          )`,
          WebkitMask:
            'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          WebkitMaskComposite: 'xor',
          maskComposite: 'exclude',
          padding: '1px',
        }}
      />
    </div>
  );
};
