import React from 'react';
import CPVisibleIcon from '../../CPVisibleIcon';

export const ReadOnlyProject = ({ customAttr, isVisible, isCMS = true }) => {
  return (
    <>
      {customAttr?.fieldName && (
        <div
          className={`${isVisible ? 'bg-blueberry-foreground/20' : 'bg-blueberry-foreground/5'} p-4 rounded-md`}
        >
          <div className="flex flex-row items-center justify-between">
            <h2 className="mb-0">{customAttr.fieldName}</h2>
            {isCMS && <CPVisibleIcon isVisible={isVisible} />}
          </div>
          {customAttr?.value && <p>{customAttr.value}</p>}
        </div>
      )}
    </>
  );
};
