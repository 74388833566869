import * as React from 'react';
import {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState,
  useCallback,
} from 'react';
import toast from 'react-hot-toast';
import { AuthState } from 'react-oidc-context';
import { Action, IUserSettings } from './types';
import { getUserSettings, patchUserSettings } from './http';

type UserSettingsContextType = {
  userSettings: IUserSettings | null;
  updateUserSettings: (action: Action) => void;
};

const initialState: IUserSettings | null = null;

export const UserSettingsContext =
  createContext<UserSettingsContextType | null>(null);

/* For internal state management of the context provider, mirrors server state */
function reducer(
  state: IUserSettings | null,
  action: Action,
): IUserSettings | null {
  switch (action.method) {
    case 'LOAD':
      return action.payload;
    case 'PATCH':
      return { ...state, ...action.payload };
    default:
      console.warn(
        `Unhandled userSettings state action: <${JSON.stringify(action)}>`,
      );
      return state;
  }
}

interface UserSettingsProviderProps {
  auth: AuthState | null;
  children: ReactNode;
}

export const UserSettingsProvider: React.FC<UserSettingsProviderProps> = ({
  auth,
  children,
}) => {
  const [userSettings, dispatch] = useReducer(reducer, initialState);
  const [currentAction, setCurrentAction] = useState<Action | null>(null);
  const [isBusy, setIsBusy] = useState(false);

  /* Optimistic updating from actions, see PassportProvider for more info*/
  useEffect(() => {
    if (currentAction === null) return;
    const oldState = { ...userSettings };
    const asyncEffect = async (action: Action, token: string) => {
      if (isBusy)
        return console.warn(
          `userSettings action in progress, ${action.method} REJECTED`,
        );
      setIsBusy(true);
      switch (action.method) {
        case 'SERVERLOAD':
          if (userSettings === null) {
            return await getUserSettings(token)
              .then((data) => {
                dispatch({ method: 'LOAD', payload: data });
                return setIsBusy(false);
              })
              .catch((err) => {
                console.warn(`Failed userSettings serverload, ${err?.message}`);
                return setIsBusy(false);
              });
          } else {
            setIsBusy(false);
            console.warn('Refusing to SERVERLOAD - userSettings not null');
          }
          return setIsBusy(false);
        case 'LOAD':
          setIsBusy(false);
          return dispatch(action);
        case 'PATCH':
          dispatch(action);
          return await patchUserSettings(token, action.payload).then(
            (patchSuccess) => {
              if (patchSuccess) {
                console.log('Settings successfully updated');
              } else {
                toast.error(`Settings not saved`);
                setTimeout(
                  () => setCurrentAction({ method: 'LOAD', payload: oldState }),
                  1000,
                );
              }
              setIsBusy(false);
            },
          );
        default:
          console.warn(
            `Unknown action on userSettings: ${JSON.stringify(action)}`,
          );
          setIsBusy(false);
      }
    };

    // Where we actually start the async execution
    const token = auth?.user?.access_token || '';
    if (token) {
      asyncEffect(currentAction, token).catch((err: any) => {
        console.warn(err);
        dispatch({ method: 'LOAD', payload: oldState });
        setCurrentAction(null);
        setIsBusy(false);
      });
    } else {
      console.warn('Auth manager did not provide an access token');
      setIsBusy(false);
    }

    // Cleanup. isBusy is managed only in the asyncEffect "strands"
    return () => {
      setCurrentAction(null);
    };
  }, [auth?.user?.access_token, currentAction, userSettings]);

  // onLoad: SERVERLOAD
  useEffect(() => {
    setCurrentAction({ method: 'SERVERLOAD' });
  }, []);

  const updateUserSettings = useCallback((action: Action) => {
    setCurrentAction(action);
  }, []);

  return (
    <UserSettingsContext.Provider value={{ userSettings, updateUserSettings }}>
      {children}
    </UserSettingsContext.Provider>
  );
};
