import React, { useState } from 'react';
import { Button } from 'src/components/ui/button';
import { useAuth } from 'react-oidc-context';
import toast from 'react-hot-toast';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import { QRScanner } from './QRScanner';
import { createConnection, validateConnection } from '../v2api/P2P/http';
import { PendingValidation } from '../v2api/P2P/types';
import { Dialog, DialogContent } from 'src/components/ui/dialog';
import { AlertTriangle } from 'lucide-react';

interface ValidateSomeoneProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ValidateSomeone: React.FC<ValidateSomeoneProps> = ({
  isOpen,
  onClose,
}) => {
  const { user } = useAuth();
  const [pendingValidation, setPendingValidation] =
    useState<PendingValidation | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const scannerRef = React.useRef<{ forceStop: () => void } | null>(null);

  const handleScanSuccess = async (decodedText: string) => {
    scannerRef.current?.forceStop();
    if (!user?.access_token) {
      console.error('Unauthed user scanned a QR code?');
      onClose();
      return;
    }
    try {
      const connectionData = await createConnection(
        user.access_token,
        decodedText,
      );
      setPendingValidation(connectionData);
      setShowConfirmation(true);
      toast.success('Connection established successfully');
    } catch (err) {
      console.error(err);
      onClose();
    }
  };

  const handleConfirmValidation = async () => {
    if (!pendingValidation || !user?.access_token) return;

    try {
      await validateConnection(user.access_token, pendingValidation);
      toast.success('Verification completed successfully');
      setShowConfirmation(false);
      onClose();
    } catch (error) {
      toast.error(`Failed to complete verification ${error.message}`);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          scannerRef.current?.forceStop();
          onClose();
        }
      }}
    >
      <DialogContent>
        {showConfirmation ? (
          <div className="space-y-4">
            <div className="text-center space-y-4">
              <h3 className="text-lg font-semibold">Confirm Connection</h3>
              <p className="text-sm text-muted-foreground">
                Please confirm that you want to validate this profile
              </p>
              {pendingValidation && (
                <div className="flex flex-col items-center space-y-2">
                  <Avatar className="object-cover w-24 h-24 mb-2 rounded-full">
                    <AvatarImage
                      src={`${process.env.REACT_APP_V2_API_BASE}/media/persona/${pendingValidation.professional_profile_id}/small.webp`}
                    />
                    <AvatarFallback>
                      {pendingValidation.firstname[0]}
                      {pendingValidation.lastname[0]}
                    </AvatarFallback>
                  </Avatar>
                  <div>
                    <p className="font-medium">
                      {pendingValidation.firstname} {pendingValidation.lastname}
                    </p>
                  </div>
                </div>
              )}
              <div className="flex justify-center space-x-2">
                <Button
                  variant="accent"
                  onClick={() => setShowConfirmation(false)}
                >
                  Cancel
                </Button>
                <Button onClick={handleConfirmValidation}>Confirm</Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="space-y-4">
            <h2>Validate exisiting user</h2>
            <div className="flex items-start gap-2 text-sm text-muted-foreground">
              <AlertTriangle className="w-5 h-5 mt-0.5 text-primary-accent flex-shrink-0" />
              <div>
                <p className="text-primary-accent font-medium inline">
                  Only validate peers you know personally, to help maintain the
                  trust network.{' '}
                </p>
                Enable camera permissions, and scan their QR.
              </div>
            </div>
            <QRScanner
              ref={scannerRef}
              isEnabled={isOpen && pendingValidation === null}
              qrCodeSuccessCallback={handleScanSuccess}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
