import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import SignedInHeader from '../SignedInHeader';
import Footer from '../Footer';

// Adds a header, and intercepts new users to the new user flow
const AuthedPagesWrapper = (props) => {
  return (
    <div className="flex flex-col min-h-screen text-foreground">
      <CssBaseline />
      <SignedInHeader />
      <div className="h-[108px] sm:h-[140px]" />
      {/* Above is space to prevent content overlap of nav */}
      <div>
        <main className="flex flex-col items-center justify-center flex-grow mx-0 sm:mx-10">
          <div className="items-center w-full max-w-screen-lg">
            {props.children}
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default AuthedPagesWrapper;
