import Verification from '../verify/VerifyNew';
import CMSPublicProfile from '../PublicProfile';
import { useContext } from 'react';
import { PersonaContext } from 'src/v2api/PersonaContext/PersonaContext';

const AllMedium = () => {
  const { persona } = useContext(PersonaContext);
  if (persona?.isProfessionalProfile) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Verification />
        <CMSPublicProfile allowOnMap={true} />
      </div>
    );
  } else
    return (
      <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
        <CMSPublicProfile allowOnMap={true} />
      </div>
    );
};

export default AllMedium;
