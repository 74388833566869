import { AuthState } from 'react-oidc-context';
import * as React from 'react';
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { P2PConnection } from './types';
import { getConnections, getMessages, getValidations } from './http';

interface P2PProviderProps {
  auth: AuthState | null;
  children: ReactNode;
}

type P2PContextType = {
  p2pConnections: P2PConnection[];
  p2pRefresh: () => Promise<void>;
};

export const P2PContext = createContext<P2PContextType | null>(null);

export const P2PProvider: React.FC<P2PProviderProps> = ({ auth, children }) => {
  const [p2pConnections, setP2pConnections] = useState<P2PConnection[]>([]);

  const updateP2PConnections = useCallback(async () => {
    if (!auth?.user?.access_token) return;
    const token = auth?.user?.access_token;
    const userConnections = await getConnections(token);
    const _p2pConnections = [
      ...userConnections.incoming,
      ...userConnections.outgoing,
    ].map(
      (c) =>
        ({
          connection: c,
          connection_id: c.connection_id,
          incoming_validation: null,
          outgoing_validation: null,
          incoming_request_status: null,
          outgoing_request_status: null,
          incoming_request_code: null,
          incoming_request_id: null,
        }) as P2PConnection,
    );
    const userValidations = await getValidations(token);
    userValidations.incoming.forEach((v) => {
      const p2pC = _p2pConnections.find(
        (c) => c.connection.connection_id === v.connection_id,
      );
      if (p2pC) p2pC.incoming_validation = v;
    });
    userValidations.outgoing.forEach((v) => {
      const p2pC = _p2pConnections.find(
        (c) => c.connection.connection_id === v.connection_id,
      );
      if (p2pC) p2pC.outgoing_validation = v;
    });
    const messages = await getMessages(token);
    // messages should be returned in order
    const unixNow = Math.round(Date.now() / 1000);
    messages.incoming.forEach((m) => {
      if (m.type !== 'VALIDATION_REQUEST') return;
      const p2pC = _p2pConnections.find(
        (c) => c.connection.passport_id === m.sender_passport_id,
      );
      if (!p2pC) return;
      if (
        m.expires &&
        m.expires < unixNow &&
        p2pC.incoming_request_status === null
      )
        p2pC.incoming_request_status = 'EXPIRED';
      else if (m.dismissed) p2pC.incoming_request_status = 'DISMISSED';
      else {
        p2pC.incoming_request_status = 'PENDING';
        p2pC.incoming_request_code = m.value;
        p2pC.incoming_request_id = m.id;
      }
    });
    messages.outgoing.forEach((m) => {
      if (m.type !== 'VALIDATION_REQUEST') return;
      const p2pC = _p2pConnections.find(
        (c) => c.connection.passport_id === m.recipient_passport_id,
      );
      if (!p2pC) return;
      if (m.expires && m.expires < unixNow)
        p2pC.outgoing_request_status = 'EXPIRED';
      else if (m.dismissed) p2pC.outgoing_request_status = 'DISMISSED';
      else p2pC.outgoing_request_status = 'PENDING';
    });
    setP2pConnections(_p2pConnections);
  }, [auth?.user?.access_token]);

  useEffect(() => {
    updateP2PConnections();
  }, [updateP2PConnections]);

  const p2pRefresh = useCallback(async () => {
    await updateP2PConnections();
  }, [updateP2PConnections]);

  return (
    <P2PContext.Provider value={{ p2pConnections, p2pRefresh }}>
      {children}
    </P2PContext.Provider>
  );
};
