import React from 'react';
import { useContext } from 'react';
import CMSBio from 'src/CMS/components/Bio';
import Representatives from 'src/CMS/components/Representatives';
import PersonalInfo from 'src/CMS/components/PersonalInfo';
import OfficialLinks from 'src/CMS/components/OfficialLinks';
import IndustryIds from 'src/CMS/components/IndustryIDs';
import Projects from 'src/CMS/components/Projects';
import PersonaSummary from 'src/CMS/components/PersonaSummary';
import { PersonaContext } from 'src/v2api/PersonaContext/PersonaContext';

const PersonaPublicView = ({ onDelete }) => {
  const { persona, isNew } = useContext(PersonaContext);
  if (!persona) return null;
  if (persona.personaId === null) {
    return <div>Loading...</div>;
  }
  return (
    <div className="space-y-4">
      <PersonaSummary onDelete={onDelete} isNew={isNew} isCMS={false} />
      <PersonalInfo isCMS={false} />
      <CMSBio isCMS={false} />
      <Projects isCMS={false} />
      <IndustryIds isCMS={false} />
      <Representatives isCMS={false} />
      <OfficialLinks isCMS={false} />
    </div>
  );
};

export default PersonaPublicView;
