import React from 'react';
import { Eye, EyeOff } from 'lucide-react';

const CPVisibleIcon = ({ isVisible }) => {
  return (
    <div
      className={`flex items-center ${
        isVisible ? '' : 'text-muted-foreground opacity-50'
      }`}
    >
      {isVisible ? <Eye size={16} /> : <EyeOff size={16} />}
    </div>
  );
};

export default CPVisibleIcon;
