import React from 'react';
import CPVisibleIcon from '../../CPVisibleIcon';
import { text_fields } from '../../../../text_fields';
import { Button } from 'src/components/ui/button';
const specialLinks = text_fields['knownOfficialLinkTypes'];

function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

const logos_links = importAll(
  require.context(
    '../../../../shared/icons/logos',
    false,
    /\.(png|jpe?g|svg)$/,
  ),
);

const absolutifyUrl = (userUrl) => {
  if (!/^https?:\/\//i.test(userUrl)) {
    return 'https://' + userUrl;
  }
  return userUrl;
};

const ReadOnlyOfficialLink = ({ customAttr, isVisible, isCMS = true }) => {
  const isSpecial = customAttr.fieldName in specialLinks;

  const imgFile = isSpecial
    ? logos_links[`${customAttr.fieldName}.svg`] ||
      logos_links[`${customAttr.fieldName}.png`]
    : logos_links['website.svg'];

  if (!imgFile) console.warn(`No img file found for ${customAttr.fieldName}`);

  return (
    <a
      target="_blank"
      href={absolutifyUrl(customAttr.value)}
      rel="noopener noreferrer"
      className="mb-1 flex-shrink-0"
    >
      <Button
        variant="accent"
        className="flex flex-col items-center justify-center p-2 h-auto min-h-[75px] w-full"
        animated
      >
        <img
          src={imgFile}
          className="h-[20px] mb-1 flex-shrink-0"
          alt={customAttr.fieldName}
          draggable="false"
        />

        <span className="text-xs text-center w-full overflow-hidden whitespace-normal break-normal text-ellipsis">
          {isSpecial
            ? specialLinks[customAttr.fieldName].label
            : customAttr?.fieldName}
        </span>
        <div className="mt-1">
          {isCMS && <CPVisibleIcon isVisible={isVisible} />}
        </div>
      </Button>
    </a>
  );
};

export default ReadOnlyOfficialLink;
