import React, { useContext, useState } from 'react';
import { Button } from 'src/components/ui/button';
import { IdCard, Plus } from 'lucide-react';
import { IndustryIdReadOnly } from './IndustryIdReadOnly';
import CMSTitleInfo from '../Title';
import EditIDs from './EditIDs';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';
import { PersonaAccessContext } from '../../../v2api/PersonaAccessContext/PersonaAccessContext';
import PropTypes from 'prop-types';

const IDs = ({ isCMS = true }) => {
  const { persona } = useContext(PersonaContext);
  const personaAccessContext = useContext(PersonaAccessContext);
  const { personaAccess, idAttrAccess, updateAccess } =
    isCMS && personaAccessContext
      ? personaAccessContext
      : { personaAccess: null, idAttrAccess: null, updateAccess: () => {} };
  const [editorOpen, setEditorOpen] = useState(false);

  const nIds =
    !!persona?.unverifiedIpi +
    !!persona?.unverifiedIpn +
    !!persona?.unverifiedIsni +
    persona?.customIds?.length;

  if (!isCMS && !nIds) {
    return null;
  }

  return (
    <div>
      {isCMS ? (
        <EditIDs isOpen={editorOpen} closeModal={() => setEditorOpen(false)} />
      ) : null}
      {nIds ? (
        <div className="card">
          <CMSTitleInfo
            isCMS={isCMS}
            title="IDs"
            isNotEmpty={nIds > 0}
            message="Edit IDs"
            onClick={() => setEditorOpen(true)}
            isVisible={
              personaAccess?.customIds ||
              personaAccess?.unverifiedIpi ||
              personaAccess?.unverifiedIpn ||
              personaAccess?.unverifiedIpn
            }
            setVisible={(v) => {
              if (v === true) {
                updateAccess({ method: 'PATCH', payload: { customIds: true } });
              } else {
                updateAccess({
                  method: 'PATCH',
                  payload: {
                    customIds: false,
                    unverifiedIpn: false,
                    unverifiedIpi: false,
                    unverifiedIsni: false,
                  },
                });
              }
            }}
            isVisibilityDisabled={!personaAccess?.personaId}
          />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
            {persona?.unverifiedIsni && (
              <IndustryIdReadOnly
                fieldName={'isni'}
                value={persona.unverifiedIsni}
                isVisible={personaAccess?.unverifiedIsni || false}
                isCMS={isCMS}
              />
            )}
            {persona?.unverifiedIpn && (
              <IndustryIdReadOnly
                fieldName={'ipn'}
                value={persona.unverifiedIpn}
                isVisible={personaAccess?.unverifiedIpn || false}
                isCMS={isCMS}
              />
            )}
            {persona?.unverifiedIpi && (
              <IndustryIdReadOnly
                fieldName={'ipi'}
                value={persona.unverifiedIpi}
                isVisible={personaAccess?.unverifiedIpi || false}
                isCMS={isCMS}
              />
            )}
            {persona.customIds?.length
              ? persona.customIds.map((cid) => (
                  <IndustryIdReadOnly
                    key={cid.uniqueKey}
                    fieldName={cid.fieldName}
                    value={cid.value}
                    isVisible={idAttrAccess?.[cid.uniqueKey]}
                    isCMS={isCMS}
                  />
                ))
              : null}
          </div>
        </div>
      ) : (
        <div className="card-inactive">
          <div className="flex flex-col gap-4 items-center justify-center h-36">
            <div className="flex gap-2 opacity-20">
              <IdCard />
            </div>
            <p>
              If you have any music IDs such as ISNI or IPN you can list them
              here
            </p>
            <Button
              size="lg"
              data-testid="add-data-button"
              onClick={() => setEditorOpen(true)}
            >
              <Plus className="w-4 h-4" />
              ID
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

IDs.propTypes = {
  isCMS: PropTypes.bool,
};

export default IDs;
