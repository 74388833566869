import React, { useState, useContext } from 'react';
import { Card, CardContent } from 'src/components/ui/card';
import { Badge } from 'src/components/ui/badge';
import { Table, TableBody, TableCell, TableRow } from 'src/components/ui/table';
import countries from 'i18n-iso-countries';
import ProfileImage from '../../../components/ProfileImage';
import SummaryEdit from './SummaryEdit';
import CPEditButton from '../CPEditButton';
import CPVisibleIcon from '../CPVisibleIcon';
import { MapPin, User2, Calendar } from 'lucide-react';

import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';
import { PassportContext } from '../../../v2api/PassportContext/PassportContext';
import { PersonaAccessContext } from '../../../v2api/PersonaAccessContext/PersonaAccessContext';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const PersonaSummary = ({ isCMS, isNew, onDelete }) => {
  const { persona } = useContext(PersonaContext);
  const passportContext = useContext(PassportContext);
  const { passport } = isCMS ? passportContext : { passport: null };
  const personaAccessContext = useContext(PersonaAccessContext);
  const { personaAccess } =
    isCMS && personaAccessContext
      ? personaAccessContext
      : { personaAccess: null };
  const [isEditMode, setIsEditMode] = useState(isNew);

  const displayName =
    persona?.isProfessionalProfile && isCMS
      ? `${passport?.unverifiedFirstname || ''} ${passport?.unverifiedLastname || ''}`
      : persona?.artistName;
  const displayCountry =
    persona?.isProfessionalProfile && isCMS
      ? passport?.unverifiedCountry
      : persona?.countryAlpha2;
  const displayDob =
    persona?.isProfessionalProfile && isCMS
      ? passport?.unverifiedDob
      : persona?.dateOfBirth;

  const thumbnail = null;
  const isFetching = false;

  const rolesOrFeatures = persona?.isProfessionalProfile
    ? 'musicRoles'
    : 'features';

  return (
    <>
      {isCMS && (
        <SummaryEdit
          isOpen={isEditMode}
          closeModal={() => setIsEditMode(false)}
          onDelete={onDelete}
        />
      )}

      <Card className="p-4 relative">
        <CardContent className="p-0">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="self-center my-4 ml-4 w-48 sm:w-72 p-1 bg-gradient-to-r from-teal-300 via-blue-400 to-pink-500 rounded-full">
              <ProfileImage
                personaId={persona?.personaId}
                alt={displayName}
                disableUpload
                isBusy={isFetching}
                isVerified={0}
              />
            </div>

            <div className="flex-grow">
              <div
                className="my-4 ml-4 mr-2 flex justify-between"
                data-testid={`first-last-name-${persona?.personaId}-display`}
              >
                <div>
                  {persona?.isProfessionalProfile && persona?.alias ? (
                    persona?.aliasOverwritesName ? (
                      <h3>{persona?.alias}</h3>
                    ) : (
                      <>
                        <h3>{persona?.alias}</h3>
                        <h2>{displayName}</h2>
                      </>
                    )
                  ) : (
                    <h3>{displayName}</h3>
                  )}
                  {persona?.isProfessionalProfile && (
                    <Badge>{passport?.auracleId || persona?.auracleId}</Badge>
                  )}
                </div>
                {isCMS && (
                  <CPEditButton
                    title={`Edit ${persona?.isProfessionalProfile ? 'Personal' : 'Artist Project'} Summary`}
                    onClick={() => setIsEditMode(true)}
                    data-testid={`edit-${persona?.personaId}-button`}
                  />
                )}
              </div>

              {/*}
              <div className="mb-4 mx-4">
                {persona &&
                rolesOrFeatures in persona &&
                persona[rolesOrFeatures]?.length ? (
                  <p
                    className="text-sm text-gray-600"
                    data-testid={`features-roles-${persona?.personaId}-display`}
                  >
                    {persona[rolesOrFeatures]
                      .slice(0, 3)
                      .map((el, index, arr) => (
                        <React.Fragment key={el}>
                          {el} {index !== arr.length - 1 && '|'}{' '}
                        </React.Fragment>
                      ))}
                  </p>
                ) : (
                  <p></p>
                )}
              </div>
*/}
              <Table className="w-full">
                <TableBody>
                  {(displayCountry || persona?.city) && (
                    <TableRow>
                      <TableCell className="font-medium overflow-hidden rounded-l-lg capitalize flex items-center gap-2">
                        <MapPin className="w-4 h-4" />
                        Location
                      </TableCell>
                      <TableCell
                        className="whitespace-normal text-right"
                        data-testid={`country-${persona?.personaId}-display`}
                      >
                        {persona?.city}
                        {persona?.city && displayCountry ? ', ' : ''}
                        {countries.getName(displayCountry, 'EN')}
                      </TableCell>
                      <TableCell className="w-4 rounded-r-lg">
                        {isCMS && (
                          <CPVisibleIcon
                            isVisible={personaAccess?.countryAlpha2}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )}

                  {persona?.isProfessionalProfile &&
                    (passport?.unverifiedGender ||
                      persona.gender ||
                      persona.pronouns) && (
                      <TableRow>
                        <TableCell className="font-medium overflow-hidden rounded-l-lg capitalize flex items-center gap-2">
                          <User2 className="w-4 h-4" />
                          Gender
                        </TableCell>
                        <TableCell
                          className="whitespace-normal text-right capitalize"
                          data-testid={`gender-${persona?.personaId}-display`}
                        >
                          {passport?.unverifiedGender || persona?.gender}
                          {(passport?.unverifiedGender || persona?.gender) &&
                          persona?.pronouns
                            ? ' | '
                            : null}
                          {persona?.pronouns}
                        </TableCell>
                        <TableCell className="w-4 rounded-r-lg">
                          {isCMS && (
                            <CPVisibleIcon
                              isVisible={
                                personaAccess?.pronouns || personaAccess?.gender
                              }
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    )}

                  {displayDob && (
                    <TableRow>
                      <TableCell className="font-medium overflow-hidden rounded-l-lg capitalize flex items-center gap-2">
                        <Calendar className="w-4 h-4" />
                        Birthday
                      </TableCell>
                      <TableCell className="whitespace-normal text-right">
                        {displayDob}
                      </TableCell>
                      <TableCell className="w-4 rounded-r-lg">
                        {isCMS && (
                          <CPVisibleIcon
                            isVisible={personaAccess?.dateOfBirth}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default PersonaSummary;
