import { Box, SvgIcon, Typography } from '@mui/material';
import Logo from '../../icons/cpLogoWithTextWhite';
import { useAuth } from 'react-oidc-context';
import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import IconTextButton from '../../components/IconTextButton';
import Plus from '../../icons/atomsIconsAdd';

// This is generally not supported by Keycloak, and not at all by react-oidc-provider, hence this hardcode.
let directRegistrationLink = `${process.env.REACT_APP_KEYCLOAK_REALM_URI}/protocol/openid-connect/registrations`;
directRegistrationLink += `?client_id=${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`;
directRegistrationLink += `&response_type=code&scope=openid`;
directRegistrationLink += `&redirect_uri=${encodeURIComponent(`${window.location.origin}/signin-callback`)}`;

const OldSiteWelcome = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [isAuthed, setIsAuthed] = React.useState(false);

  useEffect(() => {
    if (auth.isAuthenticated) {
      setIsAuthed(true);
    } else {
      setIsAuthed(false);
    }
  }, [auth.isAuthenticated]);

  const redirectContent = <Navigate to="/" />;

  const welcomePageContent = (
    <Box
      sx={{
        height: '100vh',
        minHeight: { xs: '', sm: '', md: '768px', lg: '768px' },
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          maxHeight: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
          borderRadius: { xs: '0px', sm: '0px', md: '0px', lg: '0px' },
          padding: {
            xs: '0px',
            sm: '20px',
            md: '20px',
            lg: '20px',
          },
          backgroundImage:
            'linear-gradient(328deg, rgba(255, 38, 103, 1) 0%, rgba(102, 154, 203, 1) 51%, rgba(0, 255, 204, 1) 100%)',
          boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
          },
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '15px',
          '& > div': {
            height: {
              xs: 'auto',
              sm: 'auto',
              md: '100%',
              lg: '100%',
            },
            width: {
              xs: '100%',
              sm: '512px',
              md: '100%',
              lg: '100%',
            },
          },
        }}
      >
        {/* Content Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: {
              xs: '30%',
              sm: '30%',
              md: '100%',
              lg: '100%',
            },
            padding: '10px',
          }}
        >
          <Logo
            sx={{
              fontSize: '180px',
              width: '256px',
              margin: {
                xs: '32px',
                sm: '32px',
              },
              height: 'auto{',
            }}
          />
          <Typography
            marginTop={{ md: '70px', lg: '100px' }}
            marginBottom={{ md: '70px', lg: '100px' }}
            height="120px"
            color="white"
            textAlign="center"
            variant="h2"
            display={{ xs: 'block', sm: 'block', md: 'none', lg: 'none' }}
          >
            <br />
          </Typography>
          <Typography
            marginTop={{ md: '70px', lg: '100px' }}
            marginBottom={{ md: '70px', lg: '100px' }}
            color="white"
            textAlign="center"
            variant="h2"
            display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block' }}
          >
            Empowering music makers,
            <br />
            Empowered by Auracles
          </Typography>
          <Box
            sx={{
              backgroundColor: '#fff2',
              borderRadius: { xs: '0px', sm: '13px', md: '13px', lg: '13px' },
              padding: '32px',
              paddingX: { xs: '24px', sm: '24px', md: '30px', lg: '50px' },
              marginY: { xs: '24px', sm: '24px', md: '100px', lg: '100px' },
              boxShadow: '0px 0px 24px rgba(0,0,0, 0.1)',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '30px',
            }}
          >
            <Box display="flex" flexDirection="row">
              <Typography color="white" maxWidth="320px" align="center">
                <DarkInfo
                  sx={{
                    fontSize: '14px',
                    marginRight: '8px',
                    marginTop: '0px',
                  }}
                />
                The Creative Passport is now part of Auracles, as Imogen and the
                team expand the suite of services for creatives.
              </Typography>
            </Box>
            <IconTextButton
              size="standard"
              onClick={() => navigate('/welcome')}
              sx={{ width: '100%', minWidth: '300px' }}
              icon={<Plus hasGradient={true} sx={{ fontSize: 18 }} />}
            >
              Continue to Auracle ID
            </IconTextButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  if (isAuthed) {
    return redirectContent;
  } else {
    return welcomePageContent;
  }
};
export default OldSiteWelcome;

const DarkInfo = (props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#333" transform="translate(-316 -559)">
        <g transform="translate(300 543)">
          <g transform="translate(16 16)">
            <path d="M12 0l8.486 3.515 3.515 8.486-3.515 8.485L12 24l-8.485-3.514L0 12.001l3.515-8.486L12 0zm0 2.165L6.054 4.628 3 12.001l3.054 7.371L12 21.835l6.955-2.88 2.881-6.954-2.881-6.956L12 2.165zM13.023 9v8h-2V9h2zm0-3v2h-2V6h2z"></path>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
