import React from 'react';
import CPVisibleIcon from '../../CPVisibleIcon';

export const IndustryIdReadOnly = ({
  fieldName,
  value,
  isVisible,
  isCMS = true,
}) => {
  return (
    <>
      {value && (
        <div
          className={`${isVisible ? 'bg-blueberry-foreground/20' : 'bg-blueberry-foreground/5'} p-4 rounded-md`}
        >
          <div className="flex flex-row items-center justify-between">
            <h2 className="mb-0 uppercase">{fieldName}</h2>
            {isCMS && <CPVisibleIcon isVisible={isVisible} />}
          </div>
          <p>{value}</p>
        </div>
      )}
    </>
  );
};
