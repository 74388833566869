import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { Switch } from 'src/components/ui/switch';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    '& .ant-switch-checked': {
      background:
        'linear-gradient(270deg, #FF2667 0%, #669ACB 51%, #00FFCC 100%)',
    },
  },
  wrapDiv: {
    cursor: ({ disabled }) => (disabled ? 'not-allowed' : undefined),
  },
}));

const CPSwitch = ({ disabled, checked, onChange, message, ...props }) => {
  const classes = useStyles({ disabled });

  const handleChange = (checked) => {
    // The Switch component directly passes the boolean value
    onChange?.(checked);
  };

  const switchComponent = (
    <Switch
      checked={checked}
      onCheckedChange={handleChange}
      disabled={disabled}
      {...props}
    />
  );

  return (
    <div className={classes.root}>
      {message ? (
        <Tooltip title={message} arrow>
          {switchComponent}
        </Tooltip>
      ) : (
        switchComponent
      )}
    </div>
  );
};

export default CPSwitch;
