import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'src/components/ui/button';
import { useAuth } from 'react-oidc-context';
import toast from 'react-hot-toast';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import { PendingValidation } from 'src/v2api/P2P/types';
import { Dialog, DialogContent } from 'src/components/ui/dialog';
import {
  dismissIncomingMessage,
  getPendingAsyncValidation,
  validateConnection,
} from 'src/v2api/P2P/http';
import { P2PContext } from 'src/v2api/P2P/P2PContext';

interface ConfirmAsyncProps {
  validationCode: string;
  messageId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ConfirmAsync: React.FC<ConfirmAsyncProps> = ({
  validationCode,
  messageId,
  isOpen,
  onClose,
}) => {
  const { user } = useAuth();
  const { p2pRefresh } = useContext(P2PContext);
  const [pendingValidation, setPendingValidation] =
    useState<PendingValidation | null>(null);

  useEffect(() => {
    if (isOpen) {
      getPendingAsyncValidation(user?.access_token, validationCode)
        .then((v) => setPendingValidation(v))
        .catch((err) => setPendingValidation(null));
    }
  }, [user?.access_token, validationCode, isOpen]);

  const handleConfirmValidation = async () => {
    if (!pendingValidation || !user?.access_token) return;

    try {
      await validateConnection(user.access_token, pendingValidation, true);
      toast.success('Verification completed successfully');
      p2pRefresh();
      onClose();
    } catch (error) {
      toast.error(`Failed to complete verification: ${error.message}`);
    }
  };

  const handleDismissRequest = async () => {
    try {
      await dismissIncomingMessage(user.access_token, messageId);
      p2pRefresh();
      onClose();
    } catch (error) {
      toast.error('Failed to dismiss request');
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={(open) => (open ? null : onClose())}>
      <DialogContent>
        <div className="space-y-4">
          {pendingValidation ? (
            <div className="text-center space-y-4">
              <h3 className="text-lg font-semibold">Confirm Connection</h3>
              <p className="text-sm text-muted-foreground">
                Please confirm that you want to validate this profile
              </p>
              <div className="flex flex-col items-center space-y-2">
                <Avatar className="object-cover w-24 h-24 mb-2 rounded-full">
                  <AvatarImage
                    src={`${process.env.REACT_APP_V2_API_BASE}/media/persona/${pendingValidation.professional_profile_id}/small.webp`}
                  />
                  <AvatarFallback>
                    {pendingValidation.firstname[0]}
                    {pendingValidation.lastname[0]}
                  </AvatarFallback>
                </Avatar>
                <div>
                  <p className="font-medium">
                    {pendingValidation.firstname} {pendingValidation.lastname}
                  </p>
                </div>
              </div>
              <div className="flex justify-center space-x-2">
                <Button
                  animated
                  variant="accent"
                  onClick={handleDismissRequest}
                >
                  Dismiss
                </Button>
                <Button animated onClick={handleConfirmValidation}>
                  Confirm
                </Button>
              </div>
            </div>
          ) : (
            <div className="text-center space-y-4">
              <h3 className="text-lg font-semibold">
                Validation Request Expired
              </h3>
              <div className="flex justify-center space-x-2">
                <Button
                  animated
                  variant="outline"
                  onClick={handleDismissRequest}
                >
                  Dismiss
                </Button>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
