import React, { useEffect, useRef } from 'react';

const GradientBackground = ({
  children,
  baseColor = 'rgba(0, 0, 0, 0)',
  accentColor = 'rgba(0, 0, 0, 0.03)',
  midColor = 'rgba(0, 0, 0, 0.01)',
  gradientSize = '1000px',
}) => {
  const backgroundRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const updateMousePosition = (ev: MouseEvent) => {
      if (!backgroundRef.current) return;
      const { clientX, clientY } = ev;
      backgroundRef.current.style.setProperty('--x', `${clientX}px`);
      backgroundRef.current.style.setProperty('--y', `${clientY}px`);
      backgroundRef.current.style.setProperty('--gradient-size', gradientSize);
      backgroundRef.current.style.setProperty('--base-color', baseColor);
      backgroundRef.current.style.setProperty('--accent-color', accentColor);
      backgroundRef.current.style.setProperty('--mid-color', midColor);
    };

    window.addEventListener('mousemove', updateMousePosition);

    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, [baseColor, accentColor, midColor, gradientSize]);

  return (
    <div ref={backgroundRef} className="gradient-background">
      {children}
      <style jsx>{`
        .gradient-background {
          position: fixed;
          top: 0;
          left: 0;
          min-height: 100vh;
          min-width: 100vw;
          background-color: ${baseColor};
          background-image: radial-gradient(
            circle ${gradientSize} at var(--x, 100px) var(--y, 100px),
            ${accentColor} 0%,
            ${accentColor} 20%,
            ${midColor} 50%,
            transparent 80%
          );
          background-size: 100% 100%;
          background-repeat: no-repeat;
          display: flex;
          flex-direction: column;
          z-index: -1;
        }
      `}</style>
    </div>
  );
};

export default GradientBackground;
