import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import CMSBio from '../Bio';
import Representatives from '../Representatives';
import Mediums from '../AllMedium';
import PersonalInfo from '../PersonalInfo';
import OfficialLinks from '../OfficialLinks';
import IndustryIds from '../IndustryIDs';
import Projects from '../Projects';
import PersonaSummary from '../PersonaSummary';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';
import { FirstSigninModal } from '../FirstSigninModal';
import { UserSettingsContext } from '../../../v2api/UserSettingsContext/UserSettingsContext';

const PersonaPrivateView = ({ onDelete }) => {
  const { persona, isNew } = useContext(PersonaContext);
  const { userSettings } = useContext(UserSettingsContext);

  const [showInitialModal, setShowInitialModal] = useState(null);

  useEffect(() => {
    if (showInitialModal === null) {
      if (
        persona?.isProfessionalProfile &&
        userSettings?.dismissedAlias === false &&
        !persona.alias
      ) {
        setShowInitialModal(true);
      }
    }
  }, [
    persona?.isProfessionalProfile,
    showInitialModal,
    userSettings?.dismissedAlias,
  ]);

  if (!persona) return null;
  if (persona.personaId === null) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <FirstSigninModal
        open={showInitialModal}
        onOpenChange={(open) => open || setShowInitialModal(false)}
      />
      <div className="space-y-4">
        <PersonaSummary isCMS={true} onDelete={() => {}} isNew={false} />
        <Mediums />
        <PersonalInfo isCMS={true} />
        <CMSBio isCMS={true} />
        <Projects isCMS={true} />
        <IndustryIds isCMS={true} />
        <Representatives isCMS={true} />
        <OfficialLinks isCMS={true} />
      </div>
    </>
  );
};

export default PersonaPrivateView;
